import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Btn from '../Btn/btn';
import SearchResultItem from '../searchResultsItem/searchResultsItem';
import './searchResultView.css';

const SearchResultView = (props) => {

    const { setPopup, setError, updateLead, resultOrderBy, openDepartmentsCard, id, open, title, items ,opensearch } = props;

    const resltsRef = useRef(null)
    const SearchResultViewRef = useRef(null)
    const down = require('../../assets/images/vertecalArrows/down.png')
    const up = require('../../assets/images/vertecalArrows/up.png')

    const [coursesSearchResults, setCoursesSearchResults] = useState();
    const [InstituteViewStatus, setInstituteViewStatus] = useState([]);

    useEffect(() => {
//console.log(InstituteViewStatus[0]);
        const sortedItems = items.map((item) => {

            const sorted_branches = _.orderBy(item.course_branches, ['address.distance'], 'asc');

            item.smallest_distance = sorted_branches[0].address.distance;

            return item;
        });

        setCoursesSearchResults(sortedItems);
    }, [resultOrderBy, items]);
   
    const openInstituteDiv = (InstituteID) => {
		var newInstituteViewStatus = [...InstituteViewStatus];

		newInstituteViewStatus[InstituteID] = (InstituteViewStatus[InstituteID]== undefined || !InstituteViewStatus[InstituteID])?true:false;

		setInstituteViewStatus([...newInstituteViewStatus]);
	}

    const Path = (props) => {
        return (
            <div className='path'>
                <img className='logo' src={require('../../assets/images/searchResult/arierl.png')} />
                <p className='path-text'>{'לימודי קורס אנגלית'}</p>
                <div className='line'></div>
                <Btn
                    className='item-Btn'
                    text={'לצפייה'}
                    style={{ marginRight: '15px', backgroundColor: '#2B2D41', color: '#ffff' }} />
            </div>
        )
    }

    const renderItems = () => {
        if (coursesSearchResults) {
            if (props.type === 'path') {
                return coursesSearchResults.map(i => {
                    return <Path />
                })
            }
            else {
                let resultItems = null;

                if (resultOrderBy === 'location') {
                    resultItems = _.orderBy(coursesSearchResults, ['smallest_distance'], 'asc');
                } else {
                    
                    resultItems = _.orderBy(coursesSearchResults, ['institution.sticky', 'institution.rate_order', 'institution.address.distance'], ['desc', 'desc', 'asc']);
                }

                return resultItems.map((courseItem, index) => { 
                    return <div style={{width: '100%'}} >{!opensearch && courseItem.firstInInstituteGroupBy && <div className='institute-group-head' onClick=
                    {() => {
                        openInstituteDiv(courseItem.institution.id);
                        
                    }}
                    >{courseItem?.institution?.title}</div>}
                    <div style={{
                    display: opensearch ||  InstituteViewStatus[courseItem.institution.id]?'block':'none'
            }}><SearchResultItem
                        setPopup={setPopup}
                        setError={setError}
                        updateLead={(val) => updateLead(val)}
                        data={courseItem}
                        watching={(whatch) => props.watching(whatch)}
                        id={courseItem.id}
                        key={index}
                        resultOrderBy={resultOrderBy}
                    /></div>
                    </div>
                })
            }
        }
    }

    return (
        <div ref={SearchResultViewRef} className='SearchResultView-container' style={!open ? { borderBottom: '0px', marginBottom: '0px' } : {}}>
            {resultOrderBy == 'departments' &&
                <div className='SearchResultView-header' style={!open && { marginBottom: '0px' } || {}}
                    onClick={() => {
                        openDepartmentsCard(id, !open);
                        SearchResultViewRef.current.scrollIntoView({ behavior: 'smooth' });
                    }}>

                    <img style={{ marginLeft: '40px', height: '15px', width: '31px' }} src={open ? up : down} />

                    <div className='SearchResultView-headerRihgt'>
                        <p style={{ marginRight: '18px', fontFamily: 'Heebo-Bold' }}>{`${title} (${(coursesSearchResults?.length)}) `} </p>
                        <img src={require('../../assets/images/searchResult/icon.png')} />
                    </div>
                </div>
            }
            <div ref={resltsRef} className={`renderContainerM ${resultOrderBy != 'departments' ? 'show-all' : ''}`} style={{
                overflow: 'hidden',
                transition: '0.5s',
                display: open || resultOrderBy == 'location' || resultOrderBy == 'rating' ? '' : 'none',
            }} >
                {renderItems()}
            </div>
        </div >
    )
}

export default SearchResultView;