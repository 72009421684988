import global from '../../res/global'
/**
 * Re-order the courses by institution rate order / location ( and sticky )
 * @param array items  - courses
 * @param string orderBy - order type
 * @return array ordered items
 */
export const reOrderResultItems = (items, orderBy) => {
	if (!items || items.length <= 0) { return items }
	
	let orderedItems = [], sticky = [];

	const sortCallback = getSortCallback(orderBy);

	items.forEach(i => {
		if (i.institution.sticky)
			sticky.push(i)
		else {
			orderedItems.push(i);
		}
	})

	if (sortCallback) {
		orderedItems.sort(sortCallback);
	}
	return sticky.concat(orderedItems);
}
/**
 * Retrieve the sort callback of the orederby
 * @param string orderBy 
 * @return Function - sort callback
 */
const getSortCallback = orderBy => {
	switch (orderBy) {
		case 'rating':
			return sortOrderByRating;
		case 'location':
			return sortOrderByLocation;
		default:
			return false;
	}
}
/**
 * Sort first by rating and after by location
 */
const sortOrderByRating = (course1, course2) => {
	if ((course1 && course1.hasOwnProperty('institution')) && (course2 && course2.hasOwnProperty('institution'))) {
		// order by rating
		if (course1.institution.rate_order > course2.institution.rate_order) return -1;
		if (course1.institution.rate_order < course2.institution.rate_order) return 1;

		// if the rating is the same - order by location
		if (course1.institution.distance < course2.institution.distance) return -1;
		if (course1.institution.distance > course2.institution.distance) return 1;
	}
}
/**
 * Sort first by location and after by rating
 */
const sortOrderByLocation = (course1, course2) => {
	if ((course1 && course1.hasOwnProperty('institution')) && (course2 && course2.hasOwnProperty('institution'))) {
		// order by location
		if (course1.institution.address.distance < course2.institution.address.distance) return -1;
		if (course1.institution.address.distance > course2.institution.address.distance) return 1;

		// if the location is the same - order by rating
		if (course1.institution.rate_order > course2.institution.rate_order) return -1;
		if (course1.institution.rate_order < course2.institution.rate_order) return 1;
	}
}


export const prepareResultItems = async (items, store) => {
	let max_number = Number.MAX_SAFE_INTEGER;
	let promises = [];

	if (store?.location?.address && items) {
		for (let index in items) {
			let obj = items[index];
			promises.push(obj.map(async (item) => {
				if (item?.institution?.address) {
					if (!item?.institution?.distance) {
						let tmpDistance = await calculateDistance(item.institution.address, store.location.address);
						if (tmpDistance) {
							item.institution.distance = parseInt(tmpDistance.value);
							item.institution.distance_text = tmpDistance.text
						}
					} else {
						item.institution.distance = 0;
						item.institution.distance_text = '-';
					}
				} else {
					item.institution.distance = max_number;
				}
				return item;
			}));
		}
	}
	await Promise.all(promises);

	return items;
}

const calculateDistance = (from, to) => {

	const loc1 = to.lat + ',' + to.long;
	const loc2 = from.lat + ',' + from.lng;
	const proxyUrl = 'https://cors.bridged.cc/';
	const url = 'https://maps.googleapis.com/maps/api/distancematrix/json?origins=' + loc1 + '&destinations=' + loc2 + '&key=' + global.googlePlacesAPI_KEY + '&language=he';

	return fetch(proxyUrl + url)
		.then(res => res.json())
		.then(resJson => {
			return resJson?.rows[0]?.elements[0]?.distance;
		}).catch(err => console.log(err));
}