import React, { useState, useEffect } from 'react'
import global from '../../res/global'
import Popup from '../../components/popup/Popup'
import { strings } from '../../strings'
import AsyncSelect from 'react-select/async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SelectWrapper = (props) => {

	const { handleClosePopup, selected ,onselect } = props;

	const [state, setState] = useState({ options: [], inputValue: selected ?? '' });

	const promiseOptions = inputValue => {
		return fetch(global.domin + '!get_affiliates'+'?jwtauth='+global?.user?.token, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((res) => (res.json())).then((resJson) => {
			if (resJson?.data.data) {
				setState((current) => { return { ...current, options: resJson.data.data } })
			}
		})
	};
	const filterResult = (inputValue) => {
		return state.options.filter(i =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
	};

	const loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(filterResult(inputValue));
		}, 300);
	};

	const handleInputChange = (newValue) => {

		const inputValue = newValue.replace(/\W/g, '');

		setState((current) => { return { ...current, inputValue } });

		return inputValue;
	};

	const onInputChange = option => {
		const queryParams = [
			'user_id=' + global.user?.user_id,
			'lead-affiliate=' + option.value
		];

		onselect && onselect(option.label);

		fetch(global.domin + '!update_lead?id=' + global.lead[0].id +'&jwtauth='+global?.user?.token + '&' + queryParams.join('&'), {
			method: 'POST'
		})
			.then(res => {
				if (res.status === 200)
					return res.json();
			})
			.then(resJson => {
				window.sessionStorage.setItem('lead', JSON.stringify(resJson.data.data))
				global.lead = resJson.data.data;
				toast.success(strings.affiliateSelector.success.replace('%s', option.label), {
					position: "top-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: false,
				});

				handleClosePopup();
			})

	}

	useEffect(() => {
		promiseOptions();
	}, []);

	return (
		<div>
			<h2>{strings.affiliateSelector.title}</h2>
			<div className="input-wrapper">
				<label>
					<span className='label'>{strings.affiliateSelector.label}</span>
					<AsyncSelect
						cacheOptions
						loadOptions={loadOptions}
						value={state.options.filter(option => option.value === state.inputValue)}
						defaultOptions={state.options}
						onInputChange={(s) => handleInputChange(s)}
						placeholder={strings.affiliateSelector.placeholder}
						noOptionsMessage={() => strings.affiliateSelector.notFound}
						loadingMessage={() => strings.affiliateSelector.loading}
						onChange={onInputChange}
					/>
				</label>
			</div>
		</div>
	);
}

const AffiliateSelector = (props) => {

	const { data, show, showState, showCloseButton, setAffiliateName } = props;

	let initState = { show: show ?? false };
	if (!data.affiliate_id) {
		initState.show = true
	}
	const [state, setState] = useState(initState);
	const handleClose = () => {

		if (showState?.setState) {
			showState.setState(false);
		} else {
			setState((state) => { return { ...state, show: false } });
		}
	}

	return (
		<>
			<ToastContainer />
			<Popup
				show={showState?.show ?? state.show}
				message={<SelectWrapper handleClosePopup={handleClose} selected={data.affiliate_id} onselect={setAffiliateName}/>}
				handleClose={handleClose}
				height="300px"
				allowOverflow={true}
				disableRegularClose={showCloseButton ? !showCloseButton : false}
			/>
		</>
	);
}

export default function SelectAffiliatePopup(props) {

	const { data, show, showState, showCloseButton, setAffiliateName } = props;

	return (
		<AffiliateSelector {...props} />
	)
}
