import React, { useEffect, useState } from 'react'
import './StickyNaviagation.css';
import { elementInViewport } from '../../generalUtill';

export default function StickyNaviagation({ hasResult, show }) {
	const [state, setstate] = useState([false, false, false])
	useEffect(() => {
		handleScroll()
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [])
	const handleScroll = (event) => {
		let point1 = elementInViewport(document.querySelector('.personal-details-anchor'));
		let point2 = elementInViewport(document.querySelector('.search-anchor'));
		let point3 = elementInViewport(document.querySelector('.search-result-anchor'));

		setstate([point1, point2, point3])
	}	

	const handleScrollToAnhor = (selector) => {
		const anchor = document.querySelector(selector);
		window.scroll({
			top: anchor ? ( anchor.offsetTop - (selector === '.personal-details-anchor' ? 200 : 100) ) : 100,
			behavior: 'smooth'
		});
	}

	return (
		<>
			{ show &&
				<div className="sticky-navigation">
					<button className={`js-section-personal-details${state[0] ? ' active' : ''}`} onClick={() => { handleScrollToAnhor('.personal-details-anchor') }}>
						<div className="point"></div>
						<div className="label">פרטיים אישיים</div>
					</button>
					<button className={`js-section-search${state[1] ? ' active' : ''}`} onClick={() => { handleScrollToAnhor('.search-anchor') }}>
						<div className="point"></div>
						<div className="label">חיפוש</div>
					</button>
					{hasResult &&
						<button className={`js-section-search-result${state[2] ? ' active' : ''}`} onClick={() => { handleScrollToAnhor('.search-result-anchor') }}>
							<div className="point"></div>
							<div className="label">תוצאות חיפוש</div>
						</button>
					}
				</div>
			}
		</>
	)
}
