const cityList = [
    { value: 0, label: 'לא רשום' },
    { value: 6000, label: 'באקה אל-גרביה' },
    { value: 1278, label: 'באר מילכה' },
    { value: 1309, label: 'אלעד' },
    { value: 1314, label: 'נווה זיו' },
    { value: 1320, label: 'עין חוד' },
    { value: 1321, label: 'ח\'ואלד' },
    { value: 1329, label: 'יתיר' },
    { value: 1330, label: 'אחוזת ברק' },
    { value: 1331, label: 'כמאנה' },
    { value: 3400, label: 'חברון' },
    { value: 3724, label: 'נגוהות' },
    { value: 3744, label: 'ברוכין' },
    { value: 1333, label: 'נוף איילון' },
    { value: 1334, label: 'ראס אל-עין' },
    { value: 1337, label: 'שמשית' },
    { value: 1338, label: 'כדיתה' },
    { value: 1339, label: 'אל-עזי' },
    { value: 1340, label: 'מרחב עם' },
    { value: 1341, label: 'רוח מדבר' },
    { value: 1344, label: 'גבעות בר' },
    { value: 1346, label: 'תראבין א-צאנע(ישוב)' },
    { value: 1347, label: 'קצר א-סר' },
    { value: 1348, label: 'ביר הדאג\'' },
    { value: 1349, label: 'דריג\'את' },
    { value: 1358, label: 'אום בטין' },
    { value: 1359, label: 'אל סייד' },
    { value: 1360, label: 'סעוה' },
    { value: 1364, label: 'שלומית' },
    { value: 1367, label: 'כחלה' },
    { value: 1370, label: 'מצפה אילן' },
    { value: 1371, label: 'גני טל' },
    { value: 1372, label: 'נצר חזני' },
    { value: 1375, label: 'אבו תלול' },
    { value: 1376, label: 'באר גנים' },
    { value: 1411, label: 'מחנה הילה' },
    { value: 1412, label: 'מחנה תל נוף' },
    { value: 1413, label: 'מחנה יהודית' },
    { value: 1414, label: 'מחנה מרים' },
    { value: 1415, label: 'מחנה יפה' },
    { value: 1416, label: 'מחנה יוכבד' },
    { value: 1418, label: 'מחנה טלי' },
    { value: 1243, label: 'קציר' },
    { value: 963, label: 'אעצם (שבט)' },
    { value: 1220, label: 'אבירים' },
    { value: 958, label: 'אבו עבדון (שבט)' },
    { value: 1042, label: 'אבו עמאר (שבט)' },
    { value: 932, label: 'אבו עמרה (שבט)' },
    { value: 472, label: 'אבו גוש' },
    { value: 967, label: 'אבו ג\'ווייעד (שבט)' },
    { value: 968, label: 'אבו קורינאת (שבט)' },
    { value: 1342, label: 'אבו קרינאת (יישוב)' },
    { value: 966, label: 'אבו רובייעה (שבט)' },
    { value: 961, label: 'אבו רוקייק (שבט)' },
    { value: 473, label: 'אבו סנאן' },
    { value: 935, label: 'אבו סריחאן (שבט)' },
    { value: 1068, label: 'אדמית' },
    { value: 2035, label: 'עדנים' },
    { value: 1123, label: 'אדרת' },
    { value: 113, label: 'אדירים' },
    { value: 1199, label: 'עדי' },
    { value: 3759, label: 'אדורה' },
    { value: 959, label: 'אפיניש (שבט)' },
    { value: 313, label: 'אפק' },
    { value: 4301, label: 'אפיק' },
    { value: 176, label: 'אפיקים' },
    { value: 7700, label: 'עפולה' },
    { value: 794, label: 'עגור' },
    { value: 1157, label: 'אחווה' },
    { value: 797, label: 'אחיעזר' },
    { value: 785, label: 'אחיהוד' },
    { value: 804, label: 'אחיסמך' },
    { value: 850, label: 'אחיטוב' },
    { value: 821, label: 'אחוזם' },
    { value: 7600, label: 'עכו' },
    { value: 1316, label: 'אל-עריאן' },
    { value: 3727, label: 'עלי זהב' },
    { value: 3750, label: 'אלפי מנשה' },
    { value: 1182, label: 'אלון הגליל' },
    { value: 3604, label: 'אלון שבות' },
    { value: 429, label: 'אלוני אבא' },
    { value: 4017, label: 'אלוני הבשן' },
    { value: 868, label: 'אלוני יצחק' },
    { value: 285, label: 'אלונים' },
    { value: 688, label: 'עלמה' },
    { value: 1125, label: 'אלמגור' },
    { value: 3556, label: 'אלמוג' },
    { value: 3715, label: 'עלמון' },
    { value: 1146, label: 'עלומים' },
    { value: 1145, label: 'אלומה' },
    { value: 330, label: 'אלומות' },
    { value: 23, label: 'אמציה' },
    { value: 319, label: 'עמיר' },
    { value: 1064, label: 'אמירים' },
    { value: 385, label: 'עמיעד' },
    { value: 318, label: 'עמיעוז' },
    { value: 779, label: 'עמינדב' },
    { value: 773, label: 'עמיקם' },
    { value: 1253, label: 'אמנון' },
    { value: 708, label: 'עמקה' },
    { value: 1212, label: 'עמוקה' },
    { value: 4012, label: 'אניעם' },
    { value: 637, label: 'ערערה' },
    { value: 1192, label: 'ערערה-בנגב' },
    { value: 2560, label: 'ערד' },
    { value: 1246, label: 'עראמשה' },
    { value: 701, label: 'ארבל' },
    { value: 3598, label: 'ארגמן' },
    { value: 3570, label: 'אריאל' },
    { value: 1335, label: 'ערב אל נעים' },
    { value: 531, label: 'עראבה' },
    { value: 1324, label: 'ארסוף' },
    { value: 593, label: 'ערוגות' },
    { value: 960, label: 'אסד (שבט)' },
    { value: 3754, label: 'אספר' },
    { value: 591, label: 'עשרת' },
    { value: 1152, label: 'אשלים' },
    { value: 70, label: 'אשדוד' },
    { value: 199, label: 'אשדות יעקב (איחוד)' },
    { value: 188, label: 'אשדות יעקב (מאוחד)' },
    { value: 1256, label: 'אשרת' },
    { value: 7100, label: 'אשקלון' },
    { value: 969, label: 'עטאוונה (שבט)' },
    { value: 3658, label: 'עטרת' },
    { value: 53, label: 'עתלית' },
    { value: 965, label: 'אטרש (שבט)' },
    { value: 917, label: 'עצמון שגב' },
    { value: 892, label: 'עבדון' },
    { value: 3786, label: 'אבנת' },
    { value: 679, label: 'אביאל' },
    { value: 1070, label: 'אביעזר' },
    { value: 819, label: 'אביגדור' },
    { value: 175, label: 'אביחיל' },
    { value: 2052, label: 'אביטל' },
    { value: 1115, label: 'אביבים' },
    { value: 4011, label: 'אבני איתן' },
    { value: 3793, label: 'אבני חפץ' },
    { value: 1311, label: 'אבשלום' },
    { value: 1275, label: 'אבטליון' },
    { value: 156, label: 'עיינות' },
    { value: 77, label: 'איילת השחר' },
    { value: 711, label: 'עזריה' },
    { value: 565, label: 'אזור' },
    { value: 837, label: 'עזריאל' },
    { value: 817, label: 'עזריקם' },
    { value: 2043, label: 'בחן' },
    { value: 94, label: 'בלפוריה' },
    { value: 823, label: 'בר גיורא' },
    { value: 1191, label: 'בר יוחאי' },
    { value: 667, label: 'ברעם' },
    { value: 141, label: 'ברק' },
    { value: 2038, label: 'ברקת' },
    { value: 3654, label: 'ברקן' },
    { value: 617, label: 'ברקאי' },
    { value: 1326, label: 'בסמ"ה' },
    { value: 944, label: 'בסמת טבעון' },
    { value: 3794, label: 'בת עין' },
    { value: 1323, label: 'בת הדר' },
    { value: 1401, label: 'בת חצור' },
    { value: 1319, label: 'בת חפר' },
    { value: 1361, label: 'בת חן' },
    { value: 33, label: 'בת שלמה' },
    { value: 6200, label: 'בת ים' },
    { value: 389, label: 'בצרה' },
    { value: 21, label: 'באר אורה' },
    { value: 9000, label: 'באר שבע' },
    { value: 155, label: 'באר טוביה' },
    { value: 2530, label: 'באר יעקב' },
    { value: 399, label: 'בארי' },
    { value: 450, label: 'בארות יצחק' },
    { value: 697, label: 'בארותיים' },
    { value: 480, label: 'בית ג\'ן' },
    { value: 712, label: 'בן עמי' },
    { value: 2013, label: 'בן שמן (מושב)' },
    { value: 1084, label: 'בן שמן (כפר נוער)' },
    { value: 760, label: 'בן זכאי' },
    { value: 685, label: 'בניה' },
    { value: 448, label: 'בני עטרות' },
    { value: 1066, label: 'בני עי"ש' },
    { value: 6100, label: 'בני ברק' },
    { value: 592, label: 'בני דרום' },
    { value: 386, label: 'בני דרור' },
    { value: 1363, label: 'בני נצרים' },
    { value: 588, label: 'בני ראם' },
    { value: 4015, label: 'בני יהודה' },
    { value: 418, label: 'בני ציון' },
    { value: 3612, label: 'בקעות' },
    { value: 864, label: 'בקוע' },
    { value: 3710, label: 'ברכה' },
    { value: 746, label: 'ברכיה' },
    { value: 428, label: 'ברור חיל' },
    { value: 2060, label: 'ברוש' },
    { value: 95, label: 'בית אלפא' },
    { value: 604, label: 'בית עריף' },
    { value: 3652, label: 'בית אריה' },
    { value: 1076, label: 'בית ברל' },
    { value: 466, label: 'בית דגן' },
    { value: 3574, label: 'בית אל' },
    { value: 562, label: 'בית אלעזרי' },
    { value: 756, label: 'בית עזרא' },
    { value: 571, label: 'בית גמליאל' },
    { value: 619, label: 'בית גוברין' },
    { value: 3645, label: 'בית הערבה' },
    { value: 572, label: 'בית העמק' },
    { value: 723, label: 'בית הגדי' },
    { value: 373, label: 'בית הלוי' },
    { value: 159, label: 'בית חנן' },
    { value: 800, label: 'בית חנניה' },
    { value: 242, label: 'בית השיטה' },
    { value: 1050, label: 'בית חשמונאי' },
    { value: 877, label: 'בית חירות' },
    { value: 322, label: 'בית הלל' },
    { value: 2033, label: 'בית חלקיה' },
    { value: 3575, label: 'בית חורון' },
    { value: 430, label: 'בית לחם הגלילית' },
    { value: 751, label: 'בית מאיר' },
    { value: 784, label: 'בית נחמיה' },
    { value: 672, label: 'בית נקופה' },
    { value: 16, label: 'בית ניר' },
    { value: 317, label: 'בית אורן' },
    { value: 202, label: 'בית עובד' },
    { value: 598, label: 'בית קמה' },
    { value: 365, label: 'בית קשת' },
    { value: 848, label: 'בית רבן' },
    { value: 1162, label: 'בית רימון' },
    { value: 9200, label: 'בית שאן' },
    { value: 248, label: 'בית שערים' },
    { value: 2610, label: 'בית שמש' },
    { value: 747, label: 'בית שקמה' },
    { value: 301, label: 'בית עוזיאל' },
    { value: 200, label: 'בית ינאי' },
    { value: 288, label: 'בית יהושע' },
    { value: 326, label: 'בית יצחק-שער חפר' },
    { value: 265, label: 'בית יוסף' },
    { value: 710, label: 'בית זית' },
    { value: 353, label: 'בית זיד' },
    { value: 143, label: 'בית זרע' },
    { value: 212, label: 'בית צבי' },
    { value: 3780, label: 'ביתר עילית' },
    { value: 589, label: 'בצת' },
    { value: 483, label: 'בענה' },
    { value: 9800, label: 'בנימינה-גבעת עדה' },
    { value: 998, label: 'ביר אל-מכסור' },
    { value: 368, label: 'ביריה' },
    { value: 252, label: 'ביתן אהרן' },
    { value: 762, label: 'בטחה' },
    { value: 234, label: 'ביצרון' },
    { value: 1368, label: 'בני דקלים' },
    { value: 482, label: 'בועיינה-נוג\'ידאת' },
    { value: 4001, label: 'בוקעאתא' },
    { value: 698, label: 'בורגתה' },
    { value: 559, label: 'בוסתן הגליל' },
    { value: 489, label: 'דבוריה' },
    { value: 302, label: 'דפנה' },
    { value: 475, label: 'דחי' },
    { value: 494, label: 'דאלית אל-כרמל' },
    { value: 300, label: 'דליה' },
    { value: 431, label: 'דלתון' },
    { value: 303, label: 'דן' },
    { value: 407, label: 'דברת' },
    { value: 62, label: 'דגניה א\'' },
    { value: 79, label: 'דגניה ב\'' },
    { value: 490, label: 'דייר אל-אסד' },
    { value: 492, label: 'דייר חנא' },
    { value: 493, label: 'דייר ראפאת' },
    { value: 1317, label: 'דמיידה' },
    { value: 1241, label: 'דקל' },
    { value: 849, label: 'דבירה' },
    { value: 146, label: 'דבורה' },
    { value: 2200, label: 'דימונה' },
    { value: 2063, label: 'דישון' },
    { value: 3747, label: 'דולב' },
    { value: 738, label: 'דור' },
    { value: 336, label: 'דורות' },
    { value: 1067, label: 'דוב"ב' },
    { value: 3650, label: 'אפרת' },
    { value: 530, label: 'עיילבון' },
    { value: 546, label: 'עין אל-אסד' },
    { value: 532, label: 'עין מאהל' },
    { value: 521, label: 'עין נקובא' },
    { value: 4502, label: 'עין קנייא' },
    { value: 514, label: 'עין ראפה' },
    { value: 3618, label: 'אלעזר' },
    { value: 4003, label: 'אל-רום' },
    { value: 2600, label: 'אילת' },
    { value: 3765, label: 'עלי' },
    { value: 4002, label: 'אלי-עד' },
    { value: 1248, label: 'אליפז' },
    { value: 730, label: 'אליפלט' },
    { value: 841, label: 'אלישמע' },
    { value: 294, label: 'אילון' },
    { value: 3579, label: 'אלון מורה' },
    { value: 1126, label: 'אילות' },
    { value: 3560, label: 'אלקנה' },
    { value: 603, label: 'אלקוש' },
    { value: 41, label: 'אליכין' },
    { value: 682, label: 'אליקים' },
    { value: 204, label: 'אלישיב' },
    { value: 772, label: 'אמונים' },
    { value: 687, label: 'עין איילה' },
    { value: 436, label: 'עין דור' },
    { value: 2042, label: 'עין גדי' },
    { value: 273, label: 'עין גב' },
    { value: 1240, label: 'עין הבשור' },
    { value: 367, label: 'עין העמק' },
    { value: 167, label: 'עין החורש' },
    { value: 289, label: 'עין המפרץ' },
    { value: 383, label: 'עין הנצי"ב' },
    { value: 89, label: 'עין חרוד (איחוד)' },
    { value: 82, label: 'עין חרוד (מאוחד)' },
    { value: 676, label: 'עין השלושה' },
    { value: 270, label: 'עין השופט' },
    { value: 1053, label: 'עין חצבה' },
    { value: 74, label: 'עין הוד' },
    { value: 223, label: 'עין עירון' },
    { value: 1056, label: 'עין כרם-בי"ס חקלאי' },
    { value: 426, label: 'עין כרמל' },
    { value: 880, label: 'עין שריד' },
    { value: 139, label: 'עין שמר' },
    { value: 1251, label: 'עין תמר' },
    { value: 157, label: 'עין ורד' },
    { value: 813, label: 'עין יעקב' },
    { value: 806, label: 'עין יהב' },
    { value: 4503, label: 'עין זיוון' },
    { value: 622, label: 'עין צורים' },
    { value: 871, label: 'עינת' },
    { value: 3712, label: 'ענב' },
    { value: 714, label: 'ארז' },
    { value: 71, label: 'אשבול' },
    { value: 2021, label: 'אשל הנשיא' },
    { value: 1188, label: 'אשחר' },
    { value: 3722, label: 'אשכולות' },
    { value: 740, label: 'אשתאול' },
    { value: 37, label: 'איתן' },
    { value: 886, label: 'איתנים' },
    { value: 1298, label: 'אתגר' },
    { value: 1081, label: 'אבן מנחם' },
    { value: 783, label: 'אבן ספיר' },
    { value: 400, label: 'אבן שמואל' },
    { value: 182, label: 'אבן יהודה' },
    { value: 369, label: 'גלעד (אבן יצחק)' },
    { value: 376, label: 'עברון' },
    { value: 716, label: 'אייל' },
    { value: 3778, label: 'עץ אפרים' },
    { value: 1149, label: 'עזר' },
    { value: 328, label: 'עזוז' },
    { value: 535, label: 'פסוטה' },
    { value: 537, label: 'פוריידיס' },
    { value: 842, label: 'געש' },
    { value: 463, label: 'געתון' },
    { value: 145, label: 'גדיש' },
    { value: 35, label: 'גדות' },
    { value: 393, label: 'גלאון' },
    { value: 1072, label: 'גן הדרום' },
    { value: 225, label: 'גן השומרון' },
    { value: 239, label: 'גן חיים' },
    { value: 1274, label: 'גן נר' },
    { value: 144, label: 'גן שלמה' },
    { value: 72, label: 'גן שמואל' },
    { value: 311, label: 'גן שורק' },
    { value: 166, label: 'גן יבנה' },
    { value: 734, label: 'גן יאשיה' },
    { value: 218, label: 'גני עם' },
    { value: 1103, label: 'גני הדר' },
    { value: 229, label: 'גני תקווה' },
    { value: 862, label: 'גני יוחנן' },
    { value: 836, label: 'גנות' },
    { value: 549, label: 'גנות הדר' },
    { value: 128, label: 'גת רימון' },
    { value: 340, label: 'גת (קיבוץ)' },
    { value: 457, label: 'גזית' },
    { value: 706, label: 'גיאה' },
    { value: 853, label: 'גאליה' },
    { value: 872, label: 'גאולי תימן' },
    { value: 379, label: 'גאולים' },
    { value: 2550, label: 'גדרה' },
    { value: 39, label: 'גפן' },
    { value: 346, label: 'גליל ים' },
    { value: 1129, label: 'גרופית' },
    { value: 305, label: 'גשר' },
    { value: 574, label: 'גשר הזיו' },
    { value: 4022, label: 'גשור' },
    { value: 86, label: 'גבע' },
    { value: 683, label: 'גבע כרמל' },
    { value: 3763, label: 'גבע בנימין' },
    { value: 342, label: 'גברעם' },
    { value: 133, label: 'גבת' },
    { value: 424, label: 'גבים' },
    { value: 352, label: 'גבולות' },
    { value: 370, label: 'גזר' },
    { value: 4501, label: 'ע\'ג\'ר' },
    { value: 196, label: 'גיבתון' },
    { value: 442, label: 'גדעונה' },
    { value: 736, label: 'גילת' },
    { value: 3606, label: 'גלגל' },
    { value: 1204, label: 'גילון' },
    { value: 745, label: 'גמזו' },
    { value: 863, label: 'גינתון' },
    { value: 92, label: 'גיניגר' },
    { value: 262, label: 'גינוסר' },
    { value: 1206, label: 'גיתה' },
    { value: 3613, label: 'גיתית' },
    { value: 1293, label: 'גבעת אבני' },
    { value: 147, label: 'גבעת ברנר' },
    { value: 1288, label: 'גבעת אלה' },
    { value: 870, label: 'גבעת השלושה' },
    { value: 2018, label: 'גבעת חיים (איחוד)' },
    { value: 173, label: 'גבעת חיים (מאוחד)' },
    { value: 207, label: 'גבעת ח"ן' },
    { value: 802, label: 'גבעת כ"ח' },
    { value: 360, label: 'גבעת ניל"י' },
    { value: 703, label: 'גבעת עוז' },
    { value: 1077, label: 'גבעת שפירא' },
    { value: 566, label: 'גבעת שמש' },
    { value: 681, label: 'גבעת שמואל' },
    { value: 787, label: 'גבעת יערים' },
    { value: 919, label: 'גבעת ישעיהו' },
    { value: 4021, label: 'גבעת יואב' },
    { value: 3730, label: 'גבעת זאב' },
    { value: 6300, label: 'גבעתיים' },
    { value: 793, label: 'גבעתי' },
    { value: 2014, label: 'גבעולים' },
    { value: 3644, label: 'גבעון החדשה' },
    { value: 1043, label: 'גיזו' },
    { value: 852, label: 'גונן' },
    { value: 755, label: 'גורן' },
    { value: 1219, label: 'גורנות הגליל' },
    { value: 675, label: 'הבונים' },
    { value: 4026, label: 'חד-נס' },
    { value: 191, label: 'הדר עם' },
    { value: 6500, label: 'חדרה' },
    { value: 618, label: 'חדיד' },
    { value: 363, label: 'חפץ חיים' },
    { value: 3764, label: 'חגי' },
    { value: 717, label: 'חגור' },
    { value: 356, label: 'הגושרים' },
    { value: 434, label: 'החותרים' },
    { value: 4000, label: 'חיפה' },
    { value: 3573, label: 'נוה צוף' },
    { value: 1272, label: 'חלוץ' },
    { value: 377, label: 'המעפיל' },
    { value: 343, label: 'חמדיה' },
    { value: 993, label: 'חמאם' },
    { value: 3609, label: 'חמרה' },
    { value: 280, label: 'חניתה' },
    { value: 1257, label: 'חנתון' },
    { value: 807, label: 'חניאל' },
    { value: 423, label: 'העוגן' },
    { value: 702, label: 'האון' },
    { value: 3769, label: 'הר אדר' },
    { value: 1261, label: 'הר עמשא' },
    { value: 3603, label: 'הר גילה' },
    { value: 464, label: 'הראל' },
    { value: 1203, label: 'הררית' },
    { value: 1209, label: 'חרשים' },
    { value: 1249, label: 'הרדוף' },
    { value: 1247, label: 'חריש' },
    { value: 1024, label: 'חרוצים' },
    { value: 3770, label: 'חשמונאים' },
    { value: 677, label: 'הסוללים' },
    { value: 4005, label: 'חספין' },
    { value: 235, label: 'חבצלת השרון' },
    { value: 1169, label: 'הוואשלה (שבט)' },
    { value: 684, label: 'היוגב' },
    { value: 1409, label: 'חצר בארותיים' },
    { value: 700, label: 'חצב' },
    { value: 397, label: 'חצרים' },
    { value: 13, label: 'חצבה' },
    { value: 1047, label: 'חזון' },
    { value: 2034, label: 'חצור הגלילית' },
    { value: 406, label: 'חצור-אשדוד' },
    { value: 307, label: 'הזורעים' },
    { value: 250, label: 'הזורע' },
    { value: 1402, label: 'חצרות חולדה' },
    { value: 1405, label: 'חצרות כ"ח' },
    { value: 90, label: 'חפצי-בה' },
    { value: 820, label: 'חלץ' },
    { value: 801, label: 'חמד' },
    { value: 422, label: 'חרב לאת' },
    { value: 3717, label: 'חרמש' },
    { value: 162, label: 'חירות' },
    { value: 6400, label: 'הרצליה' },
    { value: 1110, label: 'חבר' },
    { value: 219, label: 'חיבת ציון' },
    { value: 1208, label: 'הילה' },
    { value: 3643, label: 'חיננית' },
    { value: 9700, label: 'הוד השרון' },
    { value: 1322, label: 'הודיות' },
    { value: 726, label: 'הודיה' },
    { value: 115, label: 'חופית' },
    { value: 205, label: 'חגלה' },
    { value: 1239, label: 'חולית' },
    { value: 6600, label: 'חולון' },
    { value: 355, label: 'חורשים' },
    { value: 662, label: 'חוסן' },
    { value: 1186, label: 'הושעיה' },
    { value: 948, label: 'חוג\'ייראת (ד\'הרה)' },
    { value: 253, label: 'חולתה' },
    { value: 160, label: 'חולדה' },
    { value: 374, label: 'חוקוק' },
    { value: 1303, label: 'חורה' },
    { value: 496, label: 'חורפיש' },
    { value: 1332, label: 'חוסנייה' },
    { value: 956, label: 'הוזייל (שבט)' },
    { value: 529, label: 'אעבלין' },
    { value: 338, label: 'איבים' },
    { value: 652, label: 'אבטין' },
    { value: 1175, label: 'עידן' },
    { value: 478, label: 'אכסאל' },
    { value: 49, label: 'אילניה' },
    { value: 511, label: 'עילוט' },
    { value: 3660, label: 'עמנואל' },
    { value: 1187, label: 'עיר אובות' },
    { value: 534, label: 'עספיא' },
    { value: 3762, label: 'איתמר' },
    { value: 628, label: 'ג\'ת' },
    { value: 627, label: 'ג\'לג\'וליה' },
    { value: 3000, label: 'ירושלים' },
    { value: 487, label: 'ג\'ש (גוש חלב)' },
    { value: 541, label: 'ג\'סר א-זרקא' },
    { value: 1292, label: 'ג\'דיידה-מכר' },
    { value: 485, label: 'ג\'ולס' },
    { value: 976, label: 'ג\'נאביב (שבט)' },
    { value: 978, label: 'כעביה-טבאש-חג\'אג\'רה' },
    { value: 576, label: 'כברי' },
    { value: 504, label: 'כאבול' },
    { value: 371, label: 'כדורי' },
    { value: 633, label: 'כפר ברא' },
    { value: 508, label: 'כפר כמא' },
    { value: 509, label: 'כפר כנא' },
    { value: 510, label: 'כפר מנדא' },
    { value: 512, label: 'כפר מצר' },
    { value: 654, label: 'כפר קרע' },
    { value: 634, label: 'כפר קאסם' },
    { value: 507, label: 'כפר יאסיף' },
    { value: 1210, label: 'כחל' },
    { value: 1229, label: 'כלנית' },
    { value: 1201, label: 'כמון' },
    { value: 4028, label: 'כנף' },
    { value: 2006, label: 'כנות' },
    { value: 505, label: 'כאוכב אבו אל-היג\'א' },
    { value: 38, label: 'כרי דשא' },
    { value: 1285, label: 'כרכום' },
    { value: 1264, label: 'כרמי יוסף' },
    { value: 3766, label: 'כרמי צור' },
    { value: 3656, label: 'כרמל' },
    { value: 1139, label: 'כרמיאל' },
    { value: 768, label: 'כרמיה' },
    { value: 3638, label: 'כפר אדומים' },
    { value: 690, label: 'כפר אחים' },
    { value: 857, label: 'כפר אביב' },
    { value: 875, label: 'כפר עבודה' },
    { value: 845, label: 'כפר עזה' },
    { value: 132, label: 'כפר ברוך' },
    { value: 220, label: 'כפר ביאליק' },
    { value: 177, label: 'כפר ביל"ו' },
    { value: 2010, label: 'כפר בן נון' },
    { value: 357, label: 'כפר בלום' },
    { value: 707, label: 'כפר דניאל' },
    { value: 3488, label: 'כפר עציון' },
    { value: 427, label: 'כפר גלים' },
    { value: 106, label: 'כפר גדעון' },
    { value: 76, label: 'כפר גלעדי' },
    { value: 310, label: 'כפר גליקסון' },
    { value: 696, label: 'כפר חב"ד' },
    { value: 192, label: 'כפר החורש' },
    { value: 254, label: 'כפר המכבי' },
    { value: 582, label: 'כפר הנגיד' },
    { value: 1297, label: 'כפר חנניה' },
    { value: 443, label: 'כפר הנשיא' },
    { value: 890, label: 'כפר הנוער הדתי' },
    { value: 3796, label: 'כפר האורנים' },
    { value: 888, label: 'כפר הרי"ף' },
    { value: 217, label: 'כפר הרא"ה' },
    { value: 4004, label: 'כפר חרוב' },
    { value: 112, label: 'כפר חסידים א\'' },
    { value: 889, label: 'כפר חסידים ב\'' },
    { value: 193, label: 'כפר חיים' },
    { value: 187, label: 'כפר הס' },
    { value: 255, label: 'כפר חיטים' },
    { value: 609, label: 'כפר חושן' },
    { value: 388, label: 'כפר קיש' },
    { value: 98, label: 'כפר מל"ל' },
    { value: 297, label: 'כפר מסריק' },
    { value: 1095, label: 'כפר מימון' },
    { value: 274, label: 'כפר מנחם' },
    { value: 387, label: 'כפר מונש' },
    { value: 764, label: 'כפר מרדכי' },
    { value: 316, label: 'כפר נטר' },
    { value: 189, label: 'כפר פינס' },
    { value: 579, label: 'כפר ראש הנקרה' },
    { value: 1130, label: 'כפר רוזנואלד (זרעית)' },
    { value: 295, label: 'כפר רופין' },
    { value: 1166, label: 'כפר רות' },
    { value: 6900, label: 'כפר סבא' },
    { value: 605, label: 'כפר שמאי' },
    { value: 267, label: 'כפר שמריהו' },
    { value: 743, label: 'כפר שמואל' },
    { value: 107, label: 'כפר סילבר' },
    { value: 249, label: 'כפר סירקין' },
    { value: 345, label: 'כפר סאלד' },
    { value: 3572, label: 'כפר תפוח' },
    { value: 47, label: 'כפר תבור' },
    { value: 673, label: 'כפר טרומן' },
    { value: 364, label: 'כפר אוריה' },
    { value: 190, label: 'כפר ויתקין' },
    { value: 320, label: 'כפר ורבורג' },
    { value: 1263, label: 'כפר ורדים' },
    { value: 170, label: 'כפר יעבץ' },
    { value: 85, label: 'כפר יחזקאל' },
    { value: 140, label: 'כפר יהושע' },
    { value: 168, label: 'כפר יונה' },
    { value: 786, label: 'כפר זיתים' },
    { value: 1325, label: 'כפר זוהרים' },
    { value: 1183, label: 'כליל' },
    { value: 1291, label: 'כמהין' },
    { value: 1198, label: 'כרמים' },
    { value: 88, label: 'כרם בן שמן' },
    { value: 664, label: 'כרם בן זמרה' },
    { value: 580, label: 'כרם מהר"ל' },
    { value: 1085, label: 'כרם שלום' },
    { value: 1094, label: 'כרם יבנה (ישיבה)' },
    { value: 859, label: 'כסלון' },
    { value: 986, label: 'ח\'ואלד (שבט)' },
    { value: 63, label: 'כנרת (מושבה)' },
    { value: 57, label: 'כנרת (קבוצה)' },
    { value: 1153, label: 'כישור' },
    { value: 1296, label: 'כסרא-סמיע' },
    { value: 840, label: 'כיסופים' },
    { value: 3564, label: 'כוכב השחר' },
    { value: 824, label: 'כוכב מיכאל' },
    { value: 3779, label: 'כוכב יעקב' },
    { value: 1224, label: 'כוכב יאיר' },
    { value: 1252, label: 'כורזים' },
    { value: 1059, label: 'כסיפה' },
    { value: 2023, label: 'להב' },
    { value: 380, label: 'להבות הבשן' },
    { value: 715, label: 'להבות חביבה' },
    { value: 24, label: 'לכיש' },
    { value: 1310, label: 'לפיד' },
    { value: 1173, label: 'לפידות' },
    { value: 1060, label: 'לקיה' },
    { value: 585, label: 'לביא' },
    { value: 1207, label: 'לבון' },
    { value: 1271, label: 'להבים' },
    { value: 1114, label: 'שריגים (לי-און)' },
    { value: 674, label: 'לימן' },
    { value: 1230, label: 'לבנים' },
    { value: 7000, label: 'לוד' },
    { value: 595, label: 'לוחמי הגיטאות' },
    { value: 1255, label: 'לוטן' },
    { value: 1171, label: 'לוטם' },
    { value: 52, label: 'לוזית' },
    { value: 678, label: 'מעגן' },
    { value: 694, label: 'מעגן מיכאל' },
    { value: 3616, label: 'מעלה אדומים' },
    { value: 3653, label: 'מעלה עמוס' },
    { value: 3608, label: 'מעלה אפרים' },
    { value: 4008, label: 'מעלה גמלא' },
    { value: 1127, label: 'מעלה גלבוע' },
    { value: 286, label: 'מעלה החמישה' },
    { value: 1327, label: 'מעלה עירון' },
    { value: 3752, label: 'מעלה לבונה' },
    { value: 3651, label: 'מעלה מכמש' },
    { value: 1063, label: 'מעלות-תרשיחא' },
    { value: 344, label: 'מענית' },
    { value: 230, label: 'מעש' },
    { value: 197, label: 'מעברות' },
    { value: 1082, label: 'מעגלים' },
    { value: 3657, label: 'מעון' },
    { value: 2055, label: 'מאור' },
    { value: 272, label: 'מעוז חיים' },
    { value: 416, label: 'מעין ברוך' },
    { value: 290, label: 'מעין צבי' },
    { value: 1080, label: 'מבועים' },
    { value: 695, label: 'מגן' },
    { value: 1155, label: 'מגן שאול' },
    { value: 375, label: 'מגל' },
    { value: 722, label: 'מגשימים' },
    { value: 308, label: 'מחניים' },
    { value: 1262, label: 'צוקים' },
    { value: 1196, label: 'מחנה יתיר' },
    { value: 776, label: 'מחסיה' },
    { value: 516, label: 'מג\'ד אל-כרום' },
    { value: 4201, label: 'מג\'דל שמס' },
    { value: 1343, label: 'מכחול' },
    { value: 1154, label: 'מלכישוע' },
    { value: 596, label: 'מלכיה' },
    { value: 1174, label: 'מנוף' },
    { value: 1205, label: 'מנות' },
    { value: 994, label: 'מנשית זבדה' },
    { value: 843, label: 'מרגליות' },
    { value: 4203, label: 'מסעדה' },
    { value: 939, label: 'מסעודין אל-עזאזמה' },
    { value: 421, label: 'משאבי שדה' },
    { value: 791, label: 'משען' },
    { value: 3785, label: 'משכיות' },
    { value: 748, label: 'מסלול' },
    { value: 1258, label: 'מסד' },
    { value: 263, label: 'מסדה' },
    { value: 3605, label: 'משואה' },
    { value: 620, label: 'משואות יצחק' },
    { value: 822, label: 'מטע' },
    { value: 1315, label: 'מתן' },
    { value: 1184, label: 'מתת' },
    { value: 3648, label: 'מתתיהו' },
    { value: 573, label: 'מבקיעים' },
    { value: 28, label: 'מזכרת בתיה' },
    { value: 757, label: 'מצליח' },
    { value: 606, label: 'מזור' },
    { value: 517, label: 'מזרעה' },
    { value: 325, label: 'מצובה' },
    { value: 1128, label: 'מי עמי' },
    { value: 102, label: 'מאיר שפיה' },
    { value: 570, label: 'מעונה' },
    { value: 668, label: 'מפלסים' },
    { value: 689, label: 'מגדים' },
    { value: 586, label: 'מגידו' },
    { value: 3599, label: 'מחולה' },
    { value: 649, label: 'מייסר' },
    { value: 3614, label: 'מכורה' },
    { value: 164, label: 'מלאה' },
    { value: 2044, label: 'מלילות' },
    { value: 48, label: 'מנחמיה' },
    { value: 347, label: 'מנרה' },
    { value: 2030, label: 'מנוחה' },
    { value: 1282, label: 'מירב' },
    { value: 97, label: 'מרחביה (מושב)' },
    { value: 66, label: 'מרחביה (קיבוץ)' },
    { value: 1098, label: 'מרכז שפירא' },
    { value: 4101, label: 'מרום גולן' },
    { value: 607, label: 'מירון' },
    { value: 731, label: 'מישר' },
    { value: 520, label: 'משהד' },
    { value: 742, label: 'מסילת ציון' },
    { value: 298, label: 'מסילות' },
    { value: 1268, label: 'מיתר' },
    { value: 2054, label: 'מיטב' },
    { value: 43, label: 'מטולה' },
    { value: 1015, label: 'מבשרת ציון' },
    { value: 771, label: 'מבוא ביתר' },
    { value: 3569, label: 'מבוא דותן' },
    { value: 4204, label: 'מבוא חמה' },
    { value: 3709, label: 'מבוא חורון' },
    { value: 1141, label: 'מבוא מודיעים' },
    { value: 1318, label: 'מבואות ים' },
    { value: 3745, label: 'מצדות יהודה' },
    { value: 4019, label: 'מיצר' },
    { value: 648, label: 'מצר' },
    { value: 518, label: 'מעיליא' },
    { value: 2029, label: 'מדרך עוז' },
    { value: 1140, label: 'מדרשת בן גוריון' },
    { value: 897, label: 'מדרשת רופין' },
    { value: 65, label: 'מגדל' },
    { value: 874, label: 'מגדל העמק' },
    { value: 3561, label: 'מגדל עוז' },
    { value: 3751, label: 'מגדלים' },
    { value: 1202, label: 'מכמנים' },
    { value: 382, label: 'מכמורת' },
    { value: 22, label: 'מקווה ישראל' },
    { value: 378, label: 'משגב עם' },
    { value: 765, label: 'משגב דב' },
    { value: 670, label: 'משמר איילון' },
    { value: 563, label: 'משמר דוד' },
    { value: 130, label: 'משמר העמק' },
    { value: 395, label: 'משמר הנגב' },
    { value: 194, label: 'משמר השרון' },
    { value: 729, label: 'משמר השבעה' },
    { value: 732, label: 'משמר הירדן' },
    { value: 213, label: 'משמרות' },
    { value: 425, label: 'משמרת' },
    { value: 829, label: 'מבטחים' },
    { value: 58, label: 'מצפה' },
    { value: 1222, label: 'מצפה אבי"ב' },
    { value: 1190, label: 'מצפה נטופה' },
    { value: 99, label: 'מצפה רמון' },
    { value: 3610, label: 'מצפה שלם' },
    { value: 3576, label: 'מצפה יריחו' },
    { value: 104, label: 'מזרע' },
    { value: 3797, label: 'מודיעין עילית' },
    { value: 1200, label: 'מודיעין-מכבים-רעות' },
    { value: 269, label: 'מולדת' },
    { value: 1163, label: 'מורן' },
    { value: 1178, label: 'מורשת' },
    { value: 208, label: 'מוצא עילית' },
    { value: 481, label: 'מגאר' },
    { value: 635, label: 'מוקייבלה' },
    { value: 3787, label: 'נעלה' },
    { value: 158, label: 'נען' },
    { value: 524, label: 'נאעורה' },
    { value: 3713, label: 'נעמ"ה' },
    { value: 1276, label: 'אשבל' },
    { value: 3646, label: 'חמדת' },
    { value: 844, label: 'נחל עוז' },
    { value: 1267, label: 'שיטים' },
    { value: 2045, label: 'נחלה' },
    { value: 80, label: 'נהלל' },
    { value: 3767, label: 'נחליאל' },
    { value: 809, label: 'נחם' },
    { value: 9100, label: 'נהריה' },
    { value: 522, label: 'נחף' },
    { value: 433, label: 'נחשולים' },
    { value: 777, label: 'נחשון' },
    { value: 705, label: 'נחשונים' },
    { value: 1041, label: 'נצאצרה (שבט)' },
    { value: 1254, label: 'נטף' },
    { value: 4014, label: 'נטור' },
    { value: 1366, label: 'נווה' },
    { value: 7300, label: 'נצרת' },
    { value: 1061, label: 'נוף הגליל' },
    { value: 4551, label: 'נאות גולן' },
    { value: 1124, label: 'נאות הכיכר' },
    { value: 408, label: 'נאות מרדכי' },
    { value: 186, label: 'נעורים' },
    { value: 315, label: 'נגבה' },
    { value: 449, label: 'נחלים' },
    { value: 309, label: 'נהורה' },
    { value: 59, label: 'נחושה' },
    { value: 523, label: 'ניין' },
    { value: 1143, label: 'נס עמים' },
    { value: 825, label: 'נס הרים' },
    { value: 7200, label: 'נס ציונה' },
    { value: 2500, label: 'נשר' },
    { value: 1369, label: 'נטע' },
    { value: 174, label: 'נטעים' },
    { value: 7400, label: 'נתניה' },
    { value: 1242, label: 'נתיב העשרה' },
    { value: 3555, label: 'נתיב הגדוד' },
    { value: 693, label: 'נתיב הל"ה' },
    { value: 792, label: 'נתיב השיירה' },
    { value: 246, label: 'נתיבות' },
    { value: 1147, label: 'נטועה' },
    { value: 396, label: 'נבטים' },
    { value: 4303, label: 'נווה אטי"ב' },
    { value: 926, label: 'נווה אבות' },
    { value: 3725, label: 'נווה דניאל' },
    { value: 296, label: 'נווה איתן' },
    { value: 1279, label: 'נווה חריף' },
    { value: 405, label: 'נווה אילן' },
    { value: 1071, label: 'נווה מיכאל' },
    { value: 827, label: 'נווה מבטח' },
    { value: 1259, label: 'נווה שלום' },
    { value: 590, label: 'נווה אור' },
    { value: 312, label: 'נווה ים' },
    { value: 686, label: 'נווה ימין' },
    { value: 858, label: 'נווה ירק' },
    { value: 1057, label: 'נווה זוהר' },
    { value: 435, label: 'נצר סרני' },
    { value: 3655, label: 'ניל"י' },
    { value: 348, label: 'ניר עם' },
    { value: 2048, label: 'ניר עקיבא' },
    { value: 553, label: 'ניר בנים' },
    { value: 256, label: 'ניר דוד (תל עמל)' },
    { value: 808, label: 'ניר אליהו' },
    { value: 769, label: 'ניר עציון' },
    { value: 720, label: 'ניר גלים' },
    { value: 11, label: 'ניר ח"ן' },
    { value: 2047, label: 'ניר משה' },
    { value: 69, label: 'ניר עוז' },
    { value: 165, label: 'ניר יפה' },
    { value: 699, label: 'ניר ישראל' },
    { value: 402, label: 'ניר יצחק' },
    { value: 331, label: 'ניר צבי' },
    { value: 3620, label: 'נערן' },
    { value: 602, label: 'נירים' },
    { value: 1236, label: 'נירית' },
    { value: 351, label: 'ניצן' },
    { value: 1419, label: 'ניצן ב\'' },
    { value: 1195, label: 'ניצנה (קהילת חינוך)' },
    { value: 851, label: 'ניצני עוז' },
    { value: 1280, label: 'ניצני סיני' },
    { value: 359, label: 'ניצנים' },
    { value: 15, label: 'נועם' },
    { value: 257, label: 'נופך' },
    { value: 3790, label: 'נופים' },
    { value: 1284, label: 'נופית' },
    { value: 55, label: 'נוגה' },
    { value: 3726, label: 'נוקדים' },
    { value: 447, label: 'נורדיה' },
    { value: 4304, label: 'נוב' },
    { value: 833, label: 'נורית' },
    { value: 4010, label: 'אודם' },
    { value: 31, label: 'אופקים' },
    { value: 810, label: 'עופר' },
    { value: 3617, label: 'עופרה' },
    { value: 1046, label: 'אוהד' },
    { value: 737, label: 'עולש' },
    { value: 1108, label: 'אומן' },
    { value: 666, label: 'עומר' },
    { value: 680, label: 'אומץ' },
    { value: 1020, label: 'אור עקיבא' },
    { value: 1294, label: 'אור הגנוז' },
    { value: 67, label: 'אור הנר' },
    { value: 2400, label: 'אור יהודה' },
    { value: 780, label: 'אורה' },
    { value: 882, label: 'אורנים' },
    { value: 3760, label: 'אורנית' },
    { value: 2012, label: 'אורות' },
    { value: 4013, label: 'אורטל' },
    { value: 3748, label: 'עתניאל' },
    { value: 32, label: 'עוצם' },
    { value: 2059, label: 'פעמי תש"ז' },
    { value: 597, label: 'פלמחים' },
    { value: 1151, label: 'פארן' },
    { value: 7800, label: 'פרדס חנה-כרכור' },
    { value: 171, label: 'פרדסיה' },
    { value: 599, label: 'פרוד' },
    { value: 749, label: 'פטיש' },
    { value: 838, label: 'פדיה' },
    { value: 3768, label: 'פדואל' },
    { value: 750, label: 'פדויים' },
    { value: 1185, label: 'פלך' },
    { value: 3723, label: 'פני חבר' },
    { value: 536, label: 'פקיעין (בוקייעה)' },
    { value: 281, label: 'פקיעין חדשה' },
    { value: 2053, label: 'פרזון' },
    { value: 1231, label: 'פרי גן' },
    { value: 3659, label: 'פסגות' },
    { value: 7900, label: 'פתח תקווה' },
    { value: 839, label: 'פתחיה' },
    { value: 3615, label: 'פצאל' },
    { value: 767, label: 'פורת' },
    { value: 1313, label: 'פוריה עילית' },
    { value: 1104, label: 'פוריה - כפר עבודה' },
    { value: 1105, label: 'פוריה - נווה עובד' },
    { value: 1234, label: 'קבועה (שבט)' },
    { value: 1211, label: 'קדרים' },
    { value: 195, label: 'קדימה-צורן' },
    { value: 638, label: 'קלנסווה' },
    { value: 3601, label: 'קליה' },
    { value: 3640, label: 'קרני שומרון' },
    { value: 972, label: 'קוואעין (שבט)' },
    { value: 4100, label: 'קצרין' },
    { value: 3781, label: 'קדר' },
    { value: 392, label: 'קדמה' },
    { value: 3557, label: 'קדומים' },
    { value: 4024, label: 'קלע' },
    { value: 414, label: 'קלחים' },
    { value: 1167, label: 'קיסריה' },
    { value: 4006, label: 'קשת' },
    { value: 1052, label: 'קטורה' },
    { value: 334, label: 'קבוצת יבנה' },
    { value: 4025, label: 'קדמת צבי' },
    { value: 615, label: 'קדרון' },
    { value: 78, label: 'קרית ענבים' },
    { value: 3611, label: 'קרית ארבע' },
    { value: 6800, label: 'קרית אתא' },
    { value: 9500, label: 'קרית ביאליק' },
    { value: 469, label: 'קרית עקרון' },
    { value: 2630, label: 'קרית גת' },
    { value: 1034, label: 'קרית מלאכי' },
    { value: 8200, label: 'קרית מוצקין' },
    { value: 3746, label: 'קרית נטפים' },
    { value: 2620, label: 'קרית אונו' },
    { value: 412, label: 'קרית שלמה' },
    { value: 2800, label: 'קרית שמונה' },
    { value: 2300, label: 'קרית טבעון' },
    { value: 9600, label: 'קרית ים' },
    { value: 1137, label: 'קרית יערים' },
    { value: 2039, label: 'קרית יערים(מוסד)' },
    { value: 766, label: 'קוממיות' },
    { value: 1179, label: 'קורנית' },
    { value: 964, label: 'קודייראת א-צאנע(שבט)' },
    { value: 8700, label: 'רעננה' },
    { value: 1161, label: 'רהט' },
    { value: 1069, label: 'רם-און' },
    { value: 135, label: 'רמת דוד' },
    { value: 8600, label: 'רמת גן' },
    { value: 184, label: 'רמת הכובש' },
    { value: 2650, label: 'רמת השרון' },
    { value: 335, label: 'רמת השופט' },
    { value: 4701, label: 'רמת מגשימים' },
    { value: 127, label: 'רמת רחל' },
    { value: 460, label: 'רמת רזיאל' },
    { value: 122, label: 'רמת ישי' },
    { value: 178, label: 'רמת יוחנן' },
    { value: 339, label: 'רמת צבי' },
    { value: 543, label: 'ראמה' },
    { value: 8500, label: 'רמלה' },
    { value: 4702, label: 'רמות' },
    { value: 206, label: 'רמות השבים' },
    { value: 735, label: 'רמות מאיר' },
    { value: 445, label: 'רמות מנשה' },
    { value: 372, label: 'רמות נפתלי' },
    { value: 789, label: 'רנן' },
    { value: 1228, label: 'רקפת' },
    { value: 990, label: 'ראס עלי' },
    { value: 1225, label: 'רביד' },
    { value: 713, label: 'רעים' },
    { value: 444, label: 'רגבים' },
    { value: 390, label: 'רגבה' },
    { value: 3568, label: 'ריחן' },
    { value: 854, label: 'רחוב' },
    { value: 8400, label: 'רחובות' },
    { value: 540, label: 'ריחאניה' },
    { value: 542, label: 'ריינה' },
    { value: 922, label: 'רכסים' },
    { value: 437, label: 'רשפים' },
    { value: 1260, label: 'רתמים' },
    { value: 564, label: 'רבדים' },
    { value: 3795, label: 'רבבה' },
    { value: 354, label: 'רביבים' },
    { value: 2051, label: 'רווחה' },
    { value: 2016, label: 'רוויה' },
    { value: 3565, label: 'רימונים' },
    { value: 616, label: 'רינתיה' },
    { value: 8300, label: 'ראשון לציון' },
    { value: 247, label: 'רשפון' },
    { value: 3619, label: 'רועי' },
    { value: 2640, label: 'ראש העין' },
    { value: 26, label: 'ראש פינה' },
    { value: 3602, label: 'ראש צורים' },
    { value: 3782, label: 'רותם' },
    { value: 362, label: 'רוחמה' },
    { value: 997, label: 'רומת הייב' },
    { value: 539, label: 'רומאנה' },
    { value: 419, label: 'סעד' },
    { value: 454, label: 'סער' },
    { value: 525, label: 'סאג\'ור' },
    { value: 7500, label: 'סח\'נין' },
    { value: 3567, label: 'סלעית' },
    { value: 1245, label: 'סלמה' },
    { value: 1156, label: 'סמר' },
    { value: 636, label: 'צנדלה' },
    { value: 1176, label: 'ספיר' },
    { value: 126, label: 'שריד' },
    { value: 578, label: 'סאסא' },
    { value: 587, label: 'סביון' },
    { value: 989, label: 'סואעד (כמאנה) (שבט)' },
    { value: 942, label: 'סואעד (חמרייה)' },
    { value: 1170, label: 'סייד (שבט)' },
    { value: 1223, label: 'שדי אברהם' },
    { value: 885, label: 'שדה בוקר' },
    { value: 36, label: 'שדה דוד' },
    { value: 861, label: 'שדה אליעזר' },
    { value: 304, label: 'שדה אליהו' },
    { value: 2015, label: 'שדי חמד' },
    { value: 721, label: 'שדה אילן' },
    { value: 18, label: 'שדה משה' },
    { value: 259, label: 'שדה נחום' },
    { value: 329, label: 'שדה נחמיה' },
    { value: 1058, label: 'שדה ניצן' },
    { value: 2057, label: 'שדי תרומות' },
    { value: 739, label: 'שדה עוזיהו' },
    { value: 284, label: 'שדה ורבורג' },
    { value: 142, label: 'שדה יעקב' },
    { value: 2008, label: 'שדה יצחק' },
    { value: 293, label: 'שדה יואב' },
    { value: 2049, label: 'שדה צבי' },
    { value: 1031, label: 'שדרות' },
    { value: 27, label: 'שדות מיכה' },
    { value: 327, label: 'שדות ים' },
    { value: 1286, label: 'שגב-שלום' },
    { value: 2046, label: 'סגולה' },
    { value: 1132, label: 'שניר' },
    { value: 538, label: 'שעב' },
    { value: 4009, label: 'שעל' },
    { value: 856, label: 'שעלבים' },
    { value: 661, label: 'שער אפרים' },
    { value: 237, label: 'שער העמקים' },
    { value: 264, label: 'שער הגולן' },
    { value: 921, label: 'שער מנשה' },
    { value: 3720, label: 'שערי תקווה' },
    { value: 306, label: 'שדמות דבורה' },
    { value: 3578, label: 'שדמות מחולה' },
    { value: 692, label: 'שפיר' },
    { value: 7, label: 'שחר' },
    { value: 1266, label: 'שחרות' },
    { value: 1373, label: 'שלווה במדבר' },
    { value: 873, label: 'שלווה' },
    { value: 432, label: 'שמרת' },
    { value: 366, label: 'שמיר' },
    { value: 1287, label: 'שני' },
    { value: 3649, label: 'שקד' },
    { value: 292, label: 'שרונה' },
    { value: 398, label: 'שרשרת' },
    { value: 3571, label: 'שבי שומרון' },
    { value: 282, label: 'שבי ציון' },
    { value: 324, label: 'שאר ישוב' },
    { value: 555, label: 'שדמה' },
    { value: 8800, label: 'שפרעם' },
    { value: 232, label: 'שפיים' },
    { value: 846, label: 'שפר' },
    { value: 658, label: 'שייח\' דנון' },
    { value: 1160, label: 'שכניה' },
    { value: 812, label: 'שלומי' },
    { value: 439, label: 'שלוחות' },
    { value: 1233, label: 'שקף' },
    { value: 1045, label: 'שתולה' },
    { value: 763, label: 'שתולים' },
    { value: 527, label: 'שזור' },
    { value: 865, label: 'שיבולים' },
    { value: 913, label: 'שבלי - אום אל-גנם' },
    { value: 1165, label: 'שילת' },
    { value: 3641, label: 'שילה' },
    { value: 3784, label: 'שמעה' },
    { value: 1197, label: 'נאות סמדר' },
    { value: 741, label: 'שואבה' },
    { value: 1304, label: 'שוהם' },
    { value: 614, label: 'שומרה' },
    { value: 1265, label: 'שומריה' },
    { value: 415, label: 'שוקדה' },
    { value: 1235, label: 'שורשים' },
    { value: 456, label: 'שורש' },
    { value: 224, label: 'שושנת העמקים' },
    { value: 1102, label: 'צוקי ים' },
    { value: 394, label: 'שובל' },
    { value: 761, label: 'שובה' },
    { value: 610, label: 'סתריה' },
    { value: 1238, label: 'סופה' },
    { value: 526, label: 'סולם' },
    { value: 3756, label: 'סוסיה' },
    { value: 752, label: 'תעוז' },
    { value: 462, label: 'טל שחר' },
    { value: 1181, label: 'טל-אל' },
    { value: 2050, label: 'תלמי ביל"ו' },
    { value: 2003, label: 'תלמי אלעזר' },
    { value: 1051, label: 'תלמי אליהו' },
    { value: 744, label: 'תלמי יפה' },
    { value: 727, label: 'תלמי יחיאל' },
    { value: 1237, label: 'תלמי יוסף' },
    { value: 3788, label: 'טלמון' },
    { value: 8900, label: 'טמרה' },
    { value: 547, label: 'טמרה (יזרעאל)' },
    { value: 970, label: 'תראבין א-צאנע (שבט)' },
    { value: 778, label: 'תרום' },
    { value: 2730, label: 'טייבה' },
    { value: 497, label: 'טייבה (בעמק)' },
    { value: 2062, label: 'תאשור' },
    { value: 1214, label: 'טפחות' },
    { value: 103, label: 'תל עדשים' },
    { value: 5000, label: 'תל אביב - יפו' },
    { value: 154, label: 'תל מונד' },
    { value: 719, label: 'תל קציר' },
    { value: 1054, label: 'תל שבע' },
    { value: 1283, label: 'תל תאומים' },
    { value: 287, label: 'תל יצחק' },
    { value: 84, label: 'תל יוסף' },
    { value: 1177, label: 'טללים' },
    { value: 814, label: 'תלמים' },
    { value: 3719, label: 'תלם' },
    { value: 3743, label: 'טנא' },
    { value: 2002, label: 'תנובות' },
    { value: 3563, label: 'תקוע' },
    { value: 665, label: 'תקומה' },
    { value: 6700, label: 'טבריה' },
    { value: 2061, label: 'תדהר' },
    { value: 709, label: 'תפרח' },
    { value: 163, label: 'תימורים' },
    { value: 1244, label: 'תמרת' },
    { value: 2100, label: 'טירת כרמל' },
    { value: 663, label: 'טירת יהודה' },
    { value: 268, label: 'טירת צבי' },
    { value: 2720, label: 'טירה' },
    { value: 10, label: 'תירוש' },
    { value: 3558, label: 'תומר' },
    { value: 962, label: 'טובא-זנגריה' },
    { value: 498, label: 'טורעאן' },
    { value: 1083, label: 'תושיה' },
    { value: 1172, label: 'תובל' },
    { value: 446, label: 'אודים' },
    { value: 2710, label: 'אום אל-פחם' },
    { value: 2024, label: 'אום אל-קוטוף' },
    { value: 957, label: 'עוקבי (בנו עוקבה)' },
    { value: 403, label: 'אורים' },
    { value: 278, label: 'אושה' },
    { value: 826, label: 'עוזה' },
    { value: 528, label: 'עוזייר' },
    { value: 1133, label: 'ורדון' },
    { value: 3639, label: 'ורד יריחו' },
    { value: 1138, label: 'יעד' },
    { value: 795, label: 'יערה' },
    { value: 1117, label: 'יעל' },
    { value: 577, label: 'יד בנימין' },
    { value: 758, label: 'יד חנה' },
    { value: 1134, label: 'יד השמונה' },
    { value: 358, label: 'יד מרדכי' },
    { value: 775, label: 'יד נתן' },
    { value: 64, label: 'יד רמב"ם' },
    { value: 499, label: 'יפיע' },
    { value: 3566, label: 'יפית' },
    { value: 798, label: 'יגל' },
    { value: 96, label: 'יגור' },
    { value: 1158, label: 'יהל' },
    { value: 811, label: 'יכיני' },
    { value: 1295, label: 'יאנוח-ג\'ת' },
    { value: 753, label: 'ינוב' },
    { value: 3647, label: 'יקיר' },
    { value: 417, label: 'יקום' },
    { value: 2026, label: 'ירדנה' },
    { value: 718, label: 'ירחיב' },
    { value: 183, label: 'ירקונה' },
    { value: 575, label: 'יסעור' },
    { value: 828, label: 'ישרש' },
    { value: 1227, label: 'יתד' },
    { value: 2660, label: 'יבנה' },
    { value: 46, label: 'יבנאל' },
    { value: 759, label: 'יציץ' },
    { value: 1044, label: 'יעף' },
    { value: 1144, label: 'ידידה' },
    { value: 233, label: 'כפר ידידיה' },
    { value: 409, label: 'יחיעם' },
    { value: 9400, label: 'יהוד-מונוסון' },
    { value: 831, label: 'ירוחם' },
    { value: 916, label: 'ישע' },
    { value: 440, label: 'יסודות' },
    { value: 29, label: 'יסוד המעלה' },
    { value: 1232, label: 'יבול' },
    { value: 134, label: 'יפעת' },
    { value: 453, label: 'יפתח' },
    { value: 2011, label: 'ינון' },
    { value: 623, label: 'יראון' },
    { value: 502, label: 'ירכא' },
    { value: 805, label: 'ישעי' },
    { value: 3607, label: 'ייט"ב' },
    { value: 3749, label: 'יצהר' },
    { value: 452, label: 'יזרעאל' },
    { value: 1112, label: 'יודפת' },
    { value: 4007, label: 'יונתן' },
    { value: 240, label: 'יקנעם עילית' },
    { value: 241, label: 'יקנעם (מושבה)' },
    { value: 803, label: 'יושיביה' },
    { value: 866, label: 'יטבתה' },
    { value: 2009, label: 'יובל' },
    { value: 1226, label: 'יובלים' },
    { value: 2742, label: 'זבארגה (שבט)' },
    { value: 1079, label: 'צפרירים' },
    { value: 594, label: 'צפריה' },
    { value: 816, label: 'זנוח' },
    { value: 975, label: 'זרזיר' },
    { value: 815, label: 'זבדיאל' },
    { value: 413, label: 'צאלים' },
    { value: 8000, label: 'צפת' },
    { value: 799, label: 'זכריה' },
    { value: 796, label: 'צלפון' },
    { value: 1290, label: 'זמר' },
    { value: 818, label: 'זרחיה' },
    { value: 2064, label: 'זרועה' },
    { value: 612, label: 'צרופה' },
    { value: 788, label: 'זיתן' },
    { value: 9300, label: 'זכרון יעקב' },
    { value: 1065, label: 'זמרת' },
    { value: 613, label: 'ציפורי' },
    { value: 584, label: 'זיקים' },
    { value: 1213, label: 'צבעון' },
    { value: 1150, label: 'צופר' },
    { value: 198, label: 'צופית' },
    { value: 1111, label: 'צופיה' },
    { value: 44, label: 'זוהר' },
    { value: 1136, label: 'צוחר' },
    { value: 567, label: 'צרעה' },
    { value: 465, label: 'צובה' },
    { value: 3791, label: 'צופים' },
    { value: 1113, label: 'צור הדסה' },
    { value: 276, label: 'צור משה' },
    { value: 1148, label: 'צור נתן' },
    { value: 1345, label: 'צור יצחק' },
    { value: 774, label: 'צוריאל' },
    { value: 1221, label: 'צורית' },
    { value: 1180, label: 'צביה' },
    { value: 3777, label: 'סנסנה' },
    { value: 1336, label: 'אירוס' },
    { value: 1365, label: 'אליאב' },
    { value: 1374, label: 'כרמי קטיף' },
    { value: 1377, label: 'שבי דרום' },
    { value: 4035, label: 'נמרוד' },
    { value: 3822, label: 'רחלים' },
    { value: 3823, label: 'גני מודיעין' },
    { value: 3824, label: 'עמיחי' },
    { value: 3825, label: 'מבואות יריחו' },
    { value: 1378, label: 'שיזף' }
];

export default cityList;