export const strings = {
	overQutaLeadQty: 'ליד זה לא יישלח עקב חריגה ממכסת הלידים המותרת לשליחה',
	relatedCourse: 'קורס מקביל',
	question_improvement_gpa: 'מעוניין בשיפור בגרויות',
	question_want_pet: 'מעוניין בפסיכומטרי',
	question_want_english_improvment: 'מעוניין בשיפור אנגלית',
	question_want_dorms: 'מעוניין במעונות',
	studyType: 'סוג לימודים',
	readMore: 'קרא עוד',
	openSearch: 'חיפוש חופשי',
	requiredFirstName: 'יש לעדכן שם פרטי',
	requiredLastName: 'יש לעדכן שם משפחה',
	requiredAffiliate: 'יש לבחור שותף או לסמן שיחה נכנסת',
	requiredEmail: 'יש לעדכן כתובת מייל',
	requiredPhone: 'יש לעדכן מספר טלפון',
	requiredCity: 'יש לעדכן עיר מגורים',
	missingRequiredFields: 'יש לעדכן את שדות החובה',
	managerApproval: 'הגדל מכסה',
	managerApproved: 'מכסה הוגדלה',
	managerApprovalError: 'הגדלת המכסה לא אושרה',
	approve: 'אשר',
	cancel: 'בטל',
	yes: 'כן',
	km: 'ק״מ',
	no: 'לא',
	managerCode: 'קוד מנהל',
	maxSale: 'לא הגעת למקסימום מכירה',
	attention: 'שימ/י לב!',
	hasBa: 'תואר ראשון',
	hasMa: 'תואר שני',
	hasEngineering: 'תעודת הנדסאי',
	SideBar: {
		search: 'סנן',
		tabs: {
			other: 'סננים נוספים',
			activity: 'בוקר/ערב',
		},
		reset: 'איפוס בחירות'
	},
	MemberInfo: {
		welcomText: 'ברוך הבא, %s',
		guestName: 'אורח',
		counterLabel: 'מספר לידים שנשלחו: ',
		pushNotification: 'התראה ראשית'
	},
	affiliateSelector: {
		title: 'בחירת שותף',
		label: 'בחירת שותף לרשומה',
		placeholder: 'בחירת שותף',
		notFound: 'לא נמצאו שותפים',
		loading: 'מחפש...',
		success: 'השותף %s נבחר בהצלחה',
	},
	personalDetails: {
		incomingCall: 'Incoming Call',
		pet: 'פסיכומטרי',
		gpa: 'בגרות',
		langTest: 'מבחן שפה',
		globalError: 'יש שגיאה בטופס',
		petSelections: {
			has: 'יש',
			none: 'ללא',
		},
		langTestSelections: {
			has: 'יש',
			none: 'ללא',
		},
		gpaSelections: {
			none: 'ללא',
			full: 'מלאה',
			semi: 'חלקית',
			twelve: '12 שנות לימוד',
			less12: 'פחות מ12 שנות לימוד',
		},
		validation: {
			letters: 'אותיות בלבד',
			numbers: 'מספרים בלבד',
			personalId: 'תעודת הזהות לא תקינה',
			email: 'מייל לא תקין',
			numbersMinMax: 'מספרים בלבד בין %min% ל %max%',
			phone: 'מספר הטלפון שהוזן אינו תקין',
			callTime:'חובה להזין שעת חזרה ללקוח'
		}
	},
	Summery: {
		show_quesctions:'הצג שאלות',
		branchSelection: 'סניף',
		personalInfoForm: {
			title: 'פרטים אישיים',
			email: 'מייל',
			phone: 'טלפון',
			full_name: 'שם מלא',
			first_name: 'שם פרטי',
			last_name: 'שם משפחה',
			personal_id: 'תעודת זהות',
			pet: 'פסיכומטרי',
			gpa: 'ממוצע בגוריות',
			notice: 'הערות',
			saveBtn: 'שמירה',
		},
		Notifications: {
			title: 'שימו לב,',
			has_questions: 'יש שאלות שעדיין לא עניתם עליהן',
			max_courses: 'לא ניתן להוסיף עוד מסלולים',
		},
		Tooltip: {
			has_questions: 'יש שאלות ששיכות למסלול שלא עניתם עליהן',
			question_not_valid: 'ישנם שאלות ששיכות למסלול שפוסלות את הליד',
		},
		title: "סל מסלולים",
		title_max_courses: " - <span>לא ניתן להוסיף עוד מסלולים</span>",
		upsales: 'Upsales',
		show_questions: 'צפייה בשאלות',
		send_leads: 'שליחת רשומות',
		upsales_course_popup_title: 'מסלולים נוספים',
		lead_sent: 'תודה, הליד נשלח בהצלחה',
		error_lead_sent: 'אופס, לא הצלחנו לשלוח את הליד',
		no_cousrses_found: 'לא נמצאו קורסים לשליחה',
	},
	resultOrderby: {
		placeholder: 'סידור תצוגה',
		options: {
			rating: 'סידור תצוגה לפי דירוג',
			location: 'סידור תצוגה לפי מרחק ( קרוב לרחוק )',
			departments: 'סידור לפי תחומים',
		}
	},
	CourseCard: {
		admissions: 'תנאי קבלה',
		institution_details: 'פרטי מוסד לימוד',
		course_details: 'פרטי מסלול',
		linked_courses: 'מסלולים מקבילים',
		settings: 'הגדרות מוסד/מסלול',
		questions_filter: 'שאלות סינון',
		read_all: 'קרא הכל',
		question: 'שאלה',
		pet: 'ציון פסיכומטרי מינמלי',
		gpa: 'ציון בגרות מינימלי',
		age: 'גיל',
		expanded_view: 'תצוגה מורחבת',
		regular_view: 'תצוגה רגילה',
		add: 'הוספה לסל',
		added: 'נוסף לסל',
		institution_included: 'מסלול לימודים אחר של המוסד כבר נמצא בסל המסלולים',
		cart_is_full: 'הגעת למקסימום המסלולים שניתן להוסיף',
		not_relevant: "סמן כ'לא רלוונטי'",
		relevant: "סמן כ'רלוונטי'",
		branches: "סניפים:",
	},
	ValidateEmail:{
		title:'נא אשר תקינות מייל ותעודת זהות'
	},
	close_all: 'סגור הכל',
	radeOrder: 'דירוג מוסד:',
	rank: 'דירוג',
	distance: 'מרחק:',
	leadNotFound: 'לא נצאו פרטים המתאימים למספר המזהה',
	StickyNotification: 'שים לב! -',
	logout: 'התנתק'
}