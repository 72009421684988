import React from 'react'
import Loader from 'react-loader-spinner'
import './loader.css'

const LoaderC = (props) => {
  return (
    <div className='loaderContainer'>
      <Loader
        type={props.type || "Oval"} 
        color="#2B2D41"
        height={80}
        width={80}
        className='loader'
        secondaryColor={'red'}
      // timeout={3000} //3 secs
      />
    </div>
  )
}


export default LoaderC; 