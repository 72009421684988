import React, { useState, useRef, useEffect } from 'react'
import './sideBar.css'
import '../serach/serach.css'
import ChechBox from '../checkBox/checkBox'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import global from '../../res/global'
import MyContext from '../../store/MyContext'
import { strings } from '../../strings'
import { Icon } from '@iconify/react';
import educationIcon from '@iconify-icons/cil/education';
import locationIcon from '@iconify-icons/carbon/location';
import moreOutlined from '@iconify-icons/ant-design/more-outlined';
import buildFilled from '@iconify-icons/ant-design/build-filled';
import cityList from '../../res/city-list'
import Select from 'react-select'

var courseTypeIDarr = '';
var filterTypeIDarr = '';

var locationObj = {
	address: {
		name: "",
		city: "",
		country: "",
		lat: "",
		long: "",
	},
	range: 0,
	relocation: true
}

const Tab = (props) => {
	const tabRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false)
	var up = require('../../assets/images/vertecalArrows/goldenArrwo_up.png')
	var down = require('../../assets/images/vertecalArrows/goldenArrwo_down.png')

	return (
		<div className='SB-Tab '  >
			<div className='header-tab' onClick={() => setIsOpen(!isOpen)}>
				<div>
					<img src={isOpen ? up : down} />
				</div>

				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<span className='tabTitle'>{props.text}</span>
					{props.image &&
						<img style={{ width: '23px', height: '23px', backgroundSize: 'strat' }} src={props.image} />
					}
					{props.icon}
				</div>
			</div>

			<div className={`tab-children${isOpen ? ' active' : ''} ${props.containerName}`} ref={tabRef}>
				{props.children}
			</div>
		</div>
	)
}

var lsHeight = 50;

const SideBar = (props) => {
	var cArr = [false, false, false]
	var lsls = JSON.parse(window.sessionStorage.getItem('lastSearches', []))
	const [distanceValue, setDistanceValue] = useState(0);
	const [corseType, setCorseType] = useState(cArr);
	const [otherFilter, setOtherFilter] = useState([]);
	const [selectlocation, setSelectlocation] = useState('')
	const [autoComplete, setAutoComplete] = useState(null)
	const [searchResutList, setSearchResutList] = useState(false)
	const [relocation, setrelocation] = useState(false)
	const [lastSearches, setLAstSearches] = useState(lsls || [])

	const ref = useRef(null)
	const wrapperRef = useRef(null);
	const locationTabRef = useRef(null);

	var top = (57 + ref?.current?.offsetTop + 'px');
	var left = (ref?.current?.offsetLeft + 'px');
	var width = ref?.current?.clientWidth + 'px';
	var mcontext = null;

	useEffect(() => {
		function handleClickOutside(event) {
			if (!ref?.current?.contains(event.target) && !wrapperRef?.current?.contains(event.target)) {
				setSearchResutList(false)
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [locationTabRef]);

	const LastSearches = (props) => {
		return (
			<div className='LastSearches'>
				<span className='lastSearchText' style={{ fontWeight: 'Medium', textDecoration: 'underline', marginRight: '20px' }}>{props.text}</span>
				<span className='lastSearchText' onClick={() => deleteLastSearchItem(props.id)}>{'x'}</span>
			</div>
		)
	}

	const distanceUpdated = (newDistance) => {

		
		locationObj.range = newDistance;

		global.distanceFilter = newDistance;

		mcontext.setLocation({ ...locationObj });
	}

	function deleteLastSearchItem(id) {
		var arr = lastSearches;
		arr.splice(id, 1)

		setLAstSearches([...arr])
		window.sessionStorage.setItem('lastSearches', JSON.stringify(arr))
	}


	const onChechedChange = (index, type, checked, data) => {
		var arr = [];
		var ls = lastSearches;
		var lstext = '';
		switch (type) {
			case 'corseType': {
				arr = [...corseType]
				arr[index] = checked
				var strArr;
				if (checked && !courseTypeIDarr.includes(data.id)) {
					courseTypeIDarr += data.id + ',';
					strArr = courseTypeIDarr.split(',')
					var y = '';

					strArr.forEach(i => {
						var x = props.corseType.find(j => (j.id == i))
						x && (lstext += (lstext.length > 0 ? ' > ' : '') + x.name)
					})
					lstext && ls.push(lstext)
				}

				if (!checked && courseTypeIDarr.includes(data.id)) {
					var id = data.id + '';
					var indexID = courseTypeIDarr.indexOf(data.id);
					courseTypeIDarr = courseTypeIDarr.slice(0, indexID) + courseTypeIDarr.slice(indexID + id.length + 1)

					strArr = courseTypeIDarr.split(',')

					strArr.forEach(i => {
						var x = props.corseType.find(j => (j.id == i))
						x && (lstext += (lstext.length > 0 ? ' > ' : '') + x.name)
					})
					lstext && ls.push(lstext)
				}

				setLAstSearches(ls)
				window.sessionStorage.setItem('lastSearches', JSON.stringify(ls))

				setCorseType(arr);
				global.courseTypeFilters = courseTypeIDarr;
				// props.corseTypes(courseTypeIDarr)
				mcontext.setCorseTypes(courseTypeIDarr)
				break;
			}
			case 'other': {
				arr = [...otherFilter]
				arr[index] = checked
				var strArr;
				if (checked && !filterTypeIDarr.includes(data.id)) {
					filterTypeIDarr += data.id + ',';
					strArr = filterTypeIDarr.split(',')
					var y = '';

					strArr.forEach(i => {
						var x = props.otherFilter.find(j => (j.id == i))
						x && (lstext += (lstext.length > 0 ? ' > ' : '') + x.name)
					})
					lstext && ls.push(lstext)
				}

				if (!checked && filterTypeIDarr.includes(data.id)) {
					var id = data.id + '';
					var indexID = filterTypeIDarr.indexOf(data.id);
					filterTypeIDarr = filterTypeIDarr.slice(0, indexID) + filterTypeIDarr.slice(indexID + id.length + 1)

					strArr = filterTypeIDarr.split(',')

					strArr.forEach(i => {
						var x = props.otherFilter.find(j => (j.id == i))
						x && (lstext += (lstext.length > 0 ? ' > ' : '') + x.name)
					})
					lstext && ls.push(lstext)
				}

				setLAstSearches(ls)
				window.sessionStorage.setItem('lastSearches', JSON.stringify(ls))
				global.otherFilters = filterTypeIDarr;
				mcontext.setOtherFilters(filterTypeIDarr);
				break;
			}
			default:
				break;
		}
	}

	const renderCorseType = () => {
		return props.corseType.map((courseType, index) => {
			return <ChechBox
				text={`${courseType.name} [${courseType.id}]`}
				textStyle={{ color: '#ffff' }}
				id={'cb' + index}
				checked={corseType[index]}
				onChange={(checked) => onChechedChange(index, 'corseType', checked, courseType)}
				key={index}
			/>
		})
	}

	const renderFilterType = (type = '') => {
		return props.otherFilter.filter(i => {
			if (type === '') {
				return true;
			}
			if (type === '!activity') {
				return !i.slug.includes("morning") && !i.slug.includes("evening");
			}
			if (type === 'activity') {
				return i.slug.includes("morning") || i.slug.includes("evening");
			}
		}).map((i, index) => {
			const otherFiltersArray = mcontext.otherFilters.length ? mcontext.otherFilters.split(',') : null;
			const selected = otherFiltersArray && otherFiltersArray.includes(String(i.id));

			return <ChechBox
				text={`${i.name}`}
				textStyle={{ color: '#ffff' }}
				id={'cb-other' + i.id}
				checked={selected}
				onChange={(checked) => onChechedChange(index, 'other', checked, i)}
				key={index}
			/>
		})
	}

	const searchLocation = (val) => {
		// alert(JSON.stringify(val))

		let valuelabel = val?.label ? val.label : val;
		
		global.fakeCity = valuelabel;

		mcontext.setFakeCity(valuelabel);

		setSelectlocation(val);
		//alert(JSON.stringify(val));
	}

	function renderLastSearches() {
		if (lastSearches && lastSearches.length > 0 && Array.isArray(lastSearches)) {
			return lastSearches.map((i, index) => (<LastSearches text={i} id={index} key={index} />))
		}
	}

	const resetSelections = () => {
		setCorseType(cArr);
		mcontext.setCorseTypes([]);
		setOtherFilter(cArr);
		mcontext.setOtherFilters([]);
		setSelectlocation('');
		mcontext.setLocation('');
		/*global.fakeCity = '';*/
		global.otherFilters = [];
		filterTypeIDarr = [];
		global.courseTypeFilters = [];
		courseTypeIDarr = [];
		filterTypeIDarr = [];
		setDistanceValue(0);
		distanceUpdated(0);
	}

	const compare = (a, b) => {
		if (a.label < b.label) {
			return -1;
		}
		if (a.label > b.label) {
			return 1;
		}
		return 0;
	}

	const sortedList = cityList ? cityList.sort(compare) : '';

	return (
		<MyContext.Consumer>
			{(context) => {
				mcontext = context;
				return (
					<div className='sideBar-container' style={props.style}>
						<img
							className='s-b-x pointer'
							src={require('../../assets/images/x-white.png')}
							onClick={() => { props.shoSideBar(false) }}
						/>
						<div className='sideBar-tab-container'>

							{/* <Tab text={'היסטורית חיפוש'} icon={<Icon icon={fileSearchOutlined} />} cleared={lastSearches.length} containerName="history">
								{renderLastSearches()}
								<span className='lastSearchText' style={{ textDecoration: 'underline', alignSelf: 'flex-start', fontWeight: 'bold' }}
									onClick={() => {
										if (lastSearches.length > 0)
											setLAstSearches([]);
										window.sessionStorage.setItem('lastSearches', JSON.stringify([]))
									}}>
									{'נקה הכל'}
								</span>
							</Tab> */}

							<Tab text={'סוג לימודים'} icon={<Icon icon={educationIcon} />} containerName="course-type">
								{renderCorseType()}
							</Tab>
							<Tab ref={locationTabRef?.current} text={'מיקום'} icon={<Icon icon={locationIcon} />} containerName="location">
								<Select
									className='locationInput'
									isClearable
									placeholder={'הקלד עיר/אזור'}
									isRtl={true}
									value = {selectlocation}
									options={sortedList}
									onChange={(value) => searchLocation(value)}
									
								/>

								<div className='locationKM'>
									<span style={{ fontSize: '16px', color: '#ffff' }}>{'מרחק ממקום מגורים'}</span>
									<span className='KM'>{distanceValue > 0 ? distanceValue + ' ק"מ' : ''}</span>
								</div>

								<RangeSlider
									value={parseInt(distanceValue)}
									variant={'secondary'}
									onChange={changeEvent => {
										setDistanceValue(changeEvent.target.value);
									}}
									inputProps={{
										onMouseUp: (changeEvent) => {
											distanceUpdated(changeEvent.target.value);
										},
										style: {
											width: '270px',
											color: '#8D99AE',
										}
									}}
								/>
							</Tab>
							<Tab text={strings.SideBar.tabs.activity} icon={<Icon icon={buildFilled} />} containerName="filter-type activity">
								{renderFilterType('activity')}
							</Tab>
							<Tab text={strings.SideBar.tabs.other} icon={<Icon icon={moreOutlined} />} containerName="filter-type">
								{renderFilterType('!activity')}
							</Tab>
							<div className="button-container">
								<button className="btn"
									onClick={() => resetSelections()} >
									{strings.SideBar.reset}
								</button>
							</div>

						</div>
					</div>
				)
			}}
		</MyContext.Consumer>
	)
}

export default SideBar;