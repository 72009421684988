import React, { useState, useEffect } from 'react';
import Btn from '../Btn/btn';
import global from '../../res/global';
import MyContext from '../../store/MyContext';
import * as _ from 'underscore';
import { strings } from '../../strings';
import Tooltip from '../Tooltip/Tooltip';
import { deleteFromCart, updateLadApi, updateBasketCourseApi } from '../../res/api';
import { isValidQuestionsAnswered } from '../../generalUtill';
import PersonalInfoPopup from '../personalInfoPopup/personalInfoPopup';
import IntitutionBranches from '../institutionBranches/institutionBranches';

const BasketCourseItem = (props) => {

    const { setCourses, isAllRequiredQuestionsAnswered, course, index } = props;

    const [editPopup, setEditPopup] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(course.selected_branch);
    const [showDeleteWarning, setshowDeleteWarning] = useState(false);

    var mContext = null;
    var data = course.course;

    const branchChanged = (branch) => {
        setSelectedBranch(branch);

        const basketCourseUpdateData = [
            {
                id: course.course.id,
                branch: branch.id
            }
        ]

        updateBasketCourseApi(basketCourseUpdateData, null, mContext).then(response => {
            setCourses([...global.lead[0].courses]);
        });
    }

    const deleteCourseRequest = () => {
        setshowDeleteWarning(true);
    }

    const doesLeadReqCallTime = (courses) => {
		let res= false;
		courses.forEach(course_item => {
			if (course_item.course.institution.requirecalltime) {
				res = true; return res;
			}
		});
	
		return res;
	}

    const removeDeletedCourseQuestions = (questions) => {
        var contextAnswers = mContext?.answers

        const newContextAnswers = contextAnswers.filter((contextAnswer) => {
            
            if (!questions.some((question) => contextAnswer?.question === question?.id)) {
                return contextAnswer;
            };
        });

        console.log([...newContextAnswers]);

        mContext.setAnswers([...newContextAnswers]);
    }

    const deleteCorse = () => {

        mContext.setLoading(true)

        data.questions && removeDeletedCourseQuestions(data.questions);

        deleteFromCart(data.id, mContext).then(resJson => {
            if (resJson) {
                global.lead = resJson.data?.data;
                window.sessionStorage.setItem('lead', JSON.stringify(resJson?.data?.data))
                mContext.setMyCourses(resJson.data?.data[0]?.courses)
                setCourses(resJson.data?.data[0]?.courses)
                var dep = mContext?.depatmentsCoursesCounter;

                if (data?.departments && data.departments[0]) {
                    dep[data.departments[0].id]--;
                    mContext.setDepatmentsCoursesCounter({ ...dep })
                    window.sessionStorage.setItem('depatmentsCoursesCounter', JSON.stringify(dep))
                }

                mContext.setCallTimeRequired(doesLeadReqCallTime(resJson.data?.data[0]?.courses));
                window.sessionStorage.setItem('callTimeRequired', JSON.stringify(mContext.callTimeRequired));

                mContext.setLoading(false);
                setshowDeleteWarning(false);
            }
        })
    }

    const getSelectedBranchData = (branchId) => {
        let newBranch = null;

        for (let i = 0; i < data.course_branches.length; i++) {
            if (branchId == data.course_branches[i].id) {
                newBranch = data.course_branches[i];
                break;
            }
        }

        return newBranch;
    }

    const handleUpdatePersonalInfo = (data, setLoading) => {
        let coursesData = JSON.stringify([data]);
        setLoading(true)

        const chosenBranch = getSelectedBranchData(parseInt(data.branch));

        setSelectedBranch(chosenBranch);

        updateLadApi(coursesData, '', mContext).then(resJson => {
            if (resJson) {
                global.lead = resJson.data?.data;
                window.sessionStorage.setItem('lead', JSON.stringify(resJson?.data?.data))
                if (resJson.data?.data[0]?.courses) {
                    mContext.setMyCourses(resJson.data.data[0].courses)
                    setCourses(resJson.data.data[0].courses)
                    mContext.setCallTimeRequired(doesLeadReqCallTime(resJson.data?.data[0]?.courses));
                }
                setLoading(false)
            }
        })
    }

    const leadOverQuota = () => {
        console.log(global.user.max_courses)
        return index + 1 > global.user.max_courses;
    }

    const courseSettings = () => {
        let string = '<strong>הערות למסלול</strong><br/>';

        course.course.course_settings.map((settings, key) => {
            if (settings.label == 'ציון מבחן שפה') {
                return true;
            }
            const value = settings.value ? settings.value : 'לא';
            string += settings.label + ":" + value + "<br/>";
        });

        course.course.filter_types.map((settings, key) => {
            string += settings.name + ": כן" + "<br/>";
        });

        return string;
    }

    const filters = () => {
        const filtersArray = [];

        const filters = data?.filter_types?.map(filter => {
            filter.is_time_filter && filtersArray.push(filter.name);
        });

        return filtersArray.join(', ');
    }

    return (
        <MyContext.Consumer >
            {(context) => {
                mContext = context;
                return (
                    <div className='row cc shadow' >
                        <PersonalInfoPopup
                            item={course}
                            setLoading={context.setLoading}
                            editPopup={editPopup}
                            course={course}
                            setEditPopup={setEditPopup}
                            handleUpdate={handleUpdatePersonalInfo}
                        />
                        <span className='tableBodyColumn changePersonalInfoLead'>
                            <Btn text='שינוי פרטים אישיים'
                                style={{
                                    minHeight: '45px',
                                    backgroundColor: '#2B2D41',
                                    color: '#ffff'
                                }}
                                onClick={() => setEditPopup(true)}

                            />
                            <div className="tooltips">
                                {!isAllRequiredQuestionsAnswered([course]) && <Tooltip content={strings.Summery.Tooltip.has_questions} />}

                                {/* {leadOverQuota([course]) && <Tooltip type="error" content={strings.overQutaLeadQty} />} */}
                                <Tooltip type="info" content={courseSettings()} />
                                {course.course.notice ? <Tooltip type="institution-info" content={course.course.notice} /> : null}
                            </div>
                        </span>
                        <span className='tableBodyColumn'>
                            <IntitutionBranches
                                branches={data.course_branches}
                                branchChanged={branchChanged}
                                selectedBranch={selectedBranch}
                            />
                        </span>
                        <span className='tableBodyColumn filter_types'>{filters()}</span>
                        <span className='tableBodyColumn course_types'>{data?.course_types?.map(i => (i.name)) + ''}</span>
                        <span className='tableBodyColumn institution'>
                            {data?.institution?.title} ({data?.institution?.id}) ({strings.rank}: {data?.institution.rate_order})
                        </span>
                        <span className='tableBodyColumn departments'>
                            {data.title} ({data.id})
                        </span>

                        <div className='tableBodyColumn delete'>
                            <div
                                className="deleteCourseButton"
                                onClick={() => deleteCourseRequest()}>
                                <img src={require('../../assets/images/searchResult/delete@3x.png')} />
                            </div>
                        </div>
                        {showDeleteWarning ? (
                            <div className="deleteCourse">
                                <div className="deleteCourseButton">
                                    <img src={require('../../assets/images/searchResult/delete@3x.png')} />
                                </div>
                                <div className="delete-message">
                                    בחרת למחוק את הרשומה.
                                    <div>
                                        <strong>האם את/ה בטוח/ה?</strong>
                                    </div>
                                </div>
                                <div className="deleteButtons">
                                    <Btn text='כן, מחק רשומה'
                                        style={{
                                            minHeight: '45px',
                                            backgroundColor: '#2B2D41',
                                            color: '#ffff'
                                        }}
                                        onClick={() => deleteCorse()}
                                    />
                                    <Btn text='ביטול'
                                        className="cancel-delete"
                                        style={{
                                            minHeight: '45px',
                                            color: '#ffff'
                                        }}
                                        onClick={() => setshowDeleteWarning(false)}
                                    />
                                </div>
                            </div>
                        ) : ''}

                    </div>
                )
            }
            }
        </MyContext.Consumer>
    )
}//item

export default BasketCourseItem;
