import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { strings } from '../../strings';

const IntitutionBranches = (props) => {
    const { branches, branchChanged, selectedBranch } = props;

    let orderedBranches = branches.map((branch) => {
        branch.address.distance = parseFloat(branch.address.distance);

        return branch;
    });

    orderedBranches = _.orderBy(branches, ['address.distance'], 'asc');

    const [selectedComponentBranch, setSelectedBranch] = useState(selectedBranch);

    const branchSelectionChanged = (e) => {
        const newSelectedBranchId = e.target.value;
        var newSelectedBranch = null;

        branches.map((branch) => {
            if (branch.id === parseInt(newSelectedBranchId)) {
                newSelectedBranch = branch;
            }
        });

        setSelectedBranch(newSelectedBranch);

        branchChanged(newSelectedBranch);
    }

    useEffect(() => {
        setSelectedBranch(selectedBranch);
    }, []);

    return branches.length ? (
        <div className="branchs-warpper">
            {branches.length > 1 ?
                <select
                    name="course-branches"
                    className="general"
                    value={selectedComponentBranch?.id ? selectedComponentBranch.id : ''}
                    onChange={branchSelectionChanged}>
                    {orderedBranches.map((branch, key) => {
                        let distanceString;

                        if (branch.address.distance > 2000) {
                            distanceString = '(מרחק לא ידוע)';
                        } else {
                            distanceString = branch.address.distance ? '(' + branch.address.distance + strings.km + ')' : '';
                        }


                        return (
                            <option
                                key={key}
                                value={branch.id}>
                                {branch.title} {distanceString}

                            </option>
                        )
                    }

                    )}
                </select>
                : (
                    <span className="singleBranch">
                        {
                            branches.map((branch, key) => {
                                let distanceString;

                                if (branch.address.distance > 2000) {
                                    distanceString = '(מרחק לא ידוע)';
                                } else {
                                    distanceString = branch.address.distance ? '(' + branch.address.distance + strings.km + ')' : '';
                                }

                                return (
                                    <span
                                        key={key}
                                        value={branch.id}>
                                        {branch.address.address}
                                        {distanceString ? (
                                            <>
                                                {distanceString}
                                            </>
                                        ) : ''}
                                    </span>
                                )
                            })
                        }
                    </span>
                )}

        </div>
    ) : null;
}

export default IntitutionBranches;