

var lead = window.sessionStorage.getItem('lead')

var global = {
    domin: process.env.REACT_APP_API_URL,
    IDString: null,
    googlePlacesAPI_KEY: process.env.REACT_APP_GOOGLE_PLACES_API,
    watchingData: {},
    lead: lead && lead != 'null' ? JSON.parse(lead) : [],
    upSales: {},
    replaceHTMLreg: /<[^>]*>?/gm,
    user: JSON.parse(window.sessionStorage.getItem('auth'))?.user,
    distanceFilter: 0,
    fakeCity: null,
    LeadTimer: 0
}

export default global;