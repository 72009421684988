import React, { useEffect, useState } from 'react';
import global from '../../res/global';

const SummaryCount = (props) => {

    const { context } = props;

    const [summaryCount, setSummaryCount] = useState();

    useEffect(() => {
        updateSummaryCount();
    }, []);

    useEffect(() => {
        updateSummaryCount();
    }, [global?.lead[0]?.courses]);

    const updateSummaryCount = () => {
        const summaryCount = global?.lead?.length ? global.lead[0]?.courses?.length : 0;

        setSummaryCount(summaryCount);
    }

    return (
        <div className='header-left'
            onClick={() => context.setValidateEmail(!context.validateEmail)}>
            <span className='summryCount'>{summaryCount}</span>
            <img className='paths-image' src={require('../../assets/images/header/paths.png')} alt="paths" />
            <br />
            <span className='left-header-text'>{'לצפייה במסלולים'}</span>
        </div>
    )
}

export default SummaryCount;