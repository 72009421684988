import global from "./global";

const getLead = async (crmCode) => {
    var url = global.domin + '!get_lead' + (crmCode ? ('?crm_code=' + crmCode+'&') : '?')+'jwtauth='+global?.user?.token;
    

    const response = await makeApiCall(url, 'POST');
   
    return response;
}

const sendLeadApi = async () => {
    let url = global.domin + '!send_lead?id=' + global.lead[0].id + '&user_id=' + global?.user?.user_id+'&jwtauth='+global?.user?.token;

    const response = await makeApiCall(url, 'POST');

    return response;
}

const deleteLeadDraft = async () => {
    let url = global.domin + '!delete_lead?id=' + global.lead[0].id + '&user_id=' + global?.user?.user_id+'&jwtauth='+global?.user?.token;

    const response = await makeApiCall(url, 'POST');

    return response;
}

const verifyLeadApi = async () => {
    let url = global.domin + '!verify_lead?id=' + global.lead[0].id + '&user_id=' + global?.user?.user_id+'&jwtauth='+global?.user?.token;

    const response = await makeApiCall(url, 'POST');

    return response;
}

const getDepartmentsApi = async () => {
    const url = global.domin + '!get_departments';//+'?jwtauth='+global?.user?.token;

    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    return response;
}

const getOpenSearchList = async () => {

    const url = global.domin + '!get_opensearch_autocomplete'+'?jwtauth='+global?.user?.token;

    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    return response;
}

const updateBasketCourseApi = async (coursesData, str, context) => {
    let url = global.domin + '!update_basket_course?id=' + global.lead[0].id + '&user_id=' + global?.user?.user_id+'&jwtauth='+global?.user?.token;

    if (coursesData) {
        url += '&courses=' + JSON.stringify(coursesData);
    }

    if (str) {
        url += '&' + str;
    }

    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    if (response.data.data && context) {
        if (response.data?.data) {
            context.setLead(response.data.data);
            global.lead = response.data.data;
        }

    }

    return response;
}

const updateLadApi = async (coursesData, str, context) => {
    let url = global.domin + '!update_lead?id=' + global.lead[0].id + '&user_id=' + global?.user?.user_id+'&jwtauth='+global?.user?.token;

    if (coursesData) {
        url += '&courses=' + coursesData;
    }

    if (str) {
        url += '&' + str;
    }

    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    if (response.data.data && context) {
        context.setLead(response.data?.data);
    }

    return response;
}

const deleteFromCart = async (courseId, context) => {
    const courseToDelete = JSON.stringify([{
        id: courseId,
        delete: true
    }])

    let url = global.domin + '!update_lead?id=' + global.lead[0].id + '&courses=' + courseToDelete + '&user_id=' + global?.user?.user_id+'&jwtauth='+global?.user?.token;

    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    if (response.data.data && context) {
        context.setLead(response.data?.data);
    }

    return response;

}
const addToCartApi = async (courses) => {
    let url = global.domin + '!update_lead?id=' + global.lead[0].id + '&courses=' + courses + '&user_id=' + global?.user?.user_id+'&jwtauth='+global?.user?.token;

    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    return response;
}

const openSearchCourses = async (searchQuery) => {
    let url = `${global.domin}!get_courses?strict_prams`+'&jwtauth='+global?.user?.token;

    if (searchQuery) {
        url += '&s=' + searchQuery;
    }

    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    return response;
}

const authenticate = async (username, pass) => {
    const url = global.domin.replace('services/api','aam/v1/') + 'authenticate?username=' + username + '&password=' + pass;

    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    return response;
}

const get_user_data= async (userid,token) => {
    const url = global.domin + '!get_user_data?user_id=' + userid +'&jwtauth='+token;

    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    return response;
}

const searchCourses = async (leadId, dep, corseTypes, otherFilters, location, type) => {
    let url = `${global.domin}!get_courses?strict_prams`+'&jwtauth='+global?.user?.token;

    if (leadId) {
        url += '&check_lead_info=' + leadId;
    }

    if (dep) {
        url += '&departments=' + dep;
    }

    if (corseTypes.length) {
        url += '&course_type=' + corseTypes;
    }

    if (otherFilters.length) {
        url += '&filter_type=' + otherFilters;
    }

    if (location.length) {
        url += '&location=' + location;
    }

    url += '&distance=' + global.distanceFilter;

    if (global.fakeCity) {
        url += '&fake_city=' + global.fakeCity;
    }

    if (type) {
        url += '&type=' + type;
    }


    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    return response;
}

const setManagerApproval = async (managerCode) => {
    const url = global.domin + '!managerOverQuoteApproval?id=' + global.lead[0].id + '&manager-approval=' + managerCode+'&jwtauth='+global?.user?.token;

    const response = await makeApiCall(url, 'POST').then(res => (res.json()));

    return response;
}

const makeApiCall = async (url, method, params) => {
   
    return await fetch(url, {
        method: method,
    });

    //check invalid session
}


export { getOpenSearchList, deleteLeadDraft, authenticate,get_user_data, updateBasketCourseApi, searchCourses, openSearchCourses, addToCartApi, deleteFromCart, updateLadApi, sendLeadApi, getDepartmentsApi, verifyLeadApi, getLead, setManagerApproval }