import React, { useEffect, useState } from 'react'
import global from '../../res/global'
import * as _ from 'underscore'
import { Icon } from '@iconify/react';
import closeOutlined from '@iconify-icons/ant-design/close-outlined';
import { isInstitutionIncluded } from '../../generalUtill'
import 'react-toastify/dist/ReactToastify.css';
import UpSalesItem from '../upsaleItem/upsaleItem';


const UpSalesModal = (props) => {

    const [upsales, setUpsales] = useState(null);

    const {
        maxAddToBasketReached,
        courses,
        mContext,
        updateLead,
        setmodalVisible,
        updateLeadfunc
    } = props;

    const maxReached = maxAddToBasketReached();
    
    const renderCorses = () => {

        return upsales ? upsales.map((course, key) => {
            return (
                <UpSalesItem
                    key={key}
                    updateLead={(courseId,qanswers, sqObj , fromUpsale) => {
                        mContext.setLoading(true);
                        updateLead(courseId,qanswers, sqObj , fromUpsale);
                    }}
                    updateLeadfunc={() => {
                        setUpsales(null);
                        updateLeadfunc();
                    }}
                    course={course}
                    disabled={maxReached || isInstitutionIncluded(courses, course.institution.id)} />
            );
        }) : null;
    }

    useEffect(() => {
        setUpsales(global.upSales.courses);
    }, [global.upSales.courses]);

    return (
        <div className='modal qusetion-wrapper'>
            <div className='Q'>

                <div className='Qheader'>
                    <span style={{ fontSize: '33px', marginLeft: '30px', cursor: 'pointer' }} onClick={() => { setmodalVisible(false); }} ><Icon icon={closeOutlined} /></span>
                    <span className='headerTitle'>{'Upsales'}</span>
                </div>

                <div className='upSaleCourses'>
                    {renderCorses()}
                </div>
            </div>

        </div>
    )
}

export default UpSalesModal;