import React from 'react';
import './sliderItem.css';
import '../../components/Modal/modal.css'


const Item = (props) => {
	const { content, title } = props.data

	const counter = props.indexNum + 1;

	return (
		<div>
			<div className='container-sliderItem'>
				<span className='item-text'>{`${counter} - ${title}`}</span>
				<div className='readMeContainer'>
					<span className='readMore' onClick={() => props.modal({ modalVisible: true, title: title, content: content })}>{'< קרא הכל'}</span>
				</div>
			</div>
		</div>
	)
}


export default Item;