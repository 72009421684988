import React from 'react';
import './actionHistory.css';
import global from '../../res/global'

const actionHistory = (props) => {

	var { history } = global.lead[0]

	const renderHistory = () => {

		return history.map((i, key) => {
			return (<tr key={key}>
				<td><span>{i.action}        </span></td>
				<td><span>{i.agent}         </span></td>
				<td><span>{i.institutions}  </span></td>
				<td><span>{i.courses}       </span></td>
				<td><span>{i.date}          </span></td>
			</tr>)
		})
	}

	return (
		<div className='actionHistory-container'>

			<div className='innerContainer'>
				<h3 style={{ textAlign: 'right', marginRight: '23px' }}>{'היסטוריית פעולות'}</h3>

				<table>
					<thead>
						<tr>
							<th><span>{'פעולה'}     </span></th>
							<th><span>{'שם נציג'}   </span></th>
							<th><span>{'מוסד לימוד'}</span></th>
							<th><span>{'שם מסלול'}  </span></th>
							<th><span>{'תאריך'}     </span></th>
						</tr>
					</thead>
					<tbody>
						{renderHistory()}
					</tbody>

				</table>
			</div>

		</div>
	)
}


export default actionHistory;