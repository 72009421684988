import React, { useEffect } from 'react'
import Interweave from 'interweave'
import { strings } from '../../strings'
import MyContext from '../../store/MyContext'
import { InlineIcon } from '@iconify/react';
import logoutOutlined from '@iconify-icons/ant-design/logout-outlined';
import { useIdleTimer } from 'react-idle-timer'
import { handlUserFragments, fetchUserFragments, logOut, getAuth } from '../../generalUtill'
import './memberinfo.css'
import notificationOutlined from '@iconify-icons/ant-design/notification-outlined';
import { useNavigate } from 'react-router-dom';


export default function MemberInfo() {
	const { MemberInfo: string } = strings;
	const navigate = useNavigate();
	let mcontext = null;

	let welcomText = string.welcomText.replace('%s', string.guestName);
	let leadCount = 0;

	const handleOnIdle = event => {
		handleLogout(true);
	}

	useIdleTimer({
		timeout: 1000 * 60 * 30,//30 minutes
		onIdle: handleOnIdle,
		debounce: 500
	});

	const handleLogout = (isAuto) => {
		let logout = isAuto ? true : false;
		if (!isAuto && window.confirm('האם אתה בטוח  שברצונך לצאת מהמערכת ?')) {
			logout = true;
		}

		if (logout) {
			mcontext.setAuth(false);
			mcontext.setUser(false);
			mcontext.setLead(false);
			mcontext.setSummery(false);
			navigate('/');
			logOut();
		}
	}

	const handleFetchUserFragments = () => {
		const userData = getAuth()?.user;
		if (userData) {
			fetchUserFragments(userData)
				.then(res => res.json())
				.then(resJson => {
					handlUserFragments(resJson.data.data, mcontext);
				});
		}
	}

	useEffect(() => {
		handleFetchUserFragments();
		const interval = setInterval(() => {
			handleFetchUserFragments();
		}, 300000) // 5 min


		return () => clearInterval(interval)
	}, [])

	return (
		<MyContext.Consumer>
			{(context) => {
				mcontext = context;
				if (mcontext.auth && mcontext.user) {
					welcomText = string.welcomText.replace('%s', "<strong>" + mcontext.user.first_name + ' ' + mcontext.user.last_name + '</strong>');
					leadCount = mcontext.user.leads_count || leadCount;
				}
				return (
					<div className="member-info">
						{mcontext.auth && mcontext.user && mcontext.notificationReciverState.message &&
							<button className="btn main-notification-popup-trigger" onClick={() => mcontext.setNotificationReciverState(current => { return { ...current, active: true } })}>
								<span>{string.pushNotification}</span>
								<InlineIcon icon={notificationOutlined} />
							</button>
						}
						<div className="inner-info">
							<Interweave content={welcomText} />
							{mcontext.user &&
								<div className="couner-wrapper">
									<div className="label">{string.counterLabel}</div>
									<div className="count">{leadCount}</div>
								</div>
							}
						</div>
						{mcontext.user &&
							<button
								onClick={() => handleLogout()}
							><InlineIcon icon={logoutOutlined} />{strings.logout}</button>
						}
					</div>
				)
			}}
		</MyContext.Consumer>
	)
}
