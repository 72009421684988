import React, { useState, useEffect } from 'react'
import Interweave from 'interweave'
import { strings } from '../../strings'
import global from '../../res/global'

const StickyNotification = React.memo(() => {

	const [notification, setSotification] = useState('');

	useEffect(() => {
		fetch(global.domin + '!get_sticky_notification', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((res) => (res.json())).then((resJson) => {
			if (resJson) {
				setSotification(resJson.data.data);
			}
		})
	},[])

	return (
		<div className="StickyNotification">
			<span>{strings.StickyNotification}</span>
			<div><Interweave noWrap={true} content={notification} /></div>
		</div>
	)
});

export default StickyNotification