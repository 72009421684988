import React, { useState, useRef, useEffect } from 'react'
import * as _ from 'underscore'

import './serach.css'
import Btn from '../../components/Btn/btn'
import PartnerItem from '../../components/partnerItem/partnerItem'
import SearchResult from './searchResult'
import Wathch from '../Watching/watch'
import global from '../../res/global'
import MyContext from '../../store/MyContext'
import Loader from '../../components/loader/Loader'
import CheckBox from '../../components/checkBox/checkBox'

import { prepareResultItems } from './searchUtills'
import { searchCourses, getDepartmentsApi, openSearchCourses } from '../../res/api';
import { strings } from '../../strings'
import { $ } from 'dom7'

const Search = (props) => {
	const [result, setResult] = useState(true);
	const [whatching, setWatching] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [searchResutList, setSearchResutList] = useState(false)
	const [autoComplete, setAutoComplete] = useState(null)
	const [searches, setSearches] = useState(null)
	const [selectedDepartments, setselectedDepartments] = useState([])
	const [courses, setCourses] = useState(null)
	
	const [loader, setLoader] = useState(false)
	const [searchField, setSearchfieldRef] = useState(false)
	const [openSearch, setOpenSearch] = useState(false);
	const lead = global?.lead[0];
	const { updateLead } = props;

	const ref = useRef(null)
	const wrapperRef = useRef(null);

	var top = (62 + ref?.current?.offsetTop + 'px');
	var left = (ref?.current?.offsetLeft + 'px')
	var width = ref?.current?.clientWidth + 'px'
	var mcontext = null;


	useEffect(() => {
		
		getDepartments();
	}, []);

	/** watch sidebar filters change */
	useEffect(() => {
		searches && searches.length && selectedDepartments.length && getCourses();
	}, [global.courseTypeFilters, global.otherFilters, global.distanceFilter, global.fakeCity]);


	useEffect(() => {
		/**wrapperRef
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setSearchResutList(false)
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);


	const search = async (searchTerm) => {
		var arr = selectedDepartments;
		var resultArr = null;

		setSearchValue(searchTerm);

		if (!searchTerm.length) {
			setSearchResutList(false);
			return false;
		}

		const autoCompleteList = openSearch ? mcontext.autoCompleteData : searches;

		resultArr = autoCompleteList?.filter(courseTerm => {
			const name = courseTerm.name.toLowerCase();
			const parent_name = courseTerm.parent_name.toLowerCase();
			
			searchTerm = searchTerm.toLowerCase();

			return searchTerm && (name.includes(searchTerm)) || String(courseTerm.crm_id).includes(searchTerm) || String(courseTerm.tags).includes(searchTerm) || parent_name.includes(searchTerm);
		}).map((courseTerm, key) => {
				if (_.findLastIndex(arr, { id: courseTerm.id }) > -1) {
					return '';
				}
				
				const resultTitle = courseTerm.parent_name ? ` ${courseTerm.parent_name} [${courseTerm.parent_id}] -> ${courseTerm.name} [${courseTerm.id}]` : ` ${courseTerm.name} [${courseTerm.id}]`

				return (
					<span
						className='searchResutList-text'
						key={key}
						onClick={(event) => {
								!arr.includes(courseTerm) && arr.push(courseTerm);
								setselectedDepartments(arr);
								//$('#resitm_'+key).css('display','none');
								$(event.target).css('display','none');
						}}
						>
						{resultTitle}
					</span>
				)
			});

		setSearchResutList(resultArr?.length > 0)

		setAutoComplete(resultArr)

	}

	const getDepartments = () => {

		setLoader(true)

		getDepartmentsApi().then(resJson => {
			if (resJson) {
				var arr = resJson.data.data.map((department) => {
					return {
						name: department.name,
						id: department.id,
						crm_id: department.crm_id,
						tags: department.tags,
						parent_name: department.parent_name,
						parent_id: department.parent,
						rate_order: department.rate_order
					}
				});

				arr = arr = _.sortBy(arr, (o) => {
					return -o.rate_order 
				});


				setSearches(arr);
			}
			setLoader(false)
		})
	}

	const getCourses = async () => {

		setLoader(true)

		setCourses({});

		var dep = selectedDepartments.map(i => i.id + '');

		if (dep.length === 0) {
			dep = null;
		} else {
			dep = dep.toString()
		}

		try {
			const leadId = global?.lead[0]?.id ? global?.lead[0]?.id : null;

			var dictInstituteDepartment = {};
			var lastInstituteOrder = 0;
			const type = openSearch ? 'opensearch' : '';

			const searchFunction = searchCourses(leadId, dep, mcontext?.corseTypes, mcontext?.otherFilters, mcontext?.location, type);

			searchFunction.then(resJson => {
				setLoader(false);

				if (resJson) {
					var arr = [];

					resJson.data.data.filter(course => {
						let disableLocationFilter = false;

						props.idsToDisableLocationFilter.forEach(term_id => {
							if (mcontext.otherFilters.includes(term_id)) {
								disableLocationFilter = true;
								return;
							}
						});

						return true
					}).forEach(course => {
						if (course.departments) {
							let courseX = { ...course }
							let sameCourseCounter = 0;
							
							course.departments.map((department) => {
								if (openSearch) {
									courseX.department = department;
									if (sameCourseCounter >= 1) {
										courseX.listedOnMoreThanOneDepartment = true;
									}
									let InstituteDepartmentKeyID = department.id.toString() +'$' + courseX.institution.id.toString();
									courseX.firstInInstituteGroupBy =(dictInstituteDepartment.hasOwnProperty(InstituteDepartmentKeyID)!=true);
									if(courseX.firstInInstituteGroupBy){dictInstituteDepartment[InstituteDepartmentKeyID] = true;}
									courseX.InstitutionOrder =1;
									arr.push({ ...courseX });
									sameCourseCounter++;
								} else {
									selectedDepartments.map((selectedDepartment) => {
										let depID = department.parent?department.parent:department.id;
										if (selectedDepartment.id === department.id ) {
											courseX.department = department;
											if (sameCourseCounter >= 1) {
												courseX.listedOnMoreThanOneDepartment = true;
											}
											let InstituteDepartmentKeyID = department.id.toString() +'$' + courseX.institution.id.toString();
											courseX.InstituteDepartmentKeyID = InstituteDepartmentKeyID;
											courseX.firstInInstituteGroupBy =(dictInstituteDepartment.hasOwnProperty(InstituteDepartmentKeyID)!=true);
											
											//courseX.moreData = courseX.firstInInstituteGroupBy;
											if(courseX.firstInInstituteGroupBy){dictInstituteDepartment[InstituteDepartmentKeyID] = lastInstituteOrder;lastInstituteOrder++;}
											courseX.InstitutionOrder = dictInstituteDepartment[InstituteDepartmentKeyID];
											arr.push({ ...courseX });
											sameCourseCounter++;
										}
										
									});
								}
							});
						}
					})

					
					const groups = _.groupBy(arr, (i) => {
						return (i.department?.id.toString() || '');
					  });
					  
					  const sortedGroups = _.sortBy(
						_.map(groups, (group1, key) => {
						  return {
							group: _.sortBy(group1, (i) => i.InstitutionOrder||''),
							sortingProperty: _.sortBy(group1, (i) => i.department.rate_order)[0].department.rate_order
						  };
						}),
						(group1) => -group1.sortingProperty
					  );
					 
					 
					 arr =_.map(sortedGroups, (group1) => group1.group);
				
					/*arr = _.sortBy(arr, (o) => {
						return o.InstitutionOrder || ''
					});*/
					
					/*arr = _.groupBy(arr, (i) => {
						return i.department?.id || ''
					});*/

					
					/*arr = _.map(arr, (group) => {
						return _.sortBy(group, (item) => {
						  return item.department.parent_name;
						});
					  });*/
					  
					 /*arr = _.sortBy(arr, (group) => {
						return group[0].InstitutionOrder || '';
					  });*/

					//arr = Object.entries(arr).sort(en=>en[1][0].department.parent_name);
					
					//arr=sortOnKeys(arr);
				
					prepareResultItems(arr, mcontext).then((results) => {
						setCourses({ ...results });
						if (results && Object.keys(results).length > 0) {
							props.setHasResult(true);
						} else {
							props.setHasResult(false);
						}
					});
				}
			});

		} catch (e) {
			// mcontext && mcontext.setLoading(false)
			console.log('error: ' + e)
		}
	}

	const renderDepartments = () => {
		var arr = selectedDepartments.map((selectedDepartment, key) => {

			let title = selectedDepartment.parent_id ? `${selectedDepartment.parent_name} [${selectedDepartment.parent_id}] > ${selectedDepartment.name} [${selectedDepartment.id}]` : `${selectedDepartment.name} [${selectedDepartment.id}]`;

			return (<PartnerItem
				text={title}
				id={selectedDepartment.id}
				style={{ 'marginBottom': '32px' }}
				disabled={true}
				key={key}
				onCancel={id => {
					var arr = selectedDepartments;

					arr = arr.filter(filteredSelectedDepartment => {
						return filteredSelectedDepartment.id !== id;
					})
					setselectedDepartments(arr)
				}} />)
		})

		return arr;
	}

	const toggleOpenSearch = (value) => {
		setOpenSearch(!openSearch);
		setselectedDepartments([]);
	}

	return (
		<MyContext.Consumer>
			{(context) => {
				mcontext = context;
				return (
					<div >
						{loader ? <Loader /> : null}
						<div className='Search-Container search-anchor'>
							<span className='title'>{''}</span>
							<div className='search-box' >
								<div className="button-wrapper">
									<Btn text='חיפוש'
										style={{ backgroundColor: '#2B2D41', color: '#ffff' }}
										disabled={!selectedDepartments.length ? true : false}
										onClick={() => {
											getCourses();
										}}
									/>
									{selectedDepartments.length > 0 &&
										<Btn text='איפוס חיפוש' className="reset-button" style={{ backgroundColor: 'transparent', color: '#2B2D41' }} onClick={() => setselectedDepartments([])} />
									}
								</div>
								<div className='search-input' ref={ref} >
									<input
										className='input'
										type='text'
										placeholder={openSearch?'חיפוש מוסד/מסלול': 'חיפוש תחום'}
										ref={(input) => {
											setSearchfieldRef(input);
										}}
										value={searchValue}
										onFocus={(event)=>{event.target.value='';}}
										onChange={(val) => search(val.target.value)}
									/>
									<img style={{ width: '27px', height: '27px', marginRight: '18px' }} src={require('../../assets/images/search/searchIcone.png')} />
								</div>
							</div>
							<div className="checkbox-wrap freesearch">
								<CheckBox
									text={'חיפוש מוסד/מסלול'}
									id={'open-search'}
									onChange={toggleOpenSearch}
									checked={openSearch}
								/>
							</div>
							{searchResutList ?
								<div className='searchResutList shadow' ref={wrapperRef} style={{ top: top, left: left, width: width }}>
									{autoComplete} 
								</div>
								: null}

							<div className='subjects'>
								{renderDepartments()}
							</div>
							<div className="notifications">
								<ul>
									{lead.personal_info.question_has_pet ? '' : <li>ללא פסיכומטרי</li>}
									{lead.personal_info.gpa_type === 'full' || lead.personal_info.gpa_type === 'semi' ? '' : <li>ללא בגרויות</li>}
									{lead.personal_info.question_want_pet ? <li>מעוניין בשיפור פסיכומטרי</li> : ''}
									{lead.personal_info.question_want_dorms ? <li>מעוניין במעונות</li> : ''}
									{lead.personal_info.question_improvement_gpa ? <li>מעוניין בשיפור בגרויות</li> : ''}
									{lead.personal_info.question_want_english_improvment ? <li>מעוניין בשיפור אנגלית</li> : ''}
								</ul>
							</div>
						</div>
						{result ?
							<SearchResult
								updateLead={(val) => {
									updateLead && updateLead(val)
								}}
								items={courses}
								ResultOrderBy={props.ResultOrderBy}
								store={mcontext}
								openSearch={openSearch}
								watching={(watching) => {
									setWatching(watching);
									setResult(!watching)
								}} />
							: null}
						{(whatching || mcontext.watching) && global.watchingData ?
							<Wathch
								watching={(watching) => {
									setWatching(watching); setResult(!watching)
								}}
								location={props.location}
								updateLead={(val) => {
									updateLead && updateLead(val)
								}}
							/>

							: null}
					</div>
				)
			}}
		</MyContext.Consumer>
	)
}

export default Search;