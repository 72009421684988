import React from 'react';
import { Icon } from '@iconify/react';
import closeCircleFilled from '@iconify-icons/ant-design/close-circle-filled';
import './popup.css';

export default function Popup(props) {
	const { show, title, message, date, handleClose, height, width, disableRegularClose, className, allowOverflow } = props;
	let customStyle = {};
	if (height) {
		customStyle.height = height;
	}
	if (width) {
		customStyle.maxWidth = width;
	}
	const handlePopupClose = () => {
		if (!disableRegularClose) {
			handleClose();
		}
	}
	return (
		<>
			{show &&
				<div className={`popup-style ${className}`}>
					<div className="inner-popup" style={customStyle}>
						{!disableRegularClose &&
							<button className="btn-close" onClick={() => handlePopupClose()}>
								<Icon icon={closeCircleFilled} />
							</button>
						}
						{title &&
							<div className="popup-entry-title">
								<h3>{title}</h3>
							</div>
						}
						<div className={`popup-entry-content${allowOverflow ? ' allow-overflow' : ''}`}>
							{date && <div className="date-wrapper"><span>{date}</span></div>}
							{message}
						</div>
					</div>
				</div >
			}
		</>
	)
}
