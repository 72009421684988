import React from 'react'
import './btn.css'


const Btn = (props) => {
    const { className, onClick, style, disabled, text, type } = props

    const buttonType = type ? type : 'button';

    return (
        <button type={buttonType} className={className ? ('btn ' + className) : 'btn'} onClick={onClick} style={style} disabled={disabled}>{text}</button>
    )
}

export default Btn