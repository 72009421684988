import React, { useState } from 'react'
import MyContext from './MyContext'
import global from '../../src/res/global'
import { getAuth } from '../generalUtill'

const MyProvider = (props) => {
	const [loading, setLoading] = useState(false)
	const [summery, setSummery] = useState(false)
	const [validateEmail, setValidateEmail] = useState(false)
	const [corseTypes, setCorseTypes] = useState([])
	const [otherFilters, setOtherFilters] = useState([])
	const [location, setLocation] = useState('')
	const [myCourses, setMyCourses] = useState([])
	const [watching, setWatching] = useState(false)
	const [lead, setLead] = useState(JSON.parse(window.sessionStorage.getItem('lead')))
	const [callTimeRequired,setCallTimeRequired] = useState(false);
	const [user, setUser] = useState(getAuth()?.user)
	const [depArr, setDepArr] = useState([])
	const [watchingData, setWatchingData] = useState(null)
	const [questions, setQuestions] = useState([])
	const [answers, setAnswers] = useState([])
	const [loginPopup, setLoginPopup] = useState(false);
	const [notificationReciverState, setNotificationReciverState] = useState({ active: false, message: '', date: '' });
	const [notificationFirstLoad, setNotificationFirstLoad] = useState(true);
	const [auth, setAuth] = useState(getAuth()?.auth)
	const [depatmentsCoursesCounter, setDepatmentsCoursesCounter] = useState(JSON.parse(localStorage.getItem('depatmentsCoursesCounter')) || {});
	const [notRelevantCourses, setNotRelevantCourses] = useState([]);
	const [fakeCity, setFakeCity] = useState();
	const [autoCompleteData, setAutocompleteData] = useState([]);
	const [managerCode, setManagerCode] = useState(false);

	return (
		<MyContext.Provider value={{
			autoCompleteData: autoCompleteData,
			setAutocompleteData: (val) => { setAutocompleteData(val) },
			loading: loading,
			managerCode: managerCode,
			setManagerCode: (val) => { setManagerCode(val) },
			setLoading: (val) => { setLoading(val) },
			lead: lead,
			setLead: (val) => { setLead(val) },
			callTimeRequired: callTimeRequired,
			setCallTimeRequired: (val) => { setCallTimeRequired(val) },
			summery: summery,
			setSummery: (val) => setSummery(val),
			validateEmail: validateEmail,
			setValidateEmail:(val) => setValidateEmail(val),
			corseTypes: corseTypes,
			setCorseTypes: (val) => setCorseTypes(val),
			otherFilters: otherFilters,
			setOtherFilters: (val) => setOtherFilters(val),
			location: location,
			setLocation: (val) => setLocation(val),
			myCourses: myCourses,
			setMyCourses: (val) => setMyCourses(val),
			watching: watching,
			setWatching: (val) => setWatching(val),
			user: user,
			setUser: (val) => { setUser(val); },
			setWatchingData: (val) => { setWatchingData(val); global.watching = val },
			watchingData: watchingData,
			setDepArr: (val) => setDepArr(val),
			depArr: depArr,
			setQuestions: (val) => setQuestions(val),
			questions: questions,
			setLoginPopup: (val) => setLoginPopup(val),
			loginPopup: loginPopup,
			setNotificationReciverState: (val) => setNotificationReciverState(val),
			notificationReciverState: notificationReciverState,
			setNotificationFirstLoad: (val) => setNotificationFirstLoad(val),
			notificationFirstLoad: notificationFirstLoad,
			setAnswers: (val) => setAnswers(val),
			answers: answers,
			auth: auth,
			setAuth: (val) => setAuth(val),
			depatmentsCoursesCounter: depatmentsCoursesCounter,
			setDepatmentsCoursesCounter: (val) => setDepatmentsCoursesCounter(val),
			notRelevantCourses: notRelevantCourses,
			setNotRelevantCourses: (val) => setNotRelevantCourses(val),
			fakeCity: fakeCity,
			setFakeCity: (val) => setFakeCity(val)
		}}>
			{props.children}
		</MyContext.Provider>
	)
}

export default MyProvider;
