import React, { useState, useEffect, useRef } from 'react'
import './watch.css'
import Btn from '../Btn/btn'
import global from '../../res/global'
import GoogleMapReact from 'google-map-react';
import MyContext from '../../store/MyContext';
import Modal from '../../components/Modal/modal';
import { strings } from '../../strings'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { images } from '../../assets/images';

var arrTimes = []
var timesBackUp = []

const CB = (props) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginLeft: '15px' }}>
			<p className='cbtext'>{props.text}</p>
			<input type='checkbox' disabled={true} value={'mmmm'} style={{ position: 'unset', }} onChange={(e) => props.onChange && props.onChange(e.target.checked)} />
		</div>

	)
}

const Wactch = (props) => {

	var location = null
	var data = global.watchingData;
	var address = data?.institution?.address
	var mcontext = null;

	const [distance, setDistance] = useState(0);
	const [modalVisible, setModalVisible] = useState(false)
	const [popup, setPopup] = useState(false);
	const [error, setError] = useState('');
	const myref = useRef(null)

	useEffect(() => {
		scrolltoMe();
	}, [])

	const getDistant = () => {
		var loc1 = address?.lat + ',' + address?.lng;
		var loc2 = location?.address?.lat + ',' + location?.address?.long;
		var proxyUrl = 'https://cors.bridged.cc/';//'https://cors-anywhere.herokuapp.com/';

		var url = 'https://maps.googleapis.com/maps/api/distancematrix/json?origins=' + loc1 + '&destinations=' + loc2 + '&key=' + global.googlePlacesAPI_KEY + '&language=he'

		location?.address?.lat && location?.address?.long &&
			fetch(proxyUrl + url)
				.then(res => res.json())
				.then(resJson => {
					console.log('dis ', resJson)
					setDistance(parseInt(resJson?.rows[0]?.elements[0]?.distance?.value / 1000))
				}).catch(err => console.log(err));
	}

	const Location = (props) => {
		getDistant();
		return (
			<div className='location'>
				<p className='text2'>{distance + ' ק״מ '}</p>
				<p className='text2' style={{ fontWeight: 'bold', marginRight: '10px' }}>{' מרחק מהיעד: '}</p>
				<img src={images.location} alt="location"/>
			</div>
		)
	}

	function renderQ() {
		var dataArr = data?.institution?.multiple_content
		var arr = [];
		for (var i = 0; i < dataArr?.length; i += 2) {
			arr.push(
				<div className='Q1' style={{ paddingTop: '31px' }} >

					<div className='Q2L'>
						<p className={'text3'}>{dataArr[i + 1]?.title?.replace(/<\/?[^>]+(>|$)/g, "")}</p>
						<p className={'text4'}>{dataArr[i + 1]?.content?.replace(/<\/?[^>]+(>|$)/g, "")}</p>
					</div>

					<div className='Q2R'>
						<p className={'text3'}>{dataArr[i]?.title?.replace(/<\/?[^>]+(>|$)/g, "")}</p>
						<p className={'text4'}>{dataArr[i]?.content?.replace(/<\/?[^>]+(>|$)/g, "")}</p>
					</div>

				</div>
			)
		}//for

		return arr;
	}

	function renderTimes() {
		console.log('renderTimes', timesBackUp)
		arrTimes = [];
		return data?.activity_time?.map(i => <CB text={i.label} onChange={(val) => {
			if (val) {
				arrTimes.push(i.value)
			}
		}} />)
	}

	const Marker = (props) => {
		const greatPlaceStyle = {
			position: 'absolute',
			transform: 'translate(-50%, -50%)',
			backgroundColor: '#ffff',
		}
		return (
			<dev style={greatPlaceStyle}>
				{data?.institution?.title}
			</dev>
		)
	}
	const doesLeadReqCallTime = (courses) => {
		let res= false;
		courses.forEach(course_item => {
			if (course_item.course.institution.requirecalltime) {
				res = true; return res;
			}
		});
	
		return res;
	}

	const updateLaed = (q, times) => {
		if (global.lead[0].courses.length >= global.user?.max_add_to_cart) {
			let toastOption = {
				position: "top-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
			};
			toast.success(strings.CourseCard.cart_is_full, toastOption);
			return;
		}

		console.log('mcontext?.depatmentsCoursesCounter[data.id] ' + mcontext?.depatmentsCoursesCounter[data.department[0].id] +
			' data.department[0].max_leads_to_send ' + data.department[0].max_leads_to_send);

		if (data.department[0].max_leads_to_send !== null) {
			debugger;
			var depatmentsCoursesCounter = mcontext?.depatmentsCoursesCounter || {}
			if (!depatmentsCoursesCounter[data.department[0].id])
				depatmentsCoursesCounter = { ...depatmentsCoursesCounter, [data.department[0].id]: 0 };

			console.log('max lead: ' + data.department[0].max_leads_to_send + ' depatmentsCoursesCounter: ' + depatmentsCoursesCounter[data.department[0].id])
			if (depatmentsCoursesCounter[data.department[0].id] >= parseInt(data.department[0].max_leads_to_send)) {
				// alert('you have reached your max courses number for this department')
				setPopup(true)
				setError('הגעת למספר הקורסים המירבי לתחום זה ')
				return;
			}
			else {
				depatmentsCoursesCounter[data.department[0].id]++;
				mcontext.setDepatmentsCoursesCounter({ ...depatmentsCoursesCounter })
				window.sessionStorage.setItem('depatmentsCoursesCounter', JSON.stringify(depatmentsCoursesCounter))
				mcontext.setCallTimeRequired(doesLeadReqCallTime(global.lead[0].courses));
				window.sessionStorage.setItem('callTimeRequired', JSON.stringify(mcontext.callTimeRequired))
			}
		}

		mcontext.setLoading(true)

		var obj = JSON.stringify([{
			id: data.id,
			questions: q || '',
			activity_time: arrTimes.length > 0 ? arrTimes : times,
		}])
		console.log(global.domin + '!update_lead?id=' + global.lead[0].id + '&courses=' + obj + '&user_id=' + global.user?.user_id,)
		fetch(global.domin + '!update_lead?id=' + global.lead[0].id + '&courses=' + obj + '&user_id=' + global.user?.user_id+'&jwtauth='+global?.user?.token, {
			method: 'POST'
		})
			.then(res => {
				mcontext.setLoading(false)
				
				if (res.status === 200) {
					return res.json();
				}
			})
			.then(resJson => {
				
				global.lead = resJson.data?.data;
				props.updateLead(resJson.data?.data[0].courses)
				props.watching(false)
				mcontext.setWatching(false)
			})
	}


	function createMarkup(data) {
		return { __html: data };
	}

	const scrolltoMe = () => {
		window.scrollTo(0, myref?.current?.offsetTop - 140)
	}



	return (
		<MyContext.Consumer>
			{(context => {
				mcontext = context;
				location = mcontext?.location;
				// {/* data = context?.watchingData ||  global.watchingData */}
				if (context.watchingData) {
					console.log(context.watchingData)
					data = context.watchingData
				}


				return (
					<div className={'Wactch-Container'} ref={myref}>
						<ToastContainer />
						<Modal modalVisible={modalVisible} data={data?.questions}
							updateLead={(val) => {
								updateLaed(val, timesBackUp);
							}}
							modalStatus={(val) => setModalVisible(val)}
						/>

						<Modal
							modalVisible={popup}
							modalStatus={(val) => setPopup(val)}
							type={'popUp'}
							title='שים לב!'
						>
							<p>{error}</p>
						</Modal>

						<div className='watch-header'>
							<p
								onClick={() => { props.watching(false); mcontext.setWatching(false); mcontext.setWatchingData(null) }}
								style={{ fontFamily: 'Heebo-Bold', fontSize: '18px', textDecoration: 'underline', lineHeight: '22px', cursor: 'pointer' }}
							>
								{'< חזרה לתפריט ראשי'}
							</p>
							<p className='watchHeaderText' style={{ marginRight: '-15px', fontSize: '30px', fontWeight: 'bold' }}>{'תוצאות:  ' + (data?.departments?.map(i => i.name) || '')}</p>
						</div>

						<div className='whatch-body'>
							<div className='watch-grid'>
								<p className='couseTitle'>{data?.title}</p>
								<div className='Q1'>
									<div className='Q1L'>

										<div className='mapImage' >
											<GoogleMapReact
												// bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
												defaultCenter={{ lat: address?.lat, lng: address?.lng }}
												defaultZoom={address?.zoom}
												bootstrapURLKeys={{ key: 'AIzaSyD4yfQRyQkkAUrKpc_vM4yt3k7ATXYp5k4', language: 'he' }}
											// bootstrapURLKeys={{ key: global.googlePlacesAPI_KEY, language: 'he' }}
											>
												<Marker text={'asdfdsasadf'} lat={address?.lat} lng={address?.lng} />
											</GoogleMapReact>
										</div>

									</div>

									<div className='Q1R'>
										<p style={{ marginTop: '15px', fontWeight: 'bold' }}>{data?.institution?.title}</p>
										{/* <p style={{ color: '#FEAA35', marginTop: '17px', fontWeight: 'bold' }}>{`${data.match_level}:רמת התאמה`}</p> */}
										{/* <p className='Q1RText'>{data?.institution?.description?.replace(replaceHTMLreg, '')}</p> */}
										<div className='info-text' dangerouslySetInnerHTML={createMarkup(data?.course_description || '')} />
										<div className='locationContainer'>
											{location ? <Location /> : null}
										</div>
									</div>
								</div>

								{renderQ()}

								<div className='watchTiming'>
									<p className={'text3'} style={{ marginTop: '31px' }}>{' מועד לימודים'}</p>
									<div className='watchTimingCheckBoxs'>
										{renderTimes()}
									</div>
								</div>
								<Btn text='אישור הוספה' style={{ width: '227px', height: '45px', backgroundColor: '#FEAA35', alignSelf: 'center' }}
									onClick={() => {
										if (data?.questions?.length > 0) {
											timesBackUp = [...arrTimes];
											setModalVisible(true)
										}
										else
											updateLaed();
									}}
								/>
							</div>
						</div>

					</div>
				)
			})}
		</MyContext.Consumer>
	)
}


export default Wactch;