import React, { useState, useRef } from "react";
import './slider.css'
import SliderItem from './sliderItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import 'swiper/swiper-bundle.css';
import '../../components/Modal/modal.css'
import Btn from '../../components/Btn/btn'
import { Icon } from '@iconify/react';
import rightOutlined from '@iconify-icons/ant-design/right-outlined';
import leftOutlined from '@iconify-icons/ant-design/left-outlined';

SwiperCore.use([Navigation]);



const SimpleSlider = (props) => {

	const { data } = props;

	const [modal, setModal] = useState(props.modal);

	const navigationPrevRef = useRef(null)
	const navigationNextRef = useRef(null)

	const renderItems = () => {
		return data?.map((notifiaction, index) => {
			return (
				<SwiperSlide key={index}>
					<SliderItem
						data={notifiaction}
						key={index}
						indexNum={index}
						modal={(val) => {
							setModal(val)
						}}
					/>
				</SwiperSlide>
			)
		})
	}

	const Modal = (props) => {
		return (
			<div className='modal'>
				<div className='Q'>
					<div className='Qheader'>
						<span style={{ fontSize: '33px', marginLeft: '30px', cursor: 'pointer' }}
							onClick={() => {
								setModal({ modalVisible: false })
							}}  >X</span>
						<span className='headerTitle' style={{ left: '20vw' }}>{'הודעה'}</span>
					</div>
					<div className='QinnerContainer'>
						<span style={{ fontSize: '24px', fontFamily: 'Heebo-Bold', marginBottom: '10px', marginTop: '20px' }}>{modal?.title}</span>
						<span style={{ fontSize: '20px', marginRight: '15px' }}>{modal?.content}</span>
					</div>
					<Btn style={{ width: '228px', height: '45px', backgroundColor: '#FEAA35', alignSelf: 'center', marginTop: '20px' }} text='סגור'
						onClick={() => {
							setModal({ modalVisible: false })
						}} />
				</div>
			</div>
		)
	}

	const SliderRightArrow = (props) => {
		return (
			<div ref={navigationNextRef} className={'swiper-arrow swiper-arrow-next pointer'} style={{ height: '100%', width: '40px' }}>
				<Icon icon={rightOutlined} />
			</div>
		)
	}

	const SliderLeftArrow = (props) => {
		return (
			<div ref={navigationPrevRef} className={'swiper-arrow swiper-arrow-prev pointer'} style={{ height: '100%', width: '40px', left: '-39px', zIndex: '9' }}>
				<Icon icon={leftOutlined} />
			</div>
		)
	}

	return (
		<div className='sliderContainer' dir="rtl">
			{modal?.modalVisible ? <Modal /> : null}
			<Swiper
				spaceBetween={0}
				slidesPerView={3}
				navigation={{
					prevEl: navigationPrevRef.current,
					nextEl: navigationNextRef.current,
				}}

			>
				{renderItems()}
			</Swiper>
			{SliderRightArrow()}
			{SliderLeftArrow()}
		</div>
	);
}

export default SimpleSlider;