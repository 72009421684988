import React from 'react'
import global from '../../res/global'
import MyContext from '../../store/MyContext'
import CousreCard from '../../components/CousreCard/CousreCard'
import { strings } from '../../strings'
import { toast } from 'react-toastify';

const SearchResultItem = (props) => {

    const { setPopup, setError, data, resultOrderBy } = props;

    var mcontext;

    const updateLead = (q) => {

        if (global.lead[0].courses.length >= global.user?.max_add_to_cart) {
            let toastOption = {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            };
            toast.success(strings.CourseCard.cart_is_full, toastOption);
            return;
        }

        if (data.department.max_leads_to_send !== null) {
            var depatmentsCoursesCounter = mcontext?.depatmentsCoursesCounter || {}
            if (!depatmentsCoursesCounter[data.department.id])
                depatmentsCoursesCounter = { ...depatmentsCoursesCounter, [data.department.id]: 0 };

            if (depatmentsCoursesCounter[data.department.id] >= parseInt(data.department.max_leads_to_send)) {
                setPopup(true)
                setError('הגעת למספר הקורסים המירבי לתחום זה ')
                return;
            }
            else {
                depatmentsCoursesCounter[data.department.id]++;
                mcontext.setDepatmentsCoursesCounter({ ...depatmentsCoursesCounter })
                window.sessionStorage.setItem('depatmentsCoursesCounter', JSON.stringify(depatmentsCoursesCounter))
            }
        }


        if (q?.length > 0) {
            var Qarr = mcontext?.questions
            var arrQids = mcontext?.questions.map(i => (i.id))

            q.forEach(i => {
                if (!arrQids.includes(i.id)){
                    Qarr.push(i);
                }
            })

            mcontext && mcontext.setQuestions([...Qarr])

        }

        mcontext && mcontext.setLoading(true)

        props.updateLead(global.lead[0].courses);

        mcontext.setLead(global.lead);

    }

    return (
        <MyContext.Consumer>
            {(context) => {
                mcontext = context;
                return (
                    <CousreCard
                        resultOrderBy={resultOrderBy}
                        course={data}
                        mContext={mcontext}
                        updateLead={courses => updateLead(courses)}
                    />
                )
            }}
        </MyContext.Consumer>
    )
}

export default SearchResultItem;