import React, { useState, useRef, useEffect } from 'react'
import './personalDetails.css'
import Search from '../../components/serach/search'
import Summery from '../../components/summery/summery'
import Btn from '../../components/Btn/btn'
import global from '../../res/global'
import { strings } from '../../strings'
import MyContext from '../../store/MyContext'
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from '@iconify/react';
import bxsToggleRight from '@iconify-icons/bx/bxs-toggle-right';
import bxsToggleLeft from '@iconify-icons/bx/bxs-toggle-left';
import editTwotone from '@iconify-icons/ant-design/edit-twotone';
import saveTwotone from '@iconify-icons/ant-design/save-twotone';
import SelectAffiliatePopup from '../../components/AffiliatePopup/SelectAffiliatePopup';
import Popup from '../../components/popup/Popup'
import Interweave from 'interweave'
import { fetchUpdateLead } from '../../generalUtill'
import { validatePersonalID, validateLetters, validateEmail, validateNumbers, setLead, validatePhone } from '../../res/utils'
import { updateLadApi } from '../../res/api';
import cityList from '../../res/city-list'
import Select from 'react-select'

const PersonalDetails = (props) => {
	var personalDetails = global.lead[0]?.personal_info;
	var lead = global.lead[0];
	var mcontext = null
	var comments = lead?.remarks ? lead?.remarks : '';

	const [showCommit, setShowCommit] = useState(false)
	const [commite, setCommit] = useState(comments)
	const [updateLead, setUpdateLead] = useState([])
	const [globalError, setGlobalError] = useState(false)
	const [changing, setChanging] = useState(false)
	const [values, setValues] = useState([])
	const [incomingCall, setIncomingCall] = useState(lead?.incoming_call ?? false);
	const [bools, setBools] = useState({});
	const [affiliateName, setAffiliateName] = useState('');
	const [affiliateDisplay,setAffiliateDisplay] = useState(false);
	const [localLead, setLocalLead] = useState();
    const [resultOrderBy,setResultOrderBy] = useState('departments');
	const commitRef = useRef(null)
	const [missingRequiredFields, setMissingRequiredFields] = useState([]);
	const [requiredFieldsValid, setRequiredFieldsValid] = useState(false);

	const fictiveEmail ="user@email.com";
	useEffect(() => {
		validateRequiredFields();
		setLocalLead(lead);
		setAffiliateName(lead?.affiliate_name);
		setAffiliateDisplay((lead?.affiliate_display??false) || (mcontext.user.role!='agent' && mcontext.user.role!='superadviser' && mcontext.user.role!='achmash'));
	}, []);

	useEffect(() => {
		validateRequiredFields();
	}, [affiliateName]);

	useEffect(() => {
		const personal_info = localLead?.personal_info;

		setBools({
			question_want_english_improvment: personal_info?.question_want_english_improvment ? personal_info.question_want_english_improvment : false,
			question_improvement_gpa: personal_info?.question_improvement_gpa ? personal_info.question_improvement_gpa : false,
			question_want_pet: personal_info?.question_want_pet ? personal_info.question_want_pet : false,
			question_want_dorms: personal_info?.question_want_dorms ? personal_info.question_want_dorms : false,
			question_has_pet: personal_info?.question_has_pet ? personal_info.question_has_pet : false,
			question_has_ma: personal_info?.question_has_ma ? personal_info.question_has_ma : false,
			question_has_ba: personal_info?.question_has_ba ? personal_info.question_has_ba : false,
			question_has_engineer: personal_info?.question_has_engineer ? personal_info.question_has_engineer : false,
			gpa_type: personal_info?.gpa_type ?? false,
		});
	}, [localLead?.personal_info]);

	useEffect(() => {
		validateRequiredFields();
	}, [personalDetails.last_name, personalDetails.first_name, personalDetails.email, personalDetails.phone, personalDetails.address, bools.question_has_ba]);

	useEffect(() => {
		if (missingRequiredFields.length) {
			setRequiredFieldsValid(false);
		} else {
			setRequiredFieldsValid(true);
		}
	}, [missingRequiredFields]);

	useEffect(() => {
		setAffiliateName(global.lead[0]?.affiliate_name);
	}, [global.lead[0]]);

	const validateCallTime=(str) =>{
		if(mcontext && mcontext.callTimeRequired && str.trim ==='')
		{ 
			return false;
		}
		return true;
	}

	const validateRequiredFields = () => {
		let missingRequiredFields = [];

		const requiredFields = [
			{
				key: 'first_name',
				label: strings.requiredFirstName
			},
			{
				key: 'last_name',
				label: strings.requiredLastName
			},
			/*{
				key: 'email',
				label: strings.requiredEmail
			},*/
			{
				key: 'phone',
				label: strings.requiredPhone
			},
			{
				key: 'address',
				label: strings.requiredCity
			}
		];

		requiredFields.map((requiredField) => {
			if ('address' === requiredField.key) {
				if (!isValidAddress()) {
					missingRequiredFields.push(requiredField.label);
					setChanging(true);
				}
			} else if (!personalDetails[requiredField.key] || !personalDetails[requiredField.key].length) {
				missingRequiredFields.push(requiredField.label);
				setChanging(true);
			}
			setMissingRequiredFields(missingRequiredFields);
		});


		if (!affiliateName) {
			setMissingRequiredFields([...missingRequiredFields, strings.requiredAffiliate]);
		}
	}

	const openClosePartners = () => {
		setShowCommit(!showCommit)
	}

	const isValidAddress = () => {
		return cityList.some((city) => {
			return city.label == personalDetails?.address;
		});
	}
	const Marks = (props) => {
		return (
			<PdItem mark={true} {...props} />
		)
	}

	

	const PdItem = (props) => {
		var { title, id, defaultValue, mark, remarks, atts, boldcls='',enableChanges = false ,maxlength,hidden=false} = props;

		const [val, setVal] = useState(values);
		const [error, setError] = useState('');
		const [required,setRequired] = useState(' required ');
		var arr = values;

		useEffect(() => {
			
	
			setError('');

			if (typeof val[id] !== 'undefined' ) {
				let errorFlag = false;
				let errorMsg = props.validationMessage;

				if (props.min || props.max) {
					errorMsg = errorMsg.replace('%min%', props.min);
					errorMsg = errorMsg.replace('%max%', props.max);
				}

				if (props.validation && !props.validation(val[id])) {
					errorFlag = true;
				}
				if (props.min && val[id] < props.min) {
					errorFlag = true
				}
				if (props.max && val[id] > props.max) {
					errorFlag = true
				}

				if (errorFlag) {
					setError(errorMsg);
				}
				
			}
		}, [val]);

		const customStyles = {
			container: provided => ({
				...provided,
				width: 150
			})
		};

		const getDefaultValue = (value) => {
			const selectedValue = props.list.find((listItem) => {
				return listItem.label == value;
			});

			return selectedValue;
		}
		const compare = (a, b) => {
			if (a.label < b.label) {
				return -1;
			}
			if (a.label > b.label) {
				return 1;
			}
			return 0;
		}

		const sortedList = props.list ? props.list.sort(compare) : '';
		
		return props.type === 'select' ?
			<div className={`con${mark ? ' markContainer' : ''} remark-container`} style={{ ...props.style }}>
				<div className="pDataTextContainer">
					<span className={`pDataText ${props.boldcls}`}>{title}: </span>
					<Select
						defaultValue={getDefaultValue(defaultValue)}
						isDisabled={!changing}
						placeholder={title}
						isRtl={true}
						options={sortedList}
						styles={customStyles}
						onChange={value => {
							arr[id] = value.label;
							setVal([...arr])
						}}
					/>
				</div>
				{remarks && changing && <span className="remark">{remarks}</span>}
				{error && <span className="input-error">{error}</span>}
			</div>
			: (
				<div className={`con${mark ? ' markContainer' : ''}${props.hidden?' hidden':''}`} style={{ ...props.style }}>
					<span className={`pDataText ${props.boldcls}`}>{title}</span>
					<input 
						
						className={`conInput${mark ? ' mark' : ''}${error ? ' error-field' : ''}`}
						value={!changing && !enableChanges && defaultValue ? defaultValue : val[id] ?? defaultValue} disabled={!changing && !enableChanges}
						type={props.type ? props.type : 'text'}
						maxLength={props.maxlength ? props.maxlength  : ''}
						style={!changing && !enableChanges  ? { borderWidth: '0px', direction: 'ltr' } : !enableChanges?{ minWidth: '40px' }:{}}
						onChange={e => {
							const newValue = e.target.value;

							if ('number' === props.type && newValue === 0) {
								arr[id] = e.target.value;
								setVal([...arr]);
								return false;
							}

							arr[id] = e.target.value;

							setVal([...arr]);
						}}
						onKeyDown={e => {
							if (e.key === 'Backspace') {
								return false;
							}

							if ('number' === props.type && !parseInt(e.key) && e.key != 0) {
								e.preventDefault();
							};
						}}
						{...atts}
					/>
					{error && <span className="input-error">{error}</span>}
				</div>
			)
	}



	const saveChanges = (frompopup = false) => {
		var pdStr = '';

		var pdArr = [
			'lead-first-name',
			'lead-last-name',
			'lead-email',
			'lead-phone',
			'lead-age',
			'lead-lang-test',
			'lead-address',
			'lead-pet',
			'lead-gpa',
			'personal_id',
			'lead-call-time'
		];

		if (document.querySelector('.conInput.error-field')) {
			setGlobalError(true);
			return;
		} else {
			setGlobalError(false);
		}

		mcontext && mcontext.setLoading(true);
		
		values.forEach((value, index) => {
			pdStr += pdArr[index] + '=' + value + '&'
		})

		
		if(pdStr!=''){
		updateLadApi(null, pdStr, mcontext)
			.then(resJson => {
				setLead(resJson.data.data);
				setChanging(false);
				mcontext.setLead(resJson.data.data);
				mcontext && mcontext.setLoading(false);
				personalDetails = global.lead[0]?.personal_info;
				
				if(frompopup &&  personalDetails?.email && personalDetails?.email!='' && personalDetails?.email!=fictiveEmail)
				{ 
					mcontext.setSummery(true);
					mcontext.setValidateEmail(false);
				}
				
			});
		}
		else{
			if(frompopup &&  personalDetails?.email && personalDetails?.email!='' && personalDetails?.email!=fictiveEmail)
				{ 
					mcontext.setSummery(true);
					mcontext.setValidateEmail(false);
				}
			 setChanging(false);mcontext && mcontext.setLoading(false);
			}
	}

	const saveCommit = () => {

		mcontext && mcontext.setLoading(true);

		fetch(global.domin + '!update_lead?id=' + lead.id + '&lead-remarks=' + commite + '&user_id=' + global.user?.user_id+'&jwtauth='+global?.user?.token, {
			method: 'POST'
		})
			.then(res => {
				if (res.status === 200) {
					return res.json()
				}
				else {
					alert('error: ', res.status)
					mcontext && mcontext.setLoading(false)
				}
			})
			.then(resJson => {
				if (resJson?.data?.data) {
					if (resJson.data.data) {
						setLead(resJson.data.data);
					}
					setCommit(resJson?.data?.data[0].remarks)
					mcontext && mcontext.setLoading(false)
				}
			})
	}

	const handleSwitchIncomingCall = (e) => {
		e.preventDefault();
		e.target.classList.toggle('active');
		const newVal = !incomingCall;
		fetchUpdateLead({ 'lead-incoming-call': newVal }).then(res => {
			if (res.status === 200)
				return res.json();
		}).then(resJson => {
			if (resJson?.data?.data) {
				setLead(resJson.data.data);
				mcontext && mcontext.setLead(resJson.data.data);
				setAffiliateName(resJson.data.data[0].affiliate_name);
			}
			return newVal;
		});
		setIncomingCall(newVal);
	}

	const handleChangeBool = (e) => {
		e.preventDefault();
		const newVal = e.target.value;
		const name = e.target.name;
		const params = {};

		params[name] = newVal;

		if ('question_has_ma' === name && newVal === 'true') {
			params.question_has_ba = 'true';
		}

		if (('question_has_ma' === name || 'question_has_ba' === name) && newVal === 'true') {
			params.gpa_type = 'full';
			params['lead-gpa-type'] = 'full';
		}


		fetchUpdateLead(params).then(res => {
			return res.json();
		}).then(resJson => {
			if (resJson?.data?.data) {
				setLead(resJson.data.data);
				setLocalLead(resJson.data.data[0]);
				mcontext && mcontext.setLead(resJson.data.data)
			}
			return newVal;
		});

		setBools({
			...bools,
			...params
		});
	}

	const displayRequiredFieldsMessages = () => {
		return missingRequiredFields.map((requiredFieldMessage, key) => {
			return (
				<div className='required' key={key}>
					{requiredFieldMessage}
				</div>
			);
		});
	}


	return (
		<MyContext.Consumer>
			{(context) => {
				mcontext = context;

				return (
					<>
						{personalDetails && <SelectAffiliatePopup data={lead} setAffiliateName={(affiliate) => {
							setAffiliateName(affiliate);
						}} />}
						<div className='PD'>
							<div className='pdContainer shadow personal-details-anchor'>
								<div className='PD_INNER'>
									<div className='dpHeader'>
										<div className='headerText flex-column'>
											<div className="info-text">
												<span  style={affiliateDisplay?{display:'block'}:{display:'none'}}>{'שותף: ' + affiliateName}</span>
												<span>{'פרוייקט: ' + personalDetails?.project_name}</span>
												<span>{'מספר פניה: ' + lead?.crm_code}</span>
											</div>
											<button className={incomingCall ? 'btn active' : 'btn'} onClick={(e) => handleSwitchIncomingCall(e)}>
												{strings.personalDetails.incomingCall}
												{incomingCall ? <Icon icon={bxsToggleRight} /> : <Icon icon={bxsToggleLeft} />}
											</button>
										</div>
										<div className="buttons-wrapper">
											{globalError && <span className="global-error">{strings.personalDetails.globalError}</span>}
											<button className="btn-action" onClick={() => changing ? saveChanges()
												: setChanging(true)}>
												{changing ? <Icon icon={saveTwotone} /> : <Icon icon={editTwotone} />}
											</button>
											<span className='headerText' style={{ fontSize: '22px' }}>{'פרטים אישים'}</span>
										</div>
									</div>

									<div className='required-fields'>
										{displayRequiredFieldsMessages()}
									</div>
									<div style={{ width: '100%', borderCollapse: 'collapse' }} >
										<div className='pData'>
											<div className="presonal-data-row">
												{changing ? <PdItem
													id={0}
													title={'שם פרטי:'}
													validation={validateLetters}
													validationMessage={strings.personalDetails.validation.letters} defaultValue={personalDetails?.first_name || ''}
												/> : null}
												{changing ? <PdItem
													id={1}
													title={'שם משפחה:'}
													validation={validateLetters}
													validationMessage={strings.personalDetails.validation.letters} defaultValue={personalDetails?.last_name || ''}
												/> : null}
												{!changing ? <PdItem
													id={1}
													title={'שם מלא:'}
													defaultValue={personalDetails?.first_name + " " + personalDetails?.last_name}
												/> : null}
												<PdItem
													id={2}
													title={'אימייל:'}
													type="email"
													style = {{display:'none'}}
													 defaultValue={personalDetails?.email || ''}
												/>
												<PdItem
													id={3}
													title={'טלפון:'}
													type="number"
													validation={validatePhone}
													validationMessage={strings.personalDetails.validation.phone} defaultValue={personalDetails?.phone || ''}
												/>
											</div>
											<div className="presonal-data-row">
												<PdItem
													id={9}
													title={'תעודת זהות:'}
													type="number"
													style = {{display:'none'}}
													atts={{ min: 0, max: 9999999999 }}
													validationMessage={strings.personalDetails.validation.personalId} defaultValue={personalDetails?.personal_id || ''} />
												<PdItem
													id={6}
													title={'עיר'}
													style={{width:'25%'}}
													type="select"
													list={cityList}
													validationMessage={strings.personalDetails.validation.numbersMinMax}
													defaultValue={personalDetails?.address || ''}
													remarks={"במידה ולא נמצאה העיר הרלוונטית ברשימה, נא סמנו עיר קרובה אחרת"}
													boldcls={'bold'}
													disabled={!changing}
												/>
												<div className="con selection" style={{width:'65%'}}>
													<div className="selection-entity-title">האם חשוב לך שמוסד הלימודים יהיה קרוב לאזור מגוריך? </div>
													<select
														name="question_want_study"
														className={!changing ? 'location' : ''}
														disabled={!changing}
														onChange={(e) => {setResultOrderBy( e.target.value=='true'?'location':'departments')}}
													>
														<option value="false">{strings.no}</option>
														<option value="true">{strings.yes}</option>
														
													</select>
												</div>
												</div>
												<div className="presonal-data-row">
												<PdItem
													id={4}
													title={'גיל:'}
													style={{width:'25%'}}
													type="number"
													validation={validateNumbers}
													validationMessage={strings.personalDetails.validation.numbersMinMax}
													min={0}
													max={100}
													defaultValue={personalDetails?.age || ''}
													boldcls={'bold'}
												/>

												<div className="con selection"  style={{width:'65%'}}>
													<div className="selection-entity-title">האם את/ה מעוניינ/ת להתחיל ללמוד בקרוב?</div>
													<select
														name="question_want_study"
														className={!changing ? 'disable' : ''}
														
														disabled={!changing}
													>
														<option value="false">{strings.no}</option>
														<option value="true">{strings.yes}</option>
														
													</select>
												</div>


											</div>
											<div className="presonal-data-row bools">
												<div className="con selection">
														<div className="selection-entity-title bold">{strings.personalDetails.gpa}</div>
														<select
															disabled={!changing}
															name="lead-gpa-type"
															onChange={(e) => handleChangeBool(e)}
															className={!changing ? 'disable' : ''}
															value={bools.gpa_type}
															defaultValue={bools.gpa_type}
														>
															<option value="">{strings.personalDetails.gpaSelections.none}</option>
															<option value="full">{strings.personalDetails.gpaSelections.full}</option>
															<option value="semi">{strings.personalDetails.gpaSelections.semi}</option>
															<option value="12">{strings.personalDetails.gpaSelections.twelve}</option>
															<option value="less12">{strings.personalDetails.gpaSelections.less12}</option>
														</select>
												</div>
												{(bools.gpa_type === 'full') &&
													<Marks title={'ממוצע בגרויות: '}
														type="number"
														validation={validateNumbers}
														validationMessage={strings.personalDetails.validation.numbersMinMax}
														min={0}
														max={120}
														id={8}
														defaultValue={personalDetails?.gpa || ''}
													/>
												}
												</div>
												<div className="presonal-data-row">
												<div className="con selection">
													<div className="selection-entity-title bold">{strings.personalDetails.pet}</div>
													<select
														disabled={!changing}
														name="question_has_pet"
														onChange={(e) => handleChangeBool(e)}
														className={!changing ? 'disable' : ''}
														defaultValue={bools.question_has_pet}
														value={bools.question_has_pet}
													>
														<option value="false">{strings.personalDetails.petSelections.none}</option>
														<option value="true">{strings.personalDetails.petSelections.has}</option>
													</select>
												</div>
												{bools.question_has_pet != false && bools.question_has_pet != 'false' &&
													<Marks
														title={'פסיכומטרי: '}
														type="number"
														validation={validateNumbers}
														validationMessage={strings.personalDetails.validation.numbersMinMax}
														min={200}
														max={800}
														id={7}
														defaultValue={personalDetails?.pet || ''
														} />
												}
												</div>
												<div className="presonal-data-row">
												<div className="con selection">
													<div className="selection-entity-title bold">{strings.hasBa}</div>
													<select
														disabled={!changing}
														name="question_has_ba"
														onChange={(e) => handleChangeBool(e)}
														className={!changing ? 'disable' : ''}
														value={bools.question_has_ba}
													>
														<option value="false">{strings.personalDetails.petSelections.none}</option>
														<option value="true">{strings.personalDetails.petSelections.has}</option>
													</select>
												</div>
												<div className="con selection">
													<div className="selection-entity-title bold">{strings.hasMa}</div>
													<select
														disabled={!changing}
														name="question_has_ma"

														onChange={(e) => handleChangeBool(e)}
														className={!changing ? 'disable' : ''}
														value={bools.question_has_ma}
													>
														<option value="false">{strings.personalDetails.petSelections.none}</option>
														<option value="true">{strings.personalDetails.petSelections.has}</option>
													</select>
												</div>
												<div className="con selection">
													<div className="selection-entity-title bold">{strings.hasEngineering}</div>
													<select
														disabled={!changing}
														name="question_has_engineer"
														onChange={(e) => handleChangeBool(e)}
														className={!changing ? 'disable' : ''}
														defaultValue={bools.question_has_engineer}
													>
														<option value="false">{strings.personalDetails.petSelections.none}</option>
														<option value="true">{strings.personalDetails.petSelections.has}</option>
													</select>
												</div>
												<div className="con selection">
													<div className="selection-entity-title">{strings.question_want_dorms}</div>
													<select
														disabled={!changing}
														name="question_want_dorms"
														onChange={(e) => handleChangeBool(e)}
														className={!changing ? 'disable' : ''}
														value={bools.question_want_dorms}
													>
														<option value="true">{strings.yes}</option>
														<option value="false">{strings.no}</option>
													</select>
												</div>
												<div className="con selection hidden">
													<div className="selection-entity-title">{strings.question_improvement_gpa}</div>
													<select
														disabled={!changing}
														name="question_improvement_gpa"
														onChange={(e) => handleChangeBool(e)}
														className={!changing ? 'disable' : ''}
														value={bools.question_improvement_gpa}
													>
														<option value="true">{strings.yes}</option>
														<option value="false">{strings.no}</option>
													</select>
												</div>
												<div className="con selection hidden">
													<div className="selection-entity-title">{strings.question_want_english_improvment}</div>
													<select
														disabled={!changing}
														name="question_want_english_improvment"
														onChange={(e) => handleChangeBool(e)}
														className={!changing ? 'disable' : ''}
														value={bools.question_want_english_improvment}
													>
														<option value="true">{strings.yes}</option>
														<option value="false">{strings.no}</option>
													</select>
												</div>
												<div className="con selection hidden">
													<div className="selection-entity-title">{strings.question_want_pet}</div>
													<select
														disabled={!changing}
														name="question_want_pet"
														onChange={(e) => handleChangeBool(e)}
														className={!changing ? 'disable' : ''}
														value={bools.question_want_pet}
													>
														<option value="true">{strings.yes}</option>
														<option value="false">{strings.no}</option>
													</select>
												</div>

											</div>


											{/* <div className="presonal-data-row">
												<div className="code-wrapper">
													<div className="title">לבדיקות בלבד:</div>
													<div className="field-wrapper">{`שותף נכנס: ${personalDetails.affiliate_code ? personalDetails.affiliate_code : ''}`}</div>
													<div className="field-wrapper">{`פרוייקט נכנס: ${personalDetails.project_code ? personalDetails.project_code : ''}`}</div>
												</div>
											</div> */}
										</div>
									</div>

									<div ref={commitRef} style={{
										overflow: 'hidden',
										transition: '0.8s',
										height: showCommit ? commitRef?.current?.scrollHeight + 10 + 'px' : '0px',
									}}>


										<textarea
											className='commit'
											type='text'
											placeholder={'הערות נציג על רשומה'}
											wrap="soft"
											value={commite}
											onChange={(e) => setCommit(e.target.value)} />

										<div className='markBtnContainer'>
											<span className='markBtnText' onClick={() => saveCommit()} >שמירת הערות</span>
											<span className='markBtnText' style={{ marginRight: '30px', color: '#D0DAEB' }} onClick={() => setCommit('')} >ניקוי</span>
										</div>

									</div>

									<div className={'partnersArrrowContainer'} onClick={() => openClosePartners()} >
										<img className='partnerArrow' src={showCommit ? require('../../assets/images/vertecalArrows/up.png') : require('../../assets/images/vertecalArrows/down.png')} />
									</div>
								</div>
							</div>
							<Popup
								show={context.summery}
								message={<Summery
									mContext={mcontext}
									updateLead={updateLead}
									updateLeadfunc={() => {
										setUpdateLead(!updateLead)
									}}
								/>}
								handleClose={context.setSummery}
								title={<Interweave noWrap={true} content={`${strings.Summery.title} `} />}
								height="730px"
								className="large"
								disableRegularClose={false}
							/>
							
							<Popup
								show={context.validateEmail}
								message={<div className='pData validatemail'>
								<div className="presonal-data-row">
										<PdItem
										enableChanges={true}
											id={2}
											title={'אימייל:'}
											type="email"
											validation = {validateEmail}
											defaultValue={  (personalDetails?.email && personalDetails?.email!=fictiveEmail) ? personalDetails?.email : ''}
											validationMessage={strings.personalDetails.validation.email} 
										/></div>
										<div className="presonal-data-row">
								<PdItem
								enableChanges={true}
									id={9}
									title={'תעודת זהות:'}
									type="number"
									validation={validatePersonalID}
									atts={{ min: 0, max: 9999999999 }}
									validationMessage={strings.personalDetails.validation.personalId} defaultValue={personalDetails?.personal_id || ''} />
								</div>

								<div className="presonal-data-row timeTocall">
								<PdItem
								enableChanges={true}
									id={10}
									title={'שעת חזרה ללקוח:'}
									type="text"
									maxlength="50"
									hidden={!mcontext || !mcontext.callTimeRequired}
									defaultValue={ personalDetails?.calltime??''}
									//validation = {validateCallTime} 
									//validationMessage={strings.personalDetails.validation.callTime}
									/>
								</div>

								<div className="info-text">
									<span>מספר טלפון: {personalDetails?.phone || ''}</span>
								</div>
								<div className="info-text">
									<span>שם מלא: {personalDetails?.first_name + " " + personalDetails?.last_name}</span>
								</div>
								<div className="presonal-data-row" style={{margin:"inherit"}}>
									<Btn text='שמור והמשך'
										style={{ backgroundColor: '#2B2D41', color: '#ffff'}}
										
										onClick={() => {
									
										 if(!document.querySelector('.validatemail .input-error') /*&& !document.querySelector('.timeTocall .input-error')*/){	
											 saveChanges(true);
											}
										else
										{ /*alert('error');*/}
										 
										}}
									/>
								</div></div>
								}
								title={<Interweave noWrap={true} content={`${strings.ValidateEmail.title} `} />}
								
								className="small"
								disableRegularClose={true}
							/>
							

							{requiredFieldsValid ?
								<div>
									<Search updateLead={(val) => { setUpdateLead(!updateLead) }} corseTypes={props.corseTypes} location={props.location}
										setHasResult={props.setHasResult} ResultOrderBy={resultOrderBy}
										idsToDisableLocationFilter={props.idsToDisableLocationFilter}
									/>
								</div>
								:
								<div className="required">
									{strings.missingRequiredFields}
								</div>
							}

						</div>
					</>
				)
			}}
		</MyContext.Consumer>
	)
}

export default PersonalDetails;