import React, { useState, useEffect } from 'react';
import Btn from '../Btn/btn';
import global from '../../res/global';
import * as _ from 'underscore';
import { strings } from '../../strings';
import Interweave from 'interweave';
import CoursePopup from '../../components/CoursePopup/CoursePopup';
import 'react-toastify/dist/ReactToastify.css';


const UpSalesItem = (props) => {
    const [coursePopup, setCoursePopup] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false);
    
    const {
        course,
        disabled,
        updateLead,
        mContext,
        updateLeadfunc } = props;

    useEffect(() => {
        setIsDisabled(disabled);
    }, [disabled]);

    return (
        <>
            <div className='upSalesItem'
                disabled={isDisabled}
                data-course-id={course.id}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <span className='upSalestitle'>
                        <span className="institutionName">{course.institution.title} ({course.institution.id})</span>
                        <span className="courseName">{course.course_name} ({course.id})</span>
                        <span className="institutionAddress">{course.institution.address.address}
                            ({course.institution.address.distance > 1000 ? 'מרחק לא ידוע' : course.institution.address.distance + " " + strings.km})
                        </span>
                    </span>
                </div>
                <div className='line'></div>
                <div className='upSalesContent'>
                    <div className="column-inner">
                        <div className="column-title"><span>{strings.CourseCard.settings}</span></div>
                    </div>
                    <div className="column-content">
                        {course.course_settings.map((settings, key) => {
                            return (
                                <div className="column-inner" key={key}>
                                    <div className="column-inner-title">
                                        {settings.label}: {settings.value}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='line' style={{ marginTop: '15px', marginBottom: '5px' }}></div>
                <Btn
                    text={strings.readMore}
                    style={{
                        width: '80%',
                        border: 1,
                        borderColor: '#000',
                        marginBottom: 10
                    }}
                    onClick={() => {
                        setCoursePopup(true);
                    }} />
                <Btn
                    text={global.upSales.button}
                    style={{
                        width: '80%',
                        backgroundColor: isDisabled ? '#f5f5f5' : "#FEAA35",
                        cursor: isDisabled ? 'default' : 'pointer',
                    }}
                    disabled={isDisabled}
                    onClick={() => {
                        updateLead(course.id,null,null,true)
                    }} />
            </div>
            <CoursePopup
                show={coursePopup}
                mContext={mContext}
                title={strings.Summery.upsales_course_popup_title}
                setShow={setCoursePopup}
                course={course}
                fromUpsale={true}
                updateLead={() => {
                    setIsDisabled(true);
                    updateLeadfunc();
                }}
            />
        </>
    )
}

export default UpSalesItem;