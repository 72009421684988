import global from "./global"

export const validateNumbers = (string) => {
    if (string.match(/^[0-9]*$/)) {
        return true
    }
    return false
}
export const validateEmail = (string) => {
    if (string.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        return true
    }
    return false
}

export const setLead = (leadJson) => {
    global.lead = leadJson;
    window.sessionStorage.setItem('lead', JSON.stringify(leadJson))
}

export const validatePhone = (value) => {
    var regex = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;

    return regex.test(value);
}

export const validatePersonalID = (string) => {
    
    if( ! string ){
        return true;
    }
    
    if (!parseInt(string)) {
        return false;
    }

    let id = String(string).trim();
    if (id.length > 9 || id.length < 5 || isNaN(id)) return false;

    if (id === '555555555') {
        return true;
    }

    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

    return Array
        .from(id, Number)
        .reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
}

export const validateLetters = (string) => {
    if (/^[a-zA-Zא-ת|'|,|׳ ]+$/.test(string)) {
        return true
    }
    return false
}

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export const calcDistance = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d.toFixed(0);
}

// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180;
}

export const distanceFromLead = (institutionLocation) => {
    var distance = null;

    if (global.lead[0].location && institutionLocation?.lat && institutionLocation?.lng && global?.lead[0]?.location?.lat) {
        distance = calcDistance(institutionLocation.lat, institutionLocation.lng, global.lead[0].location.lat, global.lead[0].location.lng);
    }

    return distance;
}