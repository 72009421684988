import React from 'react'
import '../../App.css'
import '../../components/serach/serach.css'
import Slider from '../../components/slider/slider'
import Identification from '../../components/identification/identification'
import PartnerItem from '../../components/partnerItem/partnerItem'
import Tabs from '../../components/taps/taps'
// import ReactCSSTransitionGroup from 'react-transition-group'; // ES6
// import { CSSTransitionGroup } from 'react-transition-group' // ES6

const CSSTransitionGroup = React.CSSTransitionGroup;
const TransitionGroup = React.TransitionGroup;

var data = [
	{ name: 'הודעה מערכת', highLigthted: true },
]

class CRM extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div className='body-grid crm '>
				<Identification user={(user) => { this.props.user(user) }  }  setSideBar={(isopen)=>{ this.props.setSideBar(isopen)}} />
			</div>
		)
	}
}

export default CRM;