import React from 'react'
import Select from 'react-select';
import { strings } from '../../strings'
import * as _ from 'underscore'

let ordersOptions = [
	{
		'value': 'departments',
		'label': strings.resultOrderby.options.departments
	},
	{
		'value': 'rating',
		'label': strings.resultOrderby.options.rating
	},
	{
		'value': 'location',
		'label': strings.resultOrderby.options.location
	}
];

export default function ResultOrderby(props) {
	const { orderby, setOrderby, store } = props
	if (store?.location && store?.location.address.name) {
		if (!ordersOptions.some(option => option.value === 'location')) {
			ordersOptions.push({
				'value': 'location',
				'label': strings.resultOrderby.options.location
			});
		}
	}

	const onInputChange = option => {
		setOrderby(option.value)
	}

	let defaultOption = _.find(ordersOptions, function (option) {
		return option.value === orderby;
	});
	return (
		<div className="result-orderby">
			<Select
				defaultValue={defaultOption}
				placeholder={strings.resultOrderby.placeholder}
				onChange={onInputChange}
				options={ordersOptions}
			/>
		</div>
	)
}
