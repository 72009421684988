import React, { useEffect } from 'react'
import global from '../../res/global'
import { Icon } from '@iconify/react';
import closeCircleFilled from '@iconify-icons/ant-design/close-circle-filled';
import InterWeave from 'interweave';

export default function NotificationReciver(props) {

	const fetchNotification = () => {
		try {
			return fetch(global.domin + '!get_public_notification',
				{
					method: 'post',
					headers: {
						'Content-Type': 'application/json'
					}
				});
		} catch (err) {
			console.error(err.message);
		}
	};

	const handleNewMessage = (resJson, show = true) => {
		if (props.setState) {
			props.setState(current => {
				const new_message = resJson?.data?.data;

				if (current.message !== new_message.content) {
					return { ...current, active: show, message: new_message.content, date: new_message.date }
				} else {
					return current;
				}
			});
		}
	}

	const handleClose = () => {
		if (props.setState) {
			props.setState({ ...props.state, active: false })
		}
	}

	useEffect(() => {
		const handleFetchNotiffication = (show = true) => {
			fetchNotification()
				.then(res => res.json())
				.then(resJson => {
					handleNewMessage(resJson, show);
				});
		}

		const interval = setInterval(() => {
			handleFetchNotiffication();
		}, 50000);

		return () => clearInterval(interval)
	}, []);


	return (
		<>
			{props.state.message && props.state.active &&
				<div className="popup-style notification-reciver">
					<div className="inner-popup rtl">
						<button className="btn-close" onClick={() => handleClose()}>
							<Icon icon={closeCircleFilled} />
						</button>
						<div className="popup-entry-content">
							<div className="date-wrapper"><span>{props.state.date}</span></div>
							<InterWeave noWrap={true} content={props.state.message} />
						</div>
					</div>
				</div>
			}
		</>
	)
}
