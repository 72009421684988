import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Routes,Navigate  } from 'react-router-dom';
import './App.css';
import Tabs from './components/taps/taps'
import Slider from './components/slider/slider'
import PersonalDetails from './screens/personalDetails/personalDetails'
import ActionHistory from './components/actionHistory/actionHistory'
import SideBar from './components/sideBar/sideBar'
import CRM from './screens/CRM/crm'
import global from './res/global'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from './components/loader/Loader'
import MyProvider from './store/MyProvider'
import MyContext from './store/MyContext';
import MemberInfo from './components/MemberInfo/MemberInfo'
import StickyNotification from './components/StickyNotification/StickyNotification'
import Auth from './components/auth/auth'
import Popup from './components/popup/Popup'
import StickyNaviagation from './components/StickyNaviagation/StickyNaviagation'
import NotificationReciver from './components/NotificationReciver/NotificationReciver';
import InterWeave from 'interweave';
import SelectAffiliatePopup from './components/AffiliatePopup/SelectAffiliatePopup';
import { getLead, getOpenSearchList } from './res/api';
import SummaryCount from './components/summaryCount/summaryCount';
import IdleTimer from 'react-idle-timer'
import LeadTimer from './components/LeadTimer/LeadTimer'


var pdhData = [], data = [];
var mcontext = null;

const mql = window.matchMedia(`(min-width: 800px)`);

class App extends React.Component {

	constructor(props) {
		super(props);

		this.idleTimer = null;

		this.handleOnAction = this.handleOnAction.bind(this)
		this.handleOnActive = this.handleOnActive.bind(this)
		this.handleOnIdle = this.handleOnIdle.bind(this)

		this.state = {
			partnersOpen: false,
			modalVisible: false,
			sidebarDocked: mql.matches,
			sidebarOpen: false,
			openSideBar: false,
			idsToDisableLocationFilter: [],
			user: global.user,
			corseType: [],
			corseTypes: [],
			otherFilter: [],
			otherFilters: [],
			hasResult: false,
			showStickyNav: false,
			location: {},
			strip_notifications: [],
			loading: false,
			showSelectAffiliatePopup: false,
		}

	}

	handleOnAction(event) {
	}

	handleOnActive(event) {
	}

	handleOnIdle(event) {
		window.sessionStorage.removeItem('auth');
		window.sessionStorage.removeItem('lead');

		window.location.reload();
	}

	getCorseTypeFromApi() {
		 var url = global.domin + '!get_course_types';
		
		fetch(global.domin + '!get_course_types', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(res => res.json())
			.then(resJson => {
				this.setState({ corseType: resJson?.data?.data })
			})
	}

	getIdsToDisableLocationFilterFromApi() {
		fetch(global.domin + '!get_disable_location_from_filter', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(res => res.json())
			.then(resJson => {
				this.setState({ idsToDisableLocationFilter: resJson?.data?.data })
			})
	}

	getOtherFiltesFromApi() {
		fetch(global.domin + '!get_other_filters', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(res => res.json())
			.then(resJson => {
				this.setState({ otherFilter: resJson?.data?.data })
			})
	}

	get_strip_notifications() {
		fetch(global.domin + '!get_strip_notifications', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((res) => (res.json()))
			.then((resJson) => {
				if (resJson) {
					this.setState({ strip_notifications: resJson?.data?.data })
				}
			})
	}

    //get lead from crm and insert to global.lead (if not exists yet)
	getLead() {
		
		var crmCode = global?.lead ? global?.lead[0]?.crm_code : null;

		mcontext && mcontext.setLoading(true)

		mcontext.setManagerCode(null);
		
		if (crmCode) {
			getLead(crmCode).then(res => {
				if (res.status === 200) {
					return res.json();
				}
				else {
					console.log('error: getLead ' + res.status)
					mcontext && mcontext.setLoading(false)
				}
			}).then(resJson => {
				if (resJson) {
					global.lead = resJson?.data?.data;
					window.sessionStorage.setItem('lead', JSON.stringify(resJson?.data?.data))
					mcontext && mcontext.setLoading(false) && mcontext.setLead(global.lead)
					this.getAutocompleteData()
				}

				this.setState({ openSideBar: true });
			});
		} else {
			mcontext && mcontext.setLoading(false);
		}

	}

	setShowSelectAffiliatePopup(val) {
		this.setState(currnet => {
			return { ...currnet, showSelectAffiliatePopup: val }
		});
	}

	refresh() {

		fetch(global.domin + '!refresh_lead?crm_code=' + global.lead[0]?.code +'&jwtauth='+global?.user?.token, {
			method: 'POST'
		})
			.then(res => {
				return res.json();
			})
			.then(resJson => {

				if (resJson?.data?.status === 200) {
					global.lead = resJson?.data?.data;
					window.sessionStorage.setItem('lead', JSON.stringify(resJson?.data?.data))
				}
				window.location.reload();
			})
	}

	componentDidMount() {
	
		//mcontext && mcontext.setUser(global.lead?.length > 0)
		mcontext?.user && this.getLead()
		this.getCorseTypeFromApi();
		this.getOtherFiltesFromApi();
		this.get_strip_notifications();
		this.getIdsToDisableLocationFilterFromApi();

		setTimeout(() => {
			this.setState(current => { return { ...current, showStickyNav: true } });
		}, 1000);

		
	}

	


	getAutocompleteData(){
		getOpenSearchList().then((data) => {
			mcontext.setAutocompleteData(data.data.data);
		});
	}
	 handlePrivateRoute = (element) => {
		// If user is authenticated, show the element
		if (mcontext?.auth) {
		  return element;
		}
		// If user is not authenticated, navigate to "/"
		return <Navigate to="/" />;
	  };
	render() {

		var user = mcontext?.user
		let counter = this.state.strip_notifications.global ? this.state.strip_notifications.global.length : 0;

		! mcontext?.autoCompleteData && this.getAutocompleteData();

		data = [
			{
				name: 'הודעות מערכת (' + counter + ')',
				highLigthted: true,
				data: this.state.strip_notifications.global
			},
		]

		pdhData = [
			{ name: 'היסטוריית פעילות', highLigthted: false, screen: (<ActionHistory />) },
			{
				name: 'פרטים אישים', highLigthted: true, screen: (<PersonalDetails corseTypes={this.state.corseTypes} location={this.state.location} idsToDisableLocationFilter={this.state.idsToDisableLocationFilter} setHasResult={(val) => this.setState(current => {
					if (val !== current.hasResult) {
						return { ...current, hasResult: val }
					}
				})} />)
			},
		]

		const LoginPopup = ({ mcontext }) => {
			const [messageInfo, setMessageInfo] = useState({ message: '', date: '' });
			useEffect(() => {
				if (mcontext.loginPopup) {
					fetch(global.domin + '!get_login_notification', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						}
					}).then((res) => (res.json())).then((resJson) => {
						if (resJson) {
							const messageData = resJson?.data?.data;
							if (messageData) {
								setMessageInfo({ message: messageData.content, date: messageData.date });
							}
						}
					})
				}
			}, [mcontext])
			return (
				<Popup
					show={mcontext.auth && mcontext.loginPopup}
					message={<InterWeave noWrap={true} content={messageInfo.message} />}
					date={messageInfo.date}
					handleClose={() => mcontext.setLoginPopup(false)}
					className="rtl"
				/>
			);
		}

		return (
			<>
				<IdleTimer
					ref={ref => { this.idleTimer = ref }}
					timeout={1000 * 60 * 30}
					onActive={this.handleOnActive}
					onIdle={this.handleOnIdle}
					onAction={this.handleOnAction}
					debounce={250}
				/>
				<MyProvider>
					<MyContext.Consumer>
						{(context) => {
							mcontext = context;

							return (
								<HashRouter>
						
							
								<div className={`App${this.state.openSideBar ? ' sidebar-active' : ''}`}>
									{mcontext.auth && mcontext.user &&
										<NotificationReciver state={mcontext.notificationReciverState} setState={mcontext.setNotificationReciverState} firstReload={mcontext.notificationFirstLoad} setFirstReload={mcontext.setNotificationFirstLoad} />
									}
									{context.loading ?
										<Loader />
										: null}
									<div className={'sideBar'} style={{
										overflow: 'hidden',
										transition: '0.8s',
										width: this.state.openSideBar && user ? '390px' : '0px'
									}} >
										<SideBar
											shoSideBar={(status) => this.setState({ openSideBar: status })}
											corseTypes={(corseType) => this.setState({ corseTypes: corseType })}
											setOtherFilters={(otherFilter) => this.setState({ otherFilters: otherFilter })}
											location={(location) => this.setState({ location: location })}
											corseType={this.state.corseType}
											otherFilter={this.state.otherFilter}
										/>
									</div>

									<div className='main' style={{
										width: (this.state.openSideBar && user ? 'calc(100vw - 390px)' : '100%'),
										overflow: 'hidden',
										transition: '0.8s',
									}} >

										<div className="header shadow">

											<SummaryCount context={context} />

											<div className='header-right' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
												<MemberInfo />
												<LeadTimer/>
												{mcontext.auth && mcontext.user && <StickyNotification />}
												
												<img
													src={require('./assets/images/header/logo.png')}
													alt="logo"
													className='right-logo'
													onClick={() => {
														window.sessionStorage.setItem('lead', null);
														window.sessionStorage.lead = null;
														// this.setState({ user: false })
														//mcontext && mcontext.setUser(false)
													}} />
												<div onClick={() => {
													this.setState({ openSideBar: !this.state.openSideBar })
												}}
													style={{
														backgroundColor: '#2B2D41',
														height: '85px',
														alignItems: 'center',
														justifyContent: 'center',
														display: 'flex',
														// paddingRight: '17px',
														// paddingLeft: '17px',
														width: '6vw',
														cursor: 'pointer'
													}} >
													<img src={require('./assets/images/header/ham.png')} alt="ham" />
													
												</div>
												
											</div>

										</div>

									

										<div className='body'>
										
											<div className='body-grid'>
											
												{<Routes>
													!mcontext?.auth  ?
													<Route path='/' element={
														<Auth />}>
														</Route>
														
														:
														<Route path='/crm' element={
											this.handlePrivateRoute(
														<>
													
															<LoginPopup mcontext={mcontext} />
															<Tabs tabs={data} type='slider' style={{ alignSelf: 'right' }} />
															<Slider />
															{global.lead?.length > 0 ?
																<>
																	<StickyNaviagation hasResult={this.state.hasResult} show={this.state.showStickyNav} />
																	<Tabs
																		tabs={pdhData}
																		style={{ marginTop: '70px' }}
																		id={'bbb'}
																		back={true}
																		newSearch={() => {
																			window.sessionStorage.setItem('lead', null);
																			window.location.reload();
																		}}
																		refresh={() => {
																			this.refresh()
																		}}
																		showAffilatePopup={() => {
																			this.setShowSelectAffiliatePopup(true);
																		}}
																	/>
																	{this.state.showSelectAffiliatePopup &&
																		<SelectAffiliatePopup
																			data={JSON.parse(window.sessionStorage.getItem('lead'))[0]}
																			show={this.state.showSelectAffiliatePopup}
																			showState={{
																				state: this.state.showSelectAffiliatePopup,
																				setState: (val) => {
																					this.setShowSelectAffiliatePopup(val);
																				}
																			}}
																			showCloseButton={true}
																		/>
																	}
																</>
																:
																<CRM
																	user={(user) => {
																		mcontext && mcontext.setUser(user);
																		return user;
																	}}
																	
																	setSideBar={(isopen)=>{this.setState({ openSideBar: isopen })}}
																	/>}
																	
														</>
														)}></Route></Routes>}
														


											</div>

										</div>
									</div>

								</div>
								</HashRouter>
							)
						}}
					</MyContext.Consumer>
				</MyProvider >
			</>
		);
	}
}
export default App;
