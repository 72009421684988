import React from 'react'
import ReactTooltip from 'react-tooltip';
import { Icon } from '@iconify/react';
import exclamationCircleFilled from '@iconify-icons/ant-design/exclamation-circle-filled';
import './tooltip.css'

export default function Tooltip(props) {
	const {content,type} = props;
	return (
		<div className={`nirshamim-tooltip${type ? ' ' + type + '-type' : ''}`}>
			<div className="tooltip-trigger" data-tip={content} ><Icon icon={exclamationCircleFilled} /></div>
			<ReactTooltip html={true} place="left" />
		</div>
	)
}
