import React, { useState } from 'react'
import Popup from '../../components/popup/Popup'
import CousreCard from '../../components/CousreCard/CousreCard'

export default function CoursePopup(props) {
	const { show, setShow, title, course, mContext, updateLead, popupStyle } = props;
	return (
		<Popup
			show={show}
			handleClose={setShow}
			title={title ? title : (course ? course.title : '')}
			className={"styled-title " + popupStyle}
			height="700px"
			message={<CousreCard
				course={course}
				mContext={mContext}
				updateLead={courses => {
					updateLead()
				}}
			/>}
		/>
	)
}
