import React from 'react';
import './taps.css';
import Slider from '../../components/slider/slider'
import { Icon } from '@iconify/react';
import userSwitchOutlined from '@iconify-icons/ant-design/user-switch-outlined';
import { deleteLeadDraft } from '../../res/api';

class Tabs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabs: props.tabs,
      screen: '',
      strip_notification: [],
    }
  }

  newsearch() {
    if (window.confirm('האם אתה בטוח שברצונך לצאת מהרשומה ?')) {
      this.props.newSearch();
    }

  }
  onTabClick(data) {
    var { tabs } = this.state
    var clickedIndex = this.state.tabs.findIndex(i => i.name === data.name);
    if (data.highLigthted)
      return;

    tabs[clickedIndex].highLigthted = true;

    tabs.map((item, index) => {
      return item.highLigthted = index === clickedIndex;
    })
    this.setState({ tabs: [...tabs], screen: tabs[clickedIndex].screen, strip_notification: tabs[clickedIndex].data });


  }

  Tab = (props) => {
    var { name, highLigthted } = props.data
    return (
      <div className='tab' onClick={() => this.onTabClick(props.data)} >
        <span className={highLigthted ? 'tab-text onPress' : 'tab-text'}>{name}</span>
      </div>
    )
  }

  renderTabs() {

    this.state.tabs = this.props.tabs;
    this.state.tabs.forEach(i => {
      if (i.highLigthted) {
        this.state.screen = i.screen;
        this.state.strip_notification = i.data
      }
    });

    return this.state.tabs?.map((i, index) => { return <this.Tab data={i} key={index} /> })
  }

  deleteDraft() {
    if (window.confirm('האם אתה בטוח שברצונך למחוק את הרשומה ?')) {
      deleteLeadDraft();
      this.props.newSearch();    
    }
  }

  render() {

    return (
      <div className='tabs'>
        <div className='mContainer' style={this.props.style}>

          <div className='container' style={this.props.style}>
            {this.renderTabs()}
          </div>

          {this.props.back ?
            <div className='actions' >
              <div onClick={() => this.deleteDraft()}>
                <span>מחק  טיוטה</span>
                <img className='dealImage' src={require('../../assets/images/searchResult/delete@3x.png')} />
              </div>
              <div onClick={() => this.newsearch()}>
                <span>חיפוש חדש</span>
                <img className='dealImage' src={require('../../assets/images/search/searchIcone.png')} />
              </div>

              <div onClick={() => this.props.refresh()}>
                <span>רענון</span>
                <img className='dealImage' src={require('../../assets/images/deal.png')} />
              </div>

              <div onClick={() => this.props.showAffilatePopup()}>
                <span>בחירת שותף</span>
                <Icon icon={userSwitchOutlined} />
              </div>

            </div>
            : null}

        </div>

        {this.props.type === 'slider' ? <Slider data={this.state.strip_notification} /> : null}
        {this.state.screen}
      </div>
    );
  }
}
export default Tabs;
