import React from 'react'
import './checkBox.css'


const CheckBox = (props) => {
  return (
    <div className="input-wrapper">
      <input
        type="checkbox"
        defaultValue={props.text}
        className={props.cbClassName}
        id={props.id}
        style={props.cbStyle}
        onChange={(e) => props.onChange && props.onChange(e.target.checked)}
        checked={props.checked} />
      <label className='cbText' style={props.textStyle} htmlFor={props.id}>{props.text}</label>
    </div>
  )
}

export default CheckBox;