import global from './res/global'

export const fetchUserFragments = (userData) => {
	try {
		return fetch(global.domin + '!get_user_fragments?user_id=' + userData.user_id + '&user_token=' + userData.token+'&jwtauth='+userData.token,
			{
				method: 'post',
				headers: {
					'Content-Type': 'application/json'
				}
			});
	} catch (err) {
		console.error(err.message);
	}
};

export const getAuth = () => {
	const authString = window.sessionStorage.getItem('auth');

	if (!authString) {
		return null
	}

	const auth = JSON.parse(authString)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (!auth || (auth.expiry && now.getTime() > auth.expiry)) {
		// If the item is expired, delete the item from storage
		// and return null
		window.sessionStorage.removeItem('auth');
		window.sessionStorage.setItem('lead', null);
		window.location.reload();
		return null
	}

	return auth;
}

export const handlUserFragments = (data, mcontext) => {
	const currentAuthData = getAuth();

	mcontext.setUser((user) => {
		return { ...user, leads_count: data.leads_count }
	});

	if (currentAuthData && data) {
		let newUserData = { ...currentAuthData.user, ...data }
		setLoginAuth({
			...currentAuthData,
			user: newUserData
		});
	}
}

export const logOut = () => {
	window.sessionStorage.setItem('auth', null);

	window.sessionStorage.setItem('lead', null);
	
	window.location.reload();
}

export const setLoginAuth = (data) => {
	const now = new Date();
	const ttl = 60000 * 30;

	data.expiry = now.getTime() + ttl;

	data = JSON.stringify(data);

	window.sessionStorage.setItem('auth', data);
}
/**
 * Check if the institution_id already in the selected courses ( the cart )
 * @param array courses - selected courses
 * @param int institution_id
 * @return boolean true if institution already selected or false if not
 */
export const isInstitutionIncluded = (courses, institution_id) => {
	return courses.some(item => {
		return item.course.institution.id == institution_id
	});
}
/**
 * Check if the course_id already in the selected courses ( the cart )
 * @param int course_id
 * @return boolean true if course already selected or false if not
 */
export const isCourseIncluded = (course_id) => {
	if (global?.lead[0]?.courses) {
		return global.lead[0].courses.some(item => {
			return item.course.id == course_id
		});
	}
	return false;
}
/**
 * Get all the the answered questions
 * @param array courses - selected courses
 * @returns array ansewered questions
 */
export const getAllAnsweredQuestions = (courses) => {
	let all_answered_questions = [];
	courses.forEach(course_item => {
		if (course_item.answered_questions) {
			course_item.answered_questions.forEach(question_item => {
				if (!all_answered_questions.some(i => i.id == question_item.question.ID)) {
					all_answered_questions.push({ id: question_item.question.ID, answer: question_item.answer });
				}
			})
		}
	});
	
	return all_answered_questions;
}
/**
 * Check if the the required questions already answered
 * @param array courses - selected courses ( cart courses )
 * @return boolean true if all the required questions answered - false if not
 */
export const isAllRequiredQuestionsAnswered = (courses) => {
	let all_answered_questions = getAllAnsweredQuestions(courses);
	let isValid = true;

	courses.forEach(course_item => {
		if (course_item.course.questions) {
			course_item.course.questions.forEach(question_item => {
				if (question_item.required) {
					const answered = all_answered_questions.some(answered_question => answered_question.id == question_item.id);
					if (!answered) {
						
						isValid = false
					}
				}
			})
		}
	});

	return isValid;
}
/**
 * Check if the the required question is valid ( for "TAIN" - The Answer Is No )
 * @param array courses - selected courses ( cart courses )
 * @return boolean true if all the questions has valid answered - false if not
 */
export const isValidQuestionsAnswered = (courses) => {
	let all_answered_questions = getAllAnsweredQuestions(courses);
	let isValid = true;

	//TODO fetch from api - check if the answer is good Or get the validation from the questions that come from the api ( need to add "tain" as param)
	return true;
}
/**
 * Create query params from object
 * @param Object params - the params to send to the request
 * @return string - params ready to send via fetch
 */
const queryParams = params => {
	return Object.keys(params)
		.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		.join('&');
}
/**
 * Fetch update lead method with params
 * @param Object params - the params to send to the request
 * @returns fetch response
 */
export const fetchUpdateLead = params => {
	return fetch(global.domin + '!update_lead?id=' + global.lead[0].id+'&jwtauth='+global?.user?.token + '&' + queryParams(params), {
		method: 'POST'
	});
}
/**
 * Check if element in the view port
 * @param DOM_Element el 
 * @returns boolean true if in viewport or false if not
 */
export const elementInViewport = (el) => {
	if (el) {
		var top = el.offsetTop;
		var height = el.offsetHeight;
		var offset = window.innerHeight / 1.6;
		return (
			(top - offset) <= window.pageYOffset &&
			(top - offset + height + 60) >= (window.pageYOffset)
		);
	}
	return false
}