import React, { useState, useRef, useEffect } from 'react'
import './searchResult.css'
import Btn from '../Btn/btn'
import Modal from '../../components/Modal/modal'
import ResultOrderby from './ResultOrderby'
import { strings } from '../../strings'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchResultView from '../searchResultView/searchResultView'
import _ from 'lodash';

var arr = []

var mcontext = null;

const SearchResult = (props) => {

	const { updateLead, items,openSearch } = props;

	const [modalVisible, setModalVisible] = useState(false);
	const [popup, setPopup] = useState(false);
	const [error, setError] = useState('');
	const [resultOrderBy, setResultOrderBy] = useState('departments');
	const [departmentsViewStatus, setDepartmentsViewStatus] = useState([]);

	var c = 0;
	var [length, setLength] = useState(0)

	/** Open/close courses list under the department header */
	const openDepartmentsCard = (departmentId, status) => {
		var newDepartmentsViewStatus = [...departmentsViewStatus];

		newDepartmentsViewStatus[departmentId] = status;

		setDepartmentsViewStatus([...newDepartmentsViewStatus]);
	}

	const renderViewsm = () => {
		
		var arr = [];
		var index = 0;
		var courseItems = items;
		
		// flater all the items
		if (resultOrderBy != 'departments') {
			const flatList = _.flatMap(items);
			const tmpItems = { all: [] }

			tmpItems.all = _.uniqBy(flatList, function (e) {
				return e.id;
			});

			courseItems = { ...tmpItems };
		}

		for (var p in courseItems) {
			const crm_id = courseItems[p][0]?.department?.crm_id;

			const viewProps = {
				id: index,
				watching: (whatch) => props.watching(whatch),
				crm_id: crm_id,
				setPopup,
				openDepartmentsCard: openDepartmentsCard,
				setError,
				resultOrderBy: resultOrderBy,
				key: index,
				open: departmentsViewStatus[index] ? true : false,
				updateLead: (val) => updateLead(val),
				title: `${courseItems[p][0]?.department?.parent_name} [${courseItems[p][0]?.department?.parent}] > ${courseItems[p][0]?.department?.name} [${courseItems[p][0]?.department?.id}]`,
				mcontext,
				items: courseItems[p],
				opensearch : openSearch
			}

			arr.push(
				<SearchResultView {...viewProps} />
			);

			c += courseItems[p].length;
			index++;
		}


		if (c !== 0 && c !== length) {
			setLength(c)
		}

		return arr;
	}

	useEffect(() => {
		setResultOrderBy(props.ResultOrderBy);
		setLength(current => {
			if (items && Object.keys(items).length !== 0) {
				return current;
			} else {
				return 0;
			}
		});
		
		setDepartmentsViewStatus([]);
	}, [items]) 

	return (
		<div className='SearchResult-container search-result-anchor' >
			<ToastContainer />
			<Modal modalVisible={modalVisible}
				updateLead={(val) => {
					updateLead(val);
				}}
				modalStatus={(val) => setModalVisible(val)}
			/>

			<Modal
				modalVisible={popup}
				modalStatus={(val) => setPopup(val)}
				type={'popUp'}
				title='שים לב!'
			>
				<p>{error}</p>
			</Modal>

			<div style={{ textAlign: 'right', alignSelf: 'flex-end', fontFamily: 'Heebo-Bold' }}>{` ${length} סה"כ תוצאות רלוונטיות`}</div>

			{length !== 0 &&
				<div className="search-result-bar">
					<ResultOrderby
						orderby={resultOrderBy}
						setOrderby={setResultOrderBy}
						store={props.store}
					/>
					<Btn
						className='close-button'
						text={strings.close_all}
						onClick={() => {
							setDepartmentsViewStatus([]);

							mcontext && mcontext.setDepArr([]);
						}}
					/>


				</div>
				
			}
			{renderViewsm()}
		</div>
	)

}

export default SearchResult