import exclamationCircleFilled from '@iconify-icons/ant-design/exclamation-circle-filled';
import flagIcon from '@iconify-icons/bytesize/flag';
import successLine from '@iconify-icons/clarity/success-line';
import { Icon } from '@iconify/react';
import Interweave from 'interweave';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { images } from '../../assets/images';
import { isCourseIncluded, isInstitutionIncluded } from '../../generalUtill';
import { addToCartApi } from '../../res/api';
import global from '../../res/global';
import MyContext from '../../store/MyContext';
import { strings } from '../../strings';
import CoursePopup from '../CoursePopup/CoursePopup';
import IntitutionBranches from '../institutionBranches/institutionBranches';
import Tooltip from '../Tooltip/Tooltip';
import './coruseCard.css';

export default function CousreCard(props) {
	let mContext = null;

	const { course, updateLead, resultOrderBy,fromUpsale } = props;

	const [expandedView, setExpandedView] = useState(false);
	const [cardNotification, setCardNotification] = useState('');
	const [cardDisabled, setCardDisabled] = useState(false);
	const [inCart, setInCart] = useState(false);
	const [selectedBranch, setSelectedBranch] = useState(null);
	const [coursePopup, setCoursePopup] = useState(false);
	const [relevanceStatus, setRelevanceStatus] = useState(true);
	const [courseCardActiveClass, setCourseCardActiveClass] = useState(false);

	const shortText = (text, limit = 36) => {
		return (
			<Interweave
				noWrap={true}
				content={text.substring(0, limit) + (text?.length > limit && text?.length > 0 ? '...' : '')}
			/>
		);
	}

	const Button = (props) => {
		const { children, styletype } = props;

		return (
			<button className={`btn${styletype ? ' btn-' + styletype : ''}`} {...props}>
				{children}
			</button>
		)
	}

	const branchChanged = (branchId) => {
		setSelectedBranch(branchId);
	}

	const notify = (msg, type = 'success') => {
		let toastOption = {
			position: "top-left",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
		};
		if (type === 'success') {
			toast.success(msg, toastOption);
		} else {
			toast.error(msg, toastOption);
		}
	}

	const handleExpendView = () => {
		setExpandedView(current => !current);
	}

	const handleCourseCardActiveClass = () =>{
		setCourseCardActiveClass(current => !current);
	}

	const addToCart = () => {
		mContext.setLoading(true)

		if (global.lead[0].courses.length >= global.user?.max_add_to_cart) {
			notify(strings.Summery.Notifications.max_add_to_cart, 'error');
			return;
		}

		let courses = course.id
			? JSON.stringify([{
				id: course.id,
				branch: selectedBranch?.id,
				questions: [],
				fromUpsale: fromUpsale
			}])
			: null
		if(courses!=null) {
			mContext.setCallTimeRequired(mContext.callTimeRequired || (course.institution.requirecalltime??false));
			window.sessionStorage.setItem('callTimeRequired', JSON.stringify(mContext.callTimeRequired));
		}
		
		addToCartApi(courses).then(resJson => {
			global.lead = resJson.data?.data;
			
			updateLead();
			mContext.setLoading(false);
			cartStatusUpdated();
		});
	}

	const cartStatusUpdated = () => {
		let courseInCart = isCourseIncluded(course.id);

		setInCart(courseInCart);

		if (global.lead && global.lead[0]) {
			if (!courseInCart && isInstitutionIncluded(global.lead[0].courses, course.institution.id)) {
				setCardNotification(strings.CourseCard.institution_included);
			} else if (global.lead[0].courses.length >= global.user?.max_add_to_cart) {
				setCardNotification(strings.CourseCard.cart_is_full);
			} else {
				setCardNotification('');
			}
		}
	}

	const toggleRelevance = () => {

		let notRelevantCourses = mContext.notRelevantCourses;

		if (!relevanceStatus) {
			let notRelevantIndex = notRelevantCourses.indexOf(course.id) + 1;
			notRelevantCourses = notRelevantCourses.splice(notRelevantIndex, 1);
		} else {
			notRelevantCourses.push(course.id);
		}

		mContext.setNotRelevantCourses([...notRelevantCourses]);
	}

	useEffect(() => {
		cartStatusUpdated();

		let orderedBranches = _.orderBy(course.course_branches, ['address.distance'], 'asc');

		setSelectedBranch(orderedBranches.length ? orderedBranches[0] : null);
	}, []);

	useEffect(() => {
		let courseIsIrelevant = mContext.notRelevantCourses.indexOf(course.id);

		if (courseIsIrelevant > -1) {
			setRelevanceStatus(false);
		} else {
			setRelevanceStatus(true);
		}
	});

	useEffect(() => {
		cartStatusUpdated();
	}, [global?.lead[0]?.courses]);

	useEffect(() => {
		cartStatusUpdated();
		setCardDisabled(inCart || cardNotification || !relevanceStatus);
	}, [inCart, cardNotification, relevanceStatus, resultOrderBy]);


	return (
		<MyContext.Consumer>
			{(context) => {
				mContext = context;
				return (
					<div className={`course-card${cardDisabled ? ' disabled' : ''}${courseCardActiveClass?' active':''}`} data-id={course.id}>
						<ToastContainer />
						<div className={`card-head${(course?.title.includes('מכינה') || course?.title.includes('מכינות') || course?.title.includes('מכינת')) ? ' mechina' : ''}`}  onClick={()=>handleCourseCardActiveClass()}>
							<span className="title">{`${course.institution ? course.institution.title+' - ':'' }  ${course?.title} ${course.id ? '(' + course.id + ')' : ''}`} | {strings.studyType}: {course?.course_types?.map(i => (i.name)) + ''}</span>
							{course.notice &&
								<Tooltip content={course.notice} />
							}
							{course.listedOnMoreThanOneDepartment &&
								<Tooltip type="duplicate" content="מסלול זה מופיע מספר פעמים בתוצאות החיפוש שלך" />
							}
						</div>
						{course?.departments && course.departments.length > 0 &&
							<div className="departments-bread-crumbs">
								{course.departments.map(department => {
									return (
										<span key={department.id}>{`${department.parent_name} (${department.parent})  >  ${department.name} (${department.id})`}</span>
									)
								})}
							</div>
						}
						<div className="card-body">
							<div className="card-content">
								<div className="card-info-wrapper">
									<div className="card-info-column column-institution-details">
										{course?.institution && Object.keys(course?.institution).length > 1 &&
											<div className="column-inner">
												<div className="column-title"><span>{strings.CourseCard.institution_details}</span></div>
												{course.institution.existsPreStudy && <div className="mechinot"><a onClick={()=>{return false;}}>למוסד זה יש מכינות</a></div>}
												{course.institution.existsDormitory && <div className="meonot"><span>למוסד זה יש מעונות</span></div>}
												<div className="column-content">
													<div className="institution-info">
														<div className="content-heading">
															<img src={course.institution.thumbnail} alt={course.institution.title} />
															<span>{course.institution.title}({course.institution.id})</span>
															{course.institution.sticky ? (
																<>
																	<span className="promoted">(מקודם)</span>
																</>
															) : ''}
														</div>
														<div className="institution-order-rating"><span>{strings.radeOrder}</span> <span>{course?.institution?.rate_order}</span></div>
														<div className="institution-desc content-body">
															<div dangerouslySetInnerHTML={{ __html: course.institution.description }}></div>
														</div>
													</div>
												</div>
											</div>
										}
									</div>
									<div className="card-info-column column-institution-branches">
										<div className="column-inner">
											<div className="column-title"><span>{strings.CourseCard.course_details}</span></div>
											<div className="column-content">
												{course.institution.address.address ?
													<div className="institution-location-distance institution-location">
														<img src={images.location} alt="location" />
														<span>{course.institution.address.address}</span>
													</div>
													: ''}
												{course.institution.distance ?
													<div className="institution-location-distance">
														<span>{strings.distance}</span>
														<span >{course.institution.distance_text}</span>
													</div>
													: ''}
												{course.institution?.branches?.length ?
													<div className="institution-branches">
														<span className="inner-title">{strings.CourseCard.branches}</span>
														<IntitutionBranches
															branches={course.course_branches}
															branchChanged={branchChanged}
															selectedBranch={selectedBranch}
														/>
													</div>
													: ''}
												{course.admissions ?
													<div className="institution-branches">
														<span className="inner-title">{strings.CourseCard.admissions}</span>
														<div dangerouslySetInnerHTML={{ __html: course.admissions }}></div>
													</div>
													: ''}
												<div className="bold-content" dangerouslySetInnerHTML={{ __html: course?.moredata_toagent }}></div>
												<div className="column-content" dangerouslySetInnerHTML={{ __html: course.course_description }}></div>
											</div>
										</div>
										<div className="column-inner">
											{course.related_courses.length ?
												(
													<>
														<div className="inner-title"><span>{strings.CourseCard.linked_courses}:</span></div>
														<div className="column-content">
															{course.related_courses.map((relatedCourse, key) => {
																return (
																	<div className="related-course" key={key}>
																		<Button className="nostyle" onClick={() => {
																			setCoursePopup(relatedCourse);
																		}}>
																			{relatedCourse.title}
																		</Button>

																	</div>
																)
															})}
														</div>
													</>
												)
												: <></>}

										</div>
									</div>
									<div className="card-info-column column-course-details">
										<div className="column-inner">
											<div className="column-title"><span>{strings.CourseCard.settings}</span></div>
										</div>
										<div className="column-content">
											{course.course_settings.map((settings, key) => {
												return (
													<div className="column-inner" key={key}>
														<div className="column-inner-title">
															{settings.label}: {settings.value}
														</div>
													</div>
												)
											})}
											{course.filter_types.map((filter, key) => {
												return (
													<div className="column-inner" key={key}>
														<div className="column-inner-title">
															{filter.name}
														</div>
													</div>
												)
											})}
										</div>
									</div>
									<div className="card-info-column column-course-questions">
										<div className="column-inner">
											<div className="column-title"><span>{strings.CourseCard.questions_filter}</span></div>
										</div>
										<div className="column-content">
											{course.questions.map((question, key) => {
												return (
													<div className="column-inner" key={key}>
														<div className="column-inner-title">
															<strong>שאלה {(key + 1)}</strong>: {question.title}
														</div>
													</div>
												)
											})}
										</div>
									</div>
								</div>
								{expandedView ? (
									<div className="extendedData">
										<div className="card-info-wrapper">
											{course.institution.multiple_content && course.institution.multiple_content.length ? course.institution.multiple_content.map((content, key) => {
												return (
													<div className="card-info-column" key={key}>
														<div className="column-inner">
															<div className="column-title"><span>{content.title}</span></div>
															<div className="column-content" dangerouslySetInnerHTML={{ __html: content.content }}>
															</div>
														</div>
													</div>
												)
											}) : null}

											{course.course_extra_content?.length ? course.course_extra_content.map((content, key) => {
												return (
													<div className="card-info-column" key={key}>
														<div className="column-inner">
															<div className="column-title"><span>{content.title}</span></div>
															<div className="column-content" dangerouslySetInnerHTML={{ __html: content.content }}>
															</div>
														</div>
													</div>
												)
											}) : null}
										</div>
									</div>
								) : null}
							</div>
							<div className="inner-footer">
								<div className="button-wrapper">
									<Button styletype={`secondary${inCart ? ' in-cart' : ''}`} onClick={() => !cardDisabled ? addToCart() : ''} disabled={cardDisabled ? 'disabled' : ''} >
										{inCart ? <><Icon icon={successLine} /> {strings.CourseCard.added} </> : strings.CourseCard.add}
									</Button>
									<Button onClick={() => handleExpendView()}>
										{expandedView ? strings.CourseCard.regular_view : strings.CourseCard.expanded_view}
									</Button>
								</div>
								{cardNotification &&
									<div className="card-notification">
										<span className="icon-wrapper">
											<Icon icon={exclamationCircleFilled} />
										</span>
										<span>{cardNotification}</span>
									</div>
								}
								<div className="not-relevant">
									<Button styletype={`empty${!relevanceStatus ? ' active' : ''}`} onClick={() => toggleRelevance()}>
										<Icon icon={flagIcon} />
										<span>{relevanceStatus ? strings.CourseCard.not_relevant : strings.CourseCard.relevant}</span>
									</Button>
								</div>
							</div>
						</div>
						<CoursePopup
							popupStyle="wide"
							show={coursePopup}
							mContext={mContext}
							title={strings.relatedCourse}
							setShow={setCoursePopup}
							course={coursePopup}
							updateLead={updateLead}
						/>
					</div>
				)
			}}
		</MyContext.Consumer>
	)
}
