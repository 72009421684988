import React, { useState } from 'react';
import { strings } from '../../strings';
import Popup from '../../components/popup/Popup';
import global from '../../res/global';
import { validatePersonalID } from '../../res/utils';

const PersonalInfoPopup = props => {

    const { item, setLoading, editPopup, setEditPopup, handleUpdate } = props;

    let initInputData = {};

    for (let fieldKey in item.personal_info) {
        initInputData[fieldKey] = item.personal_info[fieldKey];
    }

    const PersonalInfoForm = () => {
        const [inputsData, setInputsData] = useState(initInputData);
        const [inputErrors, setErrors] = useState({});

        const validate = () => {
            const errors = {};

            if (inputsData.personal_id && !validatePersonalID(inputsData.personal_id)) {
                errors.personal_id = strings.personalDetails.validation.personalId;
            }

            if (Object.keys(errors)) {
                setErrors({ ...errors });
            }

            return Object.keys(errors).length ? false : true;
        }

        const handleFormUpdate = (e) => {
            e.preventDefault();

            if (validate()) {
                handleUpdate({
                    id: item.course.id,
                    personal_info: inputsData
                }, setLoading);
            }
        }

        const ignoreList = [
            'pet',
            'gpa',
        ];

        return (
            <form className="personal-info-wrapper" onSubmit={(e) => handleFormUpdate(e)}>
                <div className="inputs-wrapper">
                    <div className="input-wrapper">
                        <label>
                            <span>עיר מגורים:</span>
                            {global.lead[0].personal_info.address}
                        </label>
                    </div>
                    {Object.keys(item.personal_info).map((key, i) => {
                        return ignoreList.indexOf(key) > -1 ? null : (
                            <div className={`input-wrapper ${key}`} key={i}>
                                <label>
                                    <span>{strings.Summery.personalInfoForm[key]}</span>
                                    {key === 'notice' ?
                                        <textarea type="text" name={key} id={key} value={inputsData[key]} onChange={(e) => {
                                            setInputsData({ ...inputsData, [e.target.name]: e.target.value })
                                        }} />
                                        :
                                        <>
                                            <input type="text" name={key} id={key} value={inputsData[key]} onChange={(e) => {
                                                setInputsData({ ...inputsData, [e.target.name]: e.target.value })
                                            }} />
                                            <span className='input-error' style={{ color: 'red' }}>{inputErrors[key]}</span>
                                        </>
                                    }
                                </label>
                            </div>
                        )
                    })}

                </div>
                <input type="submit" className="btn" value={strings.Summery.personalInfoForm.saveBtn} />
            </form >
        )
    }

    return (
        <Popup
            show={editPopup}
            width="900px"
            height="700px"
            title={strings.Summery.personalInfoForm.title}
            className="styled-title"
            message={<PersonalInfoForm />}
            handleClose={() => setEditPopup(false)}
        />
    )
}

export default PersonalInfoPopup;