import React, { useState,useEffect  } from 'react'
import './auth.css'
import Btn from '../Btn/btn'
import global from '../../res/global'
import MyContext from '../../store/MyContext'
import { authenticate } from '../../res/api'
import { get_user_data } from '../../res/api'
import { setLoginAuth } from '../../generalUtill'
import { useNavigate } from 'react-router-dom';


const Auth = (props) => {

	const [username, setUsername] = useState('');
	const [pass, setPass] = useState('');
	const [error, setError] = useState(false);
	const [errorMsg,setErrorMsg] = useState('');
	const [firstenter,setFirstEnter] = useState(true);
	const navigate = useNavigate();
	var mcontext = null;


	useEffect(() => {
		//log out on enter to login
		if(mcontext.auth){
			mcontext.setAuth(false);
			mcontext.setUser(false);
			mcontext.setLead(false);
			mcontext.setSummery(false);
			window.sessionStorage.setItem('auth', null);
			window.sessionStorage.setItem('lead', null);
		}
		
		
	  }, [firstenter]); // הרץ מחדש את ה-effect אם firstenter משתנה. זה בשביל שייקרא רק פעם אחת בכניסה

	const login = () => {
		 authenticate(username, pass).then(resJson => {
			if(resJson.user)
			{ 
				setError(false);
				get_user_data(resJson.user.data.ID,resJson.jwt.token).then(resJsonuser => {
						mcontext.setUser(resJsonuser.data.data);
						
						mcontext.setAuth(true);
						
						setLoginAuth({
						auth: true,
						user: resJsonuser.data.data
						});

						global.user = resJsonuser.data.data;

				
						mcontext.setLoginPopup(true);
					
						navigate('/crm');
				});		
			}
			else
			{ 
				setError(resJson.errors.rest_jwt_auth_failure[0]);
							
				setErrorMsg((resJson.errors.rest_jwt_auth_failure[0].indexOf('Exceeded maximum number')>-1)?'המשתמש ננעל עקב מספר נסיונות שגויים, נא לנסות שוב מאוחר יותר':'שם משתמש או סיסמה לא נכונים');
			}
			
		});
	}

	const onSubmit = (e) => {
		 login();
		e.preventDefault();
		return false;
	}
	
	return (
		<MyContext.Consumer>
			{(context) => {
				mcontext = context;
				return (
					<>
						<form onSubmit={onSubmit} className="login-form">
							<div className='authContainer'>
								<span
									className='title' >{'התחברות למערכת'}</span>
								<input
									className='id-input inputa'
									type='text' placeholder='שם משתמש'
									value={username} onChange={(e) => {
										setUsername(e.target.value); error && setError(null)
									}} />
								<input
									className='id-input inputa '
									type='password' placeholder='סיסמה'
									value={pass} onChange={(e) => {
										setPass(e.target.value); error && setError(null)
									}} />
								{error ? <span className='error'>{errorMsg}</span> : null}
								<Btn
									type='submit'
									text='התחברות'
									className='exportBtn'
									
									style={{ height: '40px', width: '40%', marginTop: '30px' }}
								/>
							</div>
						</form>
					</>
				)
			}}
		</MyContext.Consumer>
	)
}

export default Auth;