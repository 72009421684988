import React, { useState } from 'react'
import './identification.css'
import Btn from '../Btn/btn'
import global from '../../res/global'
import { strings } from '../../strings'
import MyContext from '../../store/MyContext'
import { getAuth } from '../../generalUtill'
import { getLead } from '../../res/api'


const Identification = (props) => {
	var mcontext = null;

	const [crmCode, setCrmCode] = useState('');
	const [error, setError] = useState(null);

	const getLeadData = (setLoading) => {

		setLoading(true)

		getLead(crmCode).then(res => {

			setLoading(false)
			return res.json();
			
		})
			.then(resJson => {
				if (resJson?.data?.status === 200) {
					global.lead = resJson?.data?.data;
					global.LeadTimer = 0;
					window.sessionStorage.setItem('lead', JSON.stringify(resJson?.data?.data))
					props.user(getAuth()?.user);
					setLoading(false)
					if (global.lead.length === 0) {
						setError(strings.leadNotFound);
					}
					else{
						props.setSideBar(true);
						
						if(global.lead[0].courses){
							let res= false;
							global.lead[0].courses.forEach(course_item => {
								if (course_item.course.institution.requirecalltime) {
									res = true; ;
								}
							});
							mcontext.setCallTimeRequired(res);
						}
					}
				}
				else {
					setError(resJson?.data?.message)
				}
			}).catch(e => {alert(e.message);});

	}

	const onSubmit = (e) => {
		e.preventDefault();

		getLeadData(mcontext.setLoading);
	}


	return (
		<MyContext.Consumer>
			{(context) => {
				mcontext = context
				return (
					<>
						<form name="get-lead-form" method="GET" onSubmit={onSubmit} style={{width:'100%'}}>
							<div className='identification-container'>
								<span className='id-title'>{'הכנס מספר מזהה לקבלת הפרטים'}</span>
								<div className='id-inputs-container' >
									<Btn
										text='ייבוא רשומה'
										className='exportBtn'
										onClick={() => { getLeadData(context.setLoading) }}
										style={{ height: '50px' }}
									/>
									<div style={{ display: 'flex', flexDirection: 'column', width: '31%', marginLeft: '40px' }}>
										<input className='id-input' type='text' placeholder='מזהה שיוך מאגר' value={crmCode} onChange={(e) => { setCrmCode(e.target.value); error && setError(null) }} />
										{error ? <span className='error'>{error}</span> : null}
									</div>
								</div>
							</div>
						</form>
					</>
				)

			}}
		</MyContext.Consumer>
	)
}


export default Identification;