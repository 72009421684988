import React, { useState, useEffect } from 'react'
import './modal.css'
import Btn from '../../components/Btn/btn'
import MyContext from '../../store/MyContext'
import { Icon } from '@iconify/react';
import closeOutlined from '@iconify-icons/ant-design/close-outlined';
import { getAllAnsweredQuestions } from '../../generalUtill';
import global from '../../res/global';

var mcontext = null;
var flag = true;

const Modal = (props) => {

	var { data, style, className } = props;

	const [modalVisible, setmodalVisible] = useState(props.modalVisible)
	const [error, setError] = useState(null)
	const lead = global.lead[0];

	useEffect(() => {
		const visibleProps = () => {
			setmodalVisible(props.modalVisible)
		}

		visibleProps();

	}, [props.modalVisible])


	const renderQ = () => {
		let answeredQuestions = [];

		if (lead?.courses) {
			answeredQuestions = getAllAnsweredQuestions(lead?.courses)
		}
		
		return data?.map((question, index) => {
			let answerObj = answeredQuestions.filter((answeredQuestion) => answeredQuestion.id === question.id);
			let questionMarkup = null;

			if (answerObj.length) {
				question.value = answerObj[0].answer;
			}else{
				question.value = null;
			}

			switch (question?.type) {
				case 'text':
					questionMarkup = <ID data={question} id={question.id} />;
					break;
				case 'checkbox':
					questionMarkup = <CBQ data={question} id={question.id} />;
					break;
				case 'radio':
					questionMarkup = <RadioBtn
						data={question}
						id={question.id}
					/>;
					break;
				case 'true_false':
					questionMarkup = <RadioBtn
						data={question}
						id={question.id}
						type={question.type} />;
					break;
				default:
					questionMarkup = null;
					break;
			}

			return questionMarkup ? (
				<li key={index}>
					<div className="question-wrapper">
						<span className={'titleID'}>{question.title + (question.required ? '*' : '')} ({question.institutions.join(', ')})</span>
						<div className='content'>{question.content}</div>
						{questionMarkup}
					</div>
				</li>
			) : null;
		})
	}

	const RadioBtn = (props) => {

		const { data, id } = props

		var arr = mcontext?.answers;

		var true_false = [{ option: 'true' }, { option: 'false' }]

		var options = props.type === 'true_false' ? true_false : data.options


		useEffect(() => {
			if (data.value && arr[props.id] == undefined) {
				arr[props.id] = { answer: data.value, question: data.id }
				mcontext.setAnswers([...arr]);
			}
		}, []);

		const onChangeRadioBtn = (e) => {

			arr[id] = {
				answer: e.target.value,
				question: data.id
			}

			error && setError(null);
			flag = true;
			mcontext.setAnswers([...arr])
		}

		const renderRadioBtn = () => {

			return options?.map((i, index) => {

				
				return (
					<div className='radioItem' key={index}>
						<label htmlFor={'cb' + index + i.option} style={{ cursor: 'pointer' }}>
							<input
								type="radio"
								value={i.option}
								name={props.id}
								checked={mcontext?.answers[props.id]?.answer === i.option || data.value === i.option}
								id={'cb' + index + i.option}
								style={{ cursor: 'pointer' }}
								onChange={(e) => onChangeRadioBtn({ target: { value: i.option } })}
							/>
							{props.type === 'true_false' ? index === 0 ? 'כן' : 'לא' : i.option}
						</label>
					</div>
				)
			})
		}

		return (
			<div className='radioContainer' >
				<div onChange={(e) => onChangeRadioBtn(e)} >
					{renderRadioBtn()}
				</div>
			</div>
		)
	}

	const ID = (props) => {
		const { data } = props

		var arr = mcontext?.answers
		const [val, setVal] = useState(data.value)

		useEffect(() => {
			if (data.value && arr[props.id] == undefined) {
				arr[props.id] = { answer: data.value, question: data.id }
				mcontext.setAnswers([...arr]);
			}
		}, []);

		return (
			<div className={'QID'}>
				<label>
					<input className='inputID'
						value={val || mcontext?.answers[props.id]?.answer}
						onChange={(e) => {
							arr[props.id] = { answer: e.target.value, question: data.id }
							flag = true
							setVal(e.target.value)
							error && setError(null)
						}}
						onBlur={(() => {
							mcontext.setAnswers([...arr])
						})}
						style={error === props.id ? { border: '1px solid red' } : {}}
					/>
				</label>
			</div>
		)
	}

	const CBQ = (props) => {
		const { data } = props
		var arr = mcontext?.answers

		const [answers, setAnswers] = useState(arr[props.id]?.answer || data?.options?.map(i => {
			return data.value != undefined && data.value == i.value
		}))

		useEffect(() => {
			if (data.value && arr[props.id] == undefined) {
				arr[props.id] = { answer: data.value, question: data.id }
				mcontext.setAnswers([...arr]);
			}
		}, []);

		const renderOptions = () => {
			if (data.options) {
				return data?.options?.map((i, index) => (
					<div key={index}>
						<label
							className='content'
							htmlFor={'cb' + index + i.option}
							style={error === props.id ? { color: 'red' } : {}}
						>
							<input
								id={'cb' + index + i.option}
								className='inputID'
								type='checkbox'
								checked={answers[index]}
								onChange={(e) => {
									var marr = answers;
									marr[index] = e.target.checked ? i.option : false;
									arr[props.id] = { answer: marr, question: data.id }
									setAnswers([...marr])
									mcontext.setAnswers(arr)
								}} />
							{i.option}
						</label>
					</div>
				))
			}

			else {
				return (
					<div>
						<label className='content' for='cbq' style={error === props.id ? { color: 'red' } : {}}>{data.content}</label>
						<input
							id='cbq'
							className='inputID'
							type='checkbox'
							checked={mcontext?.answers[props.id]?.answer}
							onChange={(e) => {
								arr[props.id] = { answer: e.target.checked || false, question: data.id }
								// data.options = [{ answer: e.target.checked || false, option: data.content }]
								// setValue(arr);
								mcontext.setAnswers(arr)
							}} />
					</div>
				)
			}
		}

		return (
			<div className={'QID'}>
				{renderOptions()}
			</div>
		)
	}

	const sendToUpdate = () => {
		data && data.forEach((option, index) => {
			if (option.required && !mcontext.answers[option.id]?.answer && !error && flag) {
				console.log('set error to ', option.id);
				flag = false;
				setError(option.id);
			}
		})

		if (flag) {
			props.updateLead(mcontext?.answers);

			props.modalStatus(false);
		}
	}
	///UI
	if (modalVisible) {
		return (
			<MyContext.Consumer>
				{(context) => {
					mcontext = context;
					className = className ?? '';
					return (
						<div className={`modal ${className}`} style={style}>
							<div className='Q'>
								<div className='Qheader'>
									<span style={{ fontSize: '33px', marginLeft: '30px', cursor: 'pointer' }} onClick={() => { setmodalVisible(false); props.modalStatus(false) }} ><Icon icon={closeOutlined} /></span>
									<span className='headerTitle'>{props.title || 'פרטים חשובים עבור ההרשמה'}</span>
								</div>

								{props.type !== 'popUp' ? <div className='QinnerContainer'>
									<ol>
										{renderQ()}
									</ol>
								</div> : null}
								<div className={'children'}>
									{props.children}
								</div>
								<Btn
									style={{ width: '228px', height: '45px', backgroundColor: '#FEAA35', alignSelf: 'center', marginBottom: '5px' }}
									text='המשך'
									onClick={() => {
										sendToUpdate();
									}} />
							</div>
						</div>
					)
				}}
			</MyContext.Consumer>
		)
	}

	return null;
}

export default Modal;