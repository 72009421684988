import React, { useState, useEffect, useRef } from 'react'
import './summery.css'
import '../../components/Modal/modal.css'
import Btn from '../Btn/btn'
import global from '../../res/global'
import * as _ from 'underscore'
import Modal from '../../components/Modal/modal'
import { strings } from '../../strings'
import Interweave from 'interweave'
import { isAllRequiredQuestionsAnswered } from '../../generalUtill'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BasketCourseItem from '../basketCourseItem/basketCourseItem'
import { sendLeadApi, setManagerApproval, updateLadApi } from '../../res/api'
import UpSalesModal from '../upsaleModal/upsaleModal'
import closeCircleFilled from '@iconify-icons/ant-design/close-circle-filled';
import Icon from '@iconify/react'

const Summery = (props) => {

	const lead = global.lead[0];
	
	const [courses, setCourses] = useState(lead?.courses);
	const [modalVisible, setmodalVisible] = useState(false);
	const [qModalVisible, setQmodalVisible] = useState(false);
	const [questions, setQuestions] = useState([]);
	const [isQusetionsAnswered, setIsQusetionsAnswered] = useState(false);
	const [hasUpsales, setHasUpsales] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [managerErrorCode, setManagerErrorCode] = useState('');
	const [askApprovalModal, setAskApprovalModal] = useState(false);
	const [maxAllowedLeads, setMaxAllowedLeads] = useState(global.user?.max_courses);
	const [showFinalizeModal, setShowFinalizeModal] = useState(false);
	const [showIncompleteSalePopup, setShowIncompleteSalePopup] = useState(false);
	const [autoShowUpsell, setAutoShowUpsell] = useState(true);
	
	const { updateLeadfunc, mContext } = props;

	const myref = useRef(null)

	var coursesIds = courses.map(i => (i.course.id))

	const Notifications = props => {
		const { notifications } = props;
		return (
			<div className="notifications-msg">
				<span className="title">{strings.Summery.Notifications.title}</span>
				{notifications.map((notification, key) => <Interweave content={notification} key={key} />)}
			</div>
		)
	}

	const addSummeryNotification = string => {
		setNotifications(current => {
			if (current && current != undefined && current.length > 0) {
				if (current.indexOf(string) === -1) {
					return [...current, string];
				}
			}
			return [string];
		})
	}

	const removeSummeryNotification = string => {
		setNotifications(current => {
			if (current && current != undefined && current.length > 0) {
				let index = current.indexOf(string);
				if (index !== -1) {
					let tmp = [...current];
					tmp.splice(index, 1);
					return tmp;
				}
			}
		});
	}

	const renderItems = () => {
		if (courses?.length > 0) {
			return courses?.map((course, key) => (
				<BasketCourseItem
					key={key}
					index={key}
					course={course}
					isAllRequiredQuestionsAnswered={isAllRequiredQuestionsAnswered}
					setCourses={setCourses}
				/>))
		} else {
			return null
		}
	}

	const handleSendLead = (reload) => {
		setShowFinalizeModal(false);
		setmodalVisible(false)
		mContext && mContext.setLoading(true);

		sendLeadApi().then(res => {
			mContext && mContext.setLoading(false)

			let toastOption = {
				position: "top-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
			};

			if (res.status === 200) {
				toast.success(strings.Summery.lead_sent, toastOption);
				if (reload) {
					global.lead = [];
					window.sessionStorage.setItem('lead', JSON.stringify([]))
					setTimeout(() => {
						mContext && mContext.setLead('');
						mContext.setSummery(false);
						mContext.setCorseTypes([]);
						mContext.setOtherFilters([]);
						mContext.setQuestions([]);
						mContext.setAnswers([]);
						mContext.setManagerCode(null);
						mContext.setMyCourses([]);
						mContext.setLocation('');
						mContext.setCallTimeRequired(false);
						global.otherFilters = [];
						global.courseTypeFilters = [];
					}, 1500);
				}
			}
			else {
				toast.error(strings.Summery.error_lead_sent, toastOption);
				alert('error: ' + res.status)
			}
		})

	}

	const updateLead = (id, qanswers, sqObj , fromUpsale=false) => {

		mContext && mContext.setLoading(true)

		var obj = id
			? JSON.stringify([{
				id: id,
				questions: qanswers,
				fromUpsale:fromUpsale
			}])
			: null

		sqObj = sqObj && sqObj.map(i => {
			i.questions = i.questions.filter(i => i && i)
			return i;
		})

		sqObj = sqObj && JSON.stringify(sqObj)

		updateLadApi((sqObj || obj), '', mContext).then(resJson => {
			global.lead = resJson.data?.data;
			updateLeadfunc(resJson.data?.data[0].courses)
			mContext && mContext.setLoading(false)
		})
	}

	const SummeryHeaderItem = (props) => {
		return (
			<div className='summeryHeader-item' >
				<span style={{ marginRight: '7px' }}>{props.title + ' (' + props.length + ')'}</span>
				{props.img &&
					<img src={require('../../assets/images/summery/headerItemIcone.png')} alt="" />
				}
			</div>

		)
	}

	const renderSummryHeader = () => {

		var items = _.groupBy(courses, (i) => {
			return i?.course?.departments && i?.course?.departments[0]?.name
		})
		var arr = [<SummeryHeaderItem key={1} img={true} title={'סה״כ'} length={courses.length} />];


		for (var p in items) {
			if (p != 'undefined' && items.hasOwnProperty(p)) {
				arr.push(<SummeryHeaderItem key={items[p][0].course.id} title={p} length={items[p].length} />)
			}
		}

		return arr;
	}

	const showLeadQuestions = () => {

		courses.map((course, key) => {
			course.course.questions = course.course.questions.map((question) => {
				question.institution = course.course.institution;
				return question;
			});
		});

		var Qarr = {};

		//get unique qestions
		courses.map(course => {
			course.course.questions.map((question) => {
				if ('undefined' !== typeof Qarr[question.id]) {
					Qarr[question.id].required = Qarr[question.id].required || question.required
				} else {
					Qarr[question.id] = question;
				}
				Qarr[question.id].institutions = Qarr[question.id].institutions ? Qarr[question.id].institutions : [];

				Qarr[question.id].institutions.push(question.institution.title);

				Qarr[question.id].institutions = Qarr[question.id].institutions.filter((v, i, a) => a.indexOf(v) === i);
			});


		})

		//conver to array
		Qarr = Object.keys(Qarr).map((k) => Qarr[k]);

		setQuestions([...Qarr])

		if (Qarr.length > 0) {
			setQmodalVisible(true)
		}
	}

	const updateQustionsToCoursesInLead = (qArr) => {
		var cArr = [];
		//i.course.questions
		courses.forEach(i => {
			var arr = [];

			var cq = i.course.questions;

			cq.forEach(c => {
				arr.push(qArr.find(j => {
					return j?.question === c.id
				}))

			})
			cArr.push({ id: i.course.id, questions: arr })

		})

		updateLead(null, null, cArr);
	}

	const approveOverQuotaModal = () => {
		setAskApprovalModal(true);
	}

	const approveOverQuotaModalClose = () => {
		mContext.setManagerCode(false);
		setManagerApproval(null);
		setAskApprovalModal(false);
	}

	const getUpSales = () => {
		setHasUpsales(false);

		fetch(global.domin + '!get_upsales_courses?lead_id=' + lead.id, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(res => (res.json()))
			.then(resJson => {
				global.upSales = resJson.data.data
				mContext.setLoading(false);
				setHasUpsales(true);
				if(autoShowUpsell && courses.length < maxAllowedLeads && courses.length<3){
					setmodalVisible(true);
				}
			})
	}

	const maxCoursesReached = () => {
		return coursesIds.length > maxAllowedLeads;
	}

	const maxAddToBasketReached = () => {
		return coursesIds.length >= global?.user.max_add_to_cart;
	}

	const approveManagerCode = () => {
		if (mContext.managerCode) {
			setManagerApproval(mContext.managerCode).then((response) => {
				if (response.data.data.success != false) {
					setManagerErrorCode('');
					setAskApprovalModal(false);
					setMaxAllowedLeads(response.data.data['max-leads']);
				} else {
					setManagerErrorCode(strings.managerApprovalError);
				}
			});
		}
	}

	const incompleteSalePopup = () => {
		setShowIncompleteSalePopup(true);
	}
	/**
	 * Save the manager code
	 * @param {*} e 
	 */
	const managerCodeInputChange = (e) => {
		mContext.setManagerCode(e.target.value);
	}

	const approveSendLeadPopup = () => {
		setShowIncompleteSalePopup(false);
		setShowFinalizeModal(true);
	}

	const validateQuestions = () => {
		if( lead?.courses.length ){
			const questionsAnsered = isAllRequiredQuestionsAnswered(lead?.courses);

			console.log("questionsAnsered",questionsAnsered)
			setIsQusetionsAnswered(questionsAnsered);
		} 
	}

	useEffect(() => {
		if (lead.manager_approval) {
			mContext.setManagerCode(lead.manager_approval);
		}

		validateQuestions();

		setCourses(lead?.courses);
		
	}, [props.updateLead]);

	useEffect(()=>{
		validateQuestions();
	},[]);

	useEffect(() => {

		mContext.setLoading(true);

		getUpSales();
		
		setAutoShowUpsell(false); //show upsale modal automatically only once - not each render

		validateQuestions();
	}, [courses])

	useEffect(() => {
		if (isQusetionsAnswered) {
			removeSummeryNotification(strings.Summery.Notifications.has_questions);
		} else {
			addSummeryNotification(strings.Summery.Notifications.has_questions);
		}
	}, [isQusetionsAnswered])

	useEffect(() => {
		if (maxAddToBasketReached()) {
			setHasUpsales(false);
			addSummeryNotification(strings.Summery.Notifications.max_courses);
		} else {
			removeSummeryNotification(strings.Summery.Notifications.max_courses);
		}
	}, [courses, maxAllowedLeads])

	const upsaleModalProps = {
		updateLead,
		mContext,
		updateLeadfunc,
		maxAddToBasketReached,
		courses,
		setmodalVisible
	}

	const incompleteSaleModal = () => {

		return showIncompleteSalePopup ?
			<div className="deleteCourse">
				<div className="deleteCourseButton">
					<img src={require('../../assets/images/alert.png')} />
				</div>
				<div className="delete-message">
					{strings.attention}
					<div>
						<strong>{strings.maxSale}</strong>
					</div>
				</div>
				<div className="deleteButtons approvalButtons">
					<Btn text='הבנתי'
						style={{
							minHeight: '45px',
							backgroundColor: '#2B2D41',
							color: '#ffff'
						}}
						onClick={() => approveSendLeadPopup()}
					/>
					<Btn text='ביטול'
						className="cancel-delete"
						style={{
							minHeight: '45px',
							color: '#ffff'
						}}
						onClick={() => setShowIncompleteSalePopup(false)}
					/>
				</div>
			</div> : '';
	}

	const finalizeModal = () => {
		return showFinalizeModal ? <div className="finalize-modal">
			<div className="finalize-modal-title">
				סל המסלולים
				<button className="btn-close" onClick={() => setShowFinalizeModal(false)}>
					<Icon icon={closeCircleFilled} />
				</button>
			</div>
			<div className="finalize-modal-body">
				<img src={require('../../assets/images/finalize-image.png')} alt="" />
				<div className="finalize-message">
					בחרת לשלוח את כל הרשומות. האם אתה בטוח?
				</div>
			</div>
			<div className="finalize-modal-buttons">
				{/* <Btn
					text="שלח וחזור לרשומה"
					className="secondary"
					onClick={() => {
						handleSendLead()
					}}
				/> */}
				<Btn
					text="שלח ופתח רשומה חדשה"
					className="secondary"
					onClick={() => {
						handleSendLead(true)
					}}
				/>
				<Btn
					text="חזור לסל"
					className="secondary"
					onClick={() => {
						setShowFinalizeModal(false)
					}}
				/>
			</div>
		</div> : '';
	}

	return (
		<>
			{finalizeModal()}
			{incompleteSaleModal()}
			<div className='summery-container' ref={myref}>
				<div className='summeryHeader shadow'>
					{renderSummryHeader()}
				</div>

				{modalVisible ? <UpSalesModal {...upsaleModalProps} /> : null}

				<Modal
					modalVisible={qModalVisible}
					data={questions}
					updateLead={(val) => {
						updateQustionsToCoursesInLead(val)
					}}
					modalStatus={(val) => setQmodalVisible(val)}
				/>
				<div className='row bb' style={{ width: '100%' }} >
					<span className='tableHeader changePersonalInfoLead' ></span>
					<span className='tableHeader' >{'סניף'}</span>
					<span className='tableHeader filter_types' >{'מועד לימודים'}</span>
					<span className='tableHeader course_types' >{'סוג לימודים'}</span>
					<span className='tableHeader institution' >{'מוסד הלימוד'}</span>
					<span className='tableHeader departments' >{'מסלול המבוקש'}</span>
					<span className='tableHeader delete' >{'הסר'}</span>
				</div>

				<div className="items-wrapper">
					{renderItems()}
				</div>
				<div className="buttons-wrapper">
					{maxCoursesReached() || mContext.managerCode ? (
						<>
							<Btn
								text={mContext.managerCode ? strings.managerApproved : strings.managerApproval}
								className="secondary updateQty"
								onClick={approveOverQuotaModal}
								disabled={mContext.managerCode ? 'disabled' : false}
							/>
							{askApprovalModal ?
								<div className="quota-manager-code">
									<input
										autoComplete={false}
										placeholder={strings.managerCode}
										onChange={managerCodeInputChange}
										value={mContext.managerCode}
										type="password" />
									<Btn
										onClick={approveManagerCode}
										text={strings.approve} />
									<Btn
										onClick={approveOverQuotaModalClose}
										text={strings.cancel} />
									{managerErrorCode.length ? <div className="error-message">{managerErrorCode}</div> : null}
								</div>
								:
								<></>
							}
						</>
					) : null}

					<Btn className={`secondary${!hasUpsales || courses.length >= maxAllowedLeads  ? ' disabled' : ''}`} text={strings.Summery.upsales}
						disabled={!hasUpsales || courses.length >= maxAllowedLeads ? ' disabled' : ''}
						onClick={() => {
							if (global.upSales
								&& Object.keys(global.upSales).length === 0 && global.upSales.constructor === Object) {
								return;
							}
							else {
								setmodalVisible(true);
							}
						}} />
					<Btn className="questions-unanswered" text={strings.Summery.show_quesctions}
						onClick={() => {
							if (!courses || courses.length === 0) {
								alert(strings.Summery.no_cousrses_found)
								return;
							}
							else {
								showLeadQuestions();
							}
						}} />

					{isQusetionsAnswered &&
						<Btn className="questions-answered-send-lead" text={strings.Summery.send_leads}
							onClick={() => {
								if (!courses || courses.length === 0) {
									alert(strings.Summery.no_cousrses_found)
									return;
								}
								else {
									if (courses.length < maxAllowedLeads && !(mContext.managerCode)) {
										incompleteSalePopup();
									} else {
										approveSendLeadPopup();
									}
								}
							}} />
					}
				</div>
				{notifications && notifications.length > 0 &&
					<Notifications notifications={notifications} />
				}
			</div>
		</>
	)
}

export default Summery;