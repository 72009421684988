import React, { useState } from 'react'
import './partnerItem.css'


const Dialog = (props) => {


	const [edit, setEdit] = useState(true)

	var style = { position: 'absolute', zIndex: 9999, height: '170px', width: '430px', backgroundColor: '#ffff', }


	const onClick = (option) => {
		switch (option) {
			case 1:
				props.Dialog(false);
				break;
			case 2:
				setEdit(false);
				break;
			default:
				break;
		}

	}

	return (
		<div className='dialog' style={{ ...style, ...props.style }}>
			<div className='dialog-text-view'>
				<input className='dialog-text' value={'Young_Center_Clali'} disabled={edit} />
			</div>
			<div className='dialogFooterBtns'>
				<span className='dialogFooterText' style={{ color: '#FEAA35' }} onClick={() => onClick(1)} >{'בחר'}</span>
				<span className='dialogFooterText' onClick={() => onClick(2)} >{'שינוי שם שותף'}</span>
			</div>
		</div>
	)
}

const PartnerItem = (props) => {

	var style = { position: 'absolute', zIndex: 9999, height: '170px', width: '430px', backgroundColor: '#ffff', }
	var [dialog, setDialog] = useState({ showDialog: false, style: style });

	return (
		<div className='PartnerItem' style={props.style}>
			<div className='x center' onClick={() => props.onCancel && props.onCancel(props.id)}>
				<img src={require('../../assets/images/x.png')} className='x-image' />
			</div>
			<div className='x-text-div center'
				onClick={e => {
					if (!props.disabled) {
						var { style } = dialog;
						var top = (58 + e.currentTarget.offsetTop + 'px');
						var left = (e.currentTarget.offsetLeft - 225) < 15 ? (15 + 'px') : (e.currentTarget.offsetLeft - 225 + 'px');
						console.log('x: ' + e.clientX + ' Y: ' + e.clientY)
						// if(!dialog.showDialog)
						setDialog({ showDialog: true, style: { ...style, top: top, left: left } })
					}
				}}>
				<span className='x-text'>{props.text}</span>
			</div>
			{dialog.showDialog ? <Dialog style={dialog.style} Dialog={(bool) => { setDialog({ showDialog: bool }) }} /> : null}
		</div>
	)
}

export default PartnerItem;