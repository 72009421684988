import React, { useState, useEffect } from 'react';
import './LeadTimer.css'
import global from '../../res/global'


export default function LeadTimer() {
    // State to keep track of the current time
   // const [seconds, setSeconds] = useState(0);
    const [time, setTime] = useState("00:00:00");
    const [timerclass,setTimerclass] = useState('');
    useEffect(() => {
     
      // Set up an interval that updates the time every second
      const interval = setInterval(() => {
        global.LeadTimer = global.LeadTimer + 1;
        setTime(formatTime(global.LeadTimer));
        setTimerclass(decideClass(global.LeadTimer));
      }, 1000);
      
      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }, []); // Empty dependency array means this effect runs once on mount

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        
        return `${hours!=0?hours.toString().padStart(2, '0')+':':''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        //alert(hours, minutes, seconds);
      };
      const decideClass = (totalSeconds)=>{
        let retclass='green';
        if(global.LeadTimer>240) retclass = 'orange';
        if(global.LeadTimer>360) retclass = 'red';
        if(global.LeadTimer>480) retclass= 'flickering'
        return  retclass;

      }
    
  
    return (
      
      <div className={`LeadTimerDiv ${timerclass}`}>
        {global.lead!='' && global.lead!=null && global.lead !=undefined && global.lead!=[] && (
          <div  > זמן טיפול במתעניין:
            <h2>{time}</h2> </div>
           )}

          </div>
           );  



  }
